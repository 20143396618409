//Libraries
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavigationDrawer from "../Components/NavigationDrawer/NavigationDrawer";
import history from "../Utils/history";
import {
  Box,
  Divider,
  Grid,
  ListItem,
  Hidden,
  useMediaQuery,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Button,
  Paper,
  MenuItem,
  Popover,
  Avatar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Row } from "@mui-treasury/components/flex";
import clsx from "clsx";
import { deepPurple } from "@material-ui/core/colors";
import mapStyles from "../Utils/mapStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Item } from "@mui-treasury/components/flex";
import { useDynamicAvatarStyles } from "@mui-treasury/styles/avatar/dynamic";
import Rating from "@material-ui/lab/Rating";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

//Components
import actions from "../Redux/Order/Order.actions";
import Location from "../Components/DeliveryMap/Location.Component";
import DriverRating from "./Ratings/DriverRating.page";
import { webSocketLiveLocationPath } from "../Utils/constants";

const useStyles = makeStyles((theme) => ({
  cardroot: {
    borderRadius: 8,
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      marginBottom: "3%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "92%",
      marginBottom: "1.5%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "87%",
      marginBottom: "1.5%",
    },
  },
  icons: {
    padding: 6,
  },
  root: {
    display: "flex",
    // backgroundColor: "#FFFFFF"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.only("xs")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "3%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "3%",
    },
  },
  contentCard: {
    [theme.breakpoints.between("xs", "md")]: {
      padding: "8px",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingBottom: "16px",
    },
  },
  break: {
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 12,
      textDecoration: "none",
      float: "right",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 14,
      textDecoration: "none",
      float: "right",
    },
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 16,
    },
  },

  dividerMargin: {
    marginTop: "5%",
    marginBottom: "5%",
  },
  estDiv: {
    marginBottom: "3%",
    width: "100%",
  },
  dividerFloor: {
    marginTop: "4%",
    marginBottom: "3%",
  },
  purple: {
    backgroundColor: "#6251FE",
  },
  number: {
    paddingTop: "2%",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      opacity: 1,
    },
  },
  card: {
    width: "43%",
    variant: "outlined",
    border: "1px solid #E0E0E0",
  },
  submit: {
    width: "15vw",
    [theme.breakpoints.up("lg")]: {
      width: "15vw",
      margin: theme.spacing(1, 0, 2),
    },
  },
  submitAddress: {
    margin: theme.spacing(0, 0, 2),
    fontSize: 18,
  },
  fontName: {
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 18,
    },
  },
  titleFont: {
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 16,
    },
  },
  title: {
    fontSize: 15,
    marginTop: "2%",
  },
  container: {
    position: "relative",
    width: "50vw",
  },
  centered: {
    position: "absolute",
    padding: "0.5%",
    top: "54%",
    left: "28%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
  },
  list: {
    padding: "0px",
  },
  cardCentered: {
    position: "absolute",
    [theme.breakpoints.only("xs")]: {
      top: "40%",
      width: "95%",
      left: "5%",
    },
    [theme.breakpoints.only("sm")]: {
      top: "55%",
      width: "50%",
      left: "25%",
    },
    [theme.breakpoints.only("md")]: {
      width: "60%",
      top: "50%",
      left: "15%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "80%",
      top: "50%",
      left: "15%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "80%",
      top: "60%",
      left: "25%",
    },
  },
  centeredTrip: {
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
    borderRadius: 8,
    [theme.breakpoints.only("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.only("md")]: {
      width: "60%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "60%",
    },
  },
  title: {
    fontSize: 15,
    marginTop: "2%",
  },
  dialog: {
    position: "absolute",
    borderRadius: 8,
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  cancel: {
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(1, 0, 2),
    },
    [theme.breakpoints.between("sm", "xl")]: {
      margin: theme.spacing(2, 0, 2),
    },
  },
  image: {
    [theme.breakpoints.only("xs")]: {
      width: "30%",
      height: "auto",
    },
    [theme.breakpoints.only("sm")]: {
      width: "40%",
      height: "auto",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
      height: "auto",
    },
  },
  large: {
    [theme.breakpoints.only("xs")]: {
      width: theme.spacing(7),
      fontSize: 24,
      height: theme.spacing(7),
    },
    [theme.breakpoints.only("sm")]: {
      width: theme.spacing(7),
      fontSize: 26,
      height: theme.spacing(7),
    },
    [theme.breakpoints.only("md")]: {
      width: theme.spacing(9),
      fontSize: 28,
      height: theme.spacing(9),
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.spacing(12),
      fontSize: 30,
      height: theme.spacing(12),
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.spacing(14),
      fontSize: 36,
      height: theme.spacing(14),
    },
  },
  fullWidth: {
    width: "100%",
  },
  price: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#6251FE",
  },
  driver: {
    [theme.breakpoints.only("xs")]: {
      marginLeft: "3%",
      marginRight: "3%",
      marginTop: "3%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "8%",
      marginRight: "8%",
      marginTop: "3%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginLeft: "3%",
      marginTop: "0%",
      marginRight: "0%",
    },
  },
  date: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "3%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "2%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginTop: "3%",
    },
  },
  submitTrip: {
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(1, 0, 0),
      fontSize: 12,
    },
    [theme.breakpoints.only("sm")]: {
      margin: theme.spacing(2, 0, 0),
    },
    [theme.breakpoints.only("md")]: {
      margin: theme.spacing(2, 0, 0),
    },
    [theme.breakpoints.only("lg")]: {
      margin: theme.spacing(2, 0, 0),
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(5, 0, 0),
    },
  },
  summary: {
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 32.4,
    },
  },
  phoneDiv: {
    borderRadius: 8,
    border: "1px solid grey",
    padding: "5px 15px",
    width: "25%",
  },
}));

let socket;

export default function OrderSummary(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { orderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState(false);
  const jwt = useSelector((state) => state.user.jwt);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.clearError());
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);
  const themeType = useSelector((state) => state.themes.theme);
  const orderFeedbackError = useSelector(
    (state) => state.order?.errors?.orderFeedbackError
  );
  const orderFeedbackSuccess = useSelector(
    (state) => state.order?.success?.orderFeedbackSuccess
  );
  const [openSnack, setOpenSnack] = React.useState(false);

  const driverLocation = useSelector((state) => state.order?.location);
  const orderDetail = useSelector((state) => state.order.orderDetail);

  const orderStatus = useSelector(
    (state) => state.order?.orderDetail?.orderStatus
  );
  let location1 = {
    orderId: orderId,
  };
  const [activeOrders, setActiveOrders] = useState(orderDetail ? true : false);
  const [activeOrdersAvailable, setActiveOrdersAvailable] = useState(
    orderDetail ? true : false
  );

  const [driverLiveLocation, setLiveLocation] = useState({
    lat: parseFloat(driverLocation?.lat),
    lng: parseFloat(driverLocation?.long),
  });

  const [openRating, setOpenRating] = React.useState(false);
  const [latValue1, setLatValue1] = useState(0);
  const [latValue2, setLatValue2] = useState(0);
  const [lngValue1, setLngValue1] = useState(0);
  const [lngValue2, setLngValue2] = useState(0);

  useEffect(() => {
    setLiveLocation({
      lat: parseFloat(driverLocation?.lat),
      lng: parseFloat(driverLocation?.long),
    });
  }, [JSON.stringify(driverLocation)]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseRating = () => {
    setOpenRating(false);
  };

  const [answer, setAnswer] = useState();

  const getAnswer = async () => {
    //get order details
    setAnswer(dispatch(actions.getOrderDetails(jwt, orderId)));
  };

  useEffect(() => {
    dispatch(actions.getOrderDetails(jwt, orderId));
  }, [orderId]);
  useEffect(() => {}, [themeType]);

  useEffect(() => {
    console.log("page refresh");
    // dispatch(actions.getOrderDetails(jwt, orderId))
    const timer = setInterval(getAnswer, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (orderDetail) {
      setLatValue1(orderDetail?.tripStartLocation?.lat);
      setLatValue2(orderDetail?.tripEndLocation?.lat);
      setLngValue1(orderDetail?.tripStartLocation?.long);
      setLngValue2(orderDetail?.tripEndLocation?.long);
      setActiveOrders(true);
      setActiveOrdersAvailable(true);
      setLoading(false);
    }
  }, [orderDetail]);

  useEffect(() => {
    if (
      orderStatus == "on the way to pickup point" ||
      orderStatus == "reached pickup point" ||
      orderStatus == "on route to destination" ||
      orderStatus == "reached destination" ||
      orderStatus == "order complete"
    ) {
      socket = new WebSocket(
        webSocketLiveLocationPath + "getLiveLocation",
        jwt
      );

      socket.onopen = function (e) {
        if (socket.readyState == 1) {
          dispatch(actions.socketConnection(socket));
          socket.send(JSON.stringify(location1));
        }
      };

      socket.onmessage = function (event) {
        if (event.data && event.data.length > 0) {
          //for driver live location
          dispatch(actions.driverLiveLocation(JSON.parse(event.data)));
        }
      };

      socket.onclose = () => {
        console.log("disconnected");
      };

      socket.onerror = (err) => {
        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );

        socket.close();
      };
    }
  }, [driverLiveLocation?.lat, driverLiveLocation?.lng, orderStatus]);

  useEffect(() => {
    if (orderFeedbackError && !orderFeedbackSuccess) {
      handleErrorSnack();
    }

    if (!orderFeedbackError && orderFeedbackSuccess) {
      handleErrorSnack();
    }
  }, [orderFeedbackError, orderFeedbackSuccess]);

  const places = [
    { latitude: parseFloat(latValue1), longitude: parseFloat(lngValue1) },
    { latitude: parseFloat(latValue2), longitude: parseFloat(lngValue2) },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const lgMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const mdMatch = useMediaQuery(theme.breakpoints.up("md"));
  const smMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const avatarStyles = useDynamicAvatarStyles(
    lgMatch ? { size: 90 } : matches ? { size: 62 } : { size: 62 }
  );

  const handleErrorSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    dispatch(actions.clearError());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const options = [
    {
      id: 1,
      title: "Booking Fee: ",
      amount: orderDetail?.estimatedPrice?.serviseCharger,
    },
    {
      id: 2,
      title: "Driver Fee: ",
      amount: orderDetail?.estimatedPrice?.driverCharger,
    },
    {
      id: 3,
      title: "Total Price: ",
      amount: orderDetail?.estimatedPrice?.maximumPrice,
    },
    {
      id: 4,
      title: "Base Fare: ",
      amount: orderDetail?.estimatedPrice?.baseFare,
    },

    {
      id: 5,
      title: "Congestion Charges: ",
      amount: orderDetail?.estimatedPrice?.finalCongestionCharges,
    },
    {
      id: 6,
      title: "Cost flight of Stair: ",
      amount: orderDetail?.estimatedPrice?.finalCostFlightOfStair,
    },
    {
      id: 7,
      title: "Cost of Helpers: ",
      amount: orderDetail?.estimatedPrice?.finalCostOfHelpers,
    },
    {
      id: 8,
      title: "Cost per Mile Charges: ",
      amount: orderDetail?.estimatedPrice?.finalCostPerMileCharges,
    },
    {
      id: 9,
      title: "Cost per Mint Charges: ",
      amount: orderDetail?.estimatedPrice?.finalCostPerMintCharges,
    },
    {
      id: 10,
      title: "Insurrance Charges: ",
      amount: orderDetail?.estimatedPrice?.finalInsurranceCharges,
    },
    {
      id: 11,
      title: "Toll Charges: ",
      amount: orderDetail?.estimatedPrice?.finalTollCharges,
    },
    {
      id: 12,
      title: "Factore Charges: ",
      amount: orderDetail?.estimatedPrice?.finalTypeFactoreCharges,
    },

    {
      id: 13,
      title: "Ride Distance: ",
      amount: orderDetail?.estimatedPrice?.rideDistance,
    },
    {
      id: 14,
      title: "Time In Ride: ",
      amount: orderDetail?.estimatedPrice?.timeInRide,
    },
  ];

  //name conversion
  const name = orderDetail?.driver?.name ? orderDetail?.driver?.name : "Driver";

  let myStr2 = name.toUpperCase();
  let first = myStr2.match(/\b(\w)/g);
  const firstChar = first.slice(0, 3);

  //AcceptRejectOrder
  function LookingForVan() {
    return (
      <div>
        <Card className={classes.centeredTrip} elevation={2}>
          <Box p={lgMatch ? 4 : 2} className={classes.fullWidth}>
            <Typography variant="h6" color="textPrimary">
              We 're looking for van Driver.
            </Typography>
          </Box>
        </Card>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {loading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress style={{ width: "50px", height: "50px" }} />
          </div>
        ) : (
          <>
            <Grid container mt={"3%"} display="flex" alignItems="stretch">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className={classes.container}
              >
                <Location
                  defaultZoom={7}
                  places={places}
                  liveLocation={driverLiveLocation}
                />
                <div className={classes.cardCentered}>
                  {orderDetail?.orderStatus?.includes(
                    "Awaiting Confirmation"
                  ) || orderDetail?.orderStatus?.includes("Pending") ? (
                    <LookingForVan />
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box className={classes.driver}>
                  {/* hide driver data when order is not in active state */}
                  {orderDetail?.orderStatus === "Pending" ||
                  orderDetail?.orderStatus === "payment_required" ||
                  orderDetail?.orderStatus === "Awaiting Confirmation" ||
                  orderDetail?.orderStatus === "Cancelled" ? null : (
                    <Card className={classes.cardroot}>
                      <Box className={classes.fullWidth}>
                        <CardContent className={classes.contentCard}>
                          <Box
                            display="flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Item>
                              <Avatar
                                className={clsx(classes.large, classes.purple)}
                              >
                                {orderDetail?.driver?.name ? firstChar : null}
                              </Avatar>
                            </Item>
                            <label htmlFor="icon-button-file">
                              <ListItem className={classes.list}>
                                <Typography>Van Details</Typography>
                                <IconButton
                                  aria-label="upload picture"
                                  component="span"
                                  onClick={handleClick}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </ListItem>
                            </label>
                            <Popover
                              id={id}
                              open={open}
                              elevation={2}
                              className={classes.pop}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={
                                matches
                                  ? {
                                      style: { width: "55%" },
                                    }
                                  : { style: { width: "25%" } }
                              }
                            >
                              <Paper
                                style={{
                                  width: "100%",
                                  padding: "3%",
                                }}
                              >
                                {/* icons on the basis of condition */}
                                {orderDetail.rideType === "medium" ? (
                                  <img
                                    className={classes.image}
                                    src={
                                      "/assets/images/VehiclesIconsLarge/Lwb.png"
                                    }
                                  />
                                ) : orderDetail.rideType === "small" ? (
                                  <img
                                    className={classes.image}
                                    src={
                                      "/assets/images/VehiclesIconsLarge/mwb.png"
                                    }
                                  />
                                ) : orderDetail.rideType === "large" ? (
                                  <img
                                    className={classes.image}
                                    src={
                                      "/assets/images/VehiclesIconsLarge/Tlbv.png"
                                    }
                                  />
                                ) : (
                                  <img
                                    className={classes.image}
                                    src={
                                      "/assets/images/VehiclesIconsLarge/Tlbw.png"
                                    }
                                  />
                                )}

                                <Grid container mt={"3%"}>
                                  <Grid item xs={12} lg={8}>
                                    <Box paddingTop={"1%"}>
                                      <Typography
                                        color="textPrimary"
                                        className={classes.titleFont}
                                      >
                                        {orderDetail?.driver?.vehicleDetails
                                          ?.vehicleType === "small"
                                          ? "Medium Wheelbase Transit"
                                          : orderDetail?.driver?.vehicleDetails
                                              ?.vehicleType === "medium"
                                          ? "Long Wheelbase Transit"
                                          : orderDetail?.driver?.vehicleDetails
                                              ?.vehicleType === "large"
                                          ? "Ton luton body van with tail lift"
                                          : orderDetail?.driver?.vehicleDetails
                                              ?.vehicleType === "xl"
                                          ? "Ton luton box van with tail lift"
                                          : null}
                                      </Typography>
                                      <Typography
                                        color="textPrimary"
                                        className={classes.titleFont}
                                      >
                                        {
                                          orderDetail?.driver?.vehicleDetails
                                            ?.vehicleColor
                                        }
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} lg={4}>
                                    <Typography
                                      color="textPrimary"
                                      className={classes.titleFont}
                                    >
                                      {
                                        orderDetail?.driver?.vehicleDetails
                                          ?.vehicleColor
                                      }
                                    </Typography>
                                    <Typography className={classes.titleFont}>
                                      {
                                        orderDetail?.driver?.vehicleDetails
                                          ?.vehicleNumber
                                      }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Popover>
                          </Box>
                          <Typography className={classes.fontName}>
                            {orderDetail?.driver?.name}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            className={classes.fontName}
                          >
                            {orderDetail?.driver?.vehicleDetails
                              ?.vehicleType === "small"
                              ? "Medium Wheelbase Transit"
                              : orderDetail?.driver?.vehicleDetails
                                  ?.vehicleType === "medium"
                              ? "Long Wheelbase Transit"
                              : orderDetail?.driver?.vehicleDetails
                                  ?.vehicleType === "large"
                              ? "Ton luton body van with tail lift"
                              : orderDetail?.driver?.vehicleDetails
                                  ?.vehicleType === "xl"
                              ? "Ton luton box van with tail lift"
                              : null}
                          </Typography>

                          {orderDetail?.driver?.driverRating ? (
                            <Row>
                              <Rating value={1} max={1} readOnly />
                              <Box>
                                <Typography
                                  color="textPrimary"
                                  className={classes.titleFont}
                                >
                                  {orderDetail?.driver?.driverRating} (
                                  {orderDetail?.driver?.totalRide} Rides)
                                </Typography>
                              </Box>
                            </Row>
                          ) : null}
                          <Typography className={classes.heading}>
                            Scheduled on{" "}
                            {moment(orderDetail?.dateAndTime).format(
                              "MMMM DD, YYYY"
                            )}{" "}
                            at{" "}
                            {moment(orderDetail?.dateAndTime).format("h:mm a")}
                          </Typography>
                          {phone && (
                            <div className={classes.phoneDiv}>
                              <Typography
                                color="textPrimary"
                                variant="h6"
                                align="center"
                              >
                                Phone
                              </Typography>
                              <Typography
                                color="textPrimary"
                                variant="subtitle2"
                                align="center"
                              >
                                {orderDetail?.driver?.phone}
                              </Typography>
                            </div>
                          )}

                          {orderDetail?.orderStatus ===
                          "order complete" ? null : (
                            <Box>
                              <IconButton
                                className={classes.icons}
                                onClick={() => setPhone(!phone)}
                              >
                                <img
                                  src={
                                    mdMatch
                                      ? "/assets/images/call.png"
                                      : "/assets/images/call/callSmallicon.png"
                                  }
                                  style={{ paddingRight: "10%" }}
                                />
                              </IconButton>
                              <IconButton
                                className={classes.icons}
                                component={Link}
                                to={`/chat/${orderDetail?._id}`}
                              >
                                <img
                                  src={
                                    mdMatch
                                      ? "/assets/images/chat.png"
                                      : "/assets/images/call/chatSmallicon.png"
                                  }
                                />
                              </IconButton>
                            </Box>
                          )}
                        </CardContent>
                      </Box>
                    </Card>
                  )}
                  <Card className={classes.cardroot}>
                    <Box className={classes.fullWidth}>
                      <CardContent className={classes.contentCard}>
                        <Grid
                          justifyContent="space-between" // Add it here :)
                          container
                          alignItems="center"
                        >
                          <Grid item xs={9} lg={10}>
                            <Typography className={classes.summary}>
                              <b>Order Summary</b>
                            </Typography>
                          </Grid>
                          {orderDetail?.userFeedback?.rating &&
                          !orderDetail?.completeOrderProblemDetails
                            ?.completeOrderProblem &&
                          orderDetail?.orderStatus === "order complete" ? (
                            <Grid item xs={3} lg={2}>
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={9}
                                  md={11}
                                  lg={9}
                                  style={{ float: "right" }}
                                >
                                  <Rating
                                    value={orderDetail?.userFeedback?.rating}
                                    max={1}
                                    size={lgMatch ? "large" : "small"}
                                    style={{ float: "right" }}
                                    readOnly
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  md={1}
                                  lg={3}
                                  style={{ float: "right" }}
                                >
                                  <Box>
                                    <Typography
                                      color="textPrimary"
                                      style={
                                        lgMatch
                                          ? { fontSize: 32.4 }
                                          : { fontSize: 20 }
                                      }
                                    >
                                      {orderDetail?.userFeedback?.rating}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Box className={classes.date}>
                          <Typography className={classes.titleFont}>
                            <b>Date: </b> &nbsp;
                            <span color="textPrimary">
                              {moment(orderDetail?.dateAndTime).format(
                                "MMMM DD, YYYY h:mm a"
                              )}
                              &nbsp;
                            </span>
                          </Typography>
                        </Box>
                        <Typography className={classes.titleFont}>
                          <b>Order ID: </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.ID
                              ? orderDetail?.ID
                              : orderDetail?._id}
                            &nbsp;
                          </span>
                        </Typography>
                        <Grid
                          justifyContent="space-between" // Add it here :)
                          container
                          spacing={4}
                        >
                          <Grid item xs={12}>
                            <Typography className={classes.titleFont}>
                              <b>Order Status: </b> &nbsp;
                              <span style={{ color: "#1EBC80" }}>
                                {orderDetail?.orderStatus}&nbsp;
                              </span>
                            </Typography>
                            {orderDetail?.completeOrderProblemDetails
                              ?.completeOrderProblem ? (
                              <Box>
                                <Typography className={classes.titleFont}>
                                  <b>Complete Order Problem: </b> &nbsp;
                                  <span>
                                    {
                                      orderDetail?.completeOrderProblemDetails
                                        ?.completeOrderProblem
                                    }
                                    &nbsp;
                                  </span>
                                </Typography>
                                <Typography className={classes.titleFont}>
                                  <b>Description: </b> &nbsp;
                                  <span>
                                    {
                                      orderDetail?.completeOrderProblemDetails
                                        ?.description
                                    }
                                    &nbsp;
                                  </span>
                                </Typography>
                              </Box>
                            ) : null}
                          </Grid>

                          {orderDetail?.orderStatus === "order complete" &&
                          !orderDetail?.completeOrderProblemDetails
                            ?.completeOrderProblem &&
                          !orderDetail?.userFeedback?.rating ? (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                size={lgMatch ? "large" : "small"}
                                onClick={() => setOpenRating(true)}
                                className={classes.break}
                              >
                                Rate your Order
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Divider className={classes.dividerMargin} />
                        <Typography className={classes.titleFont}>
                          <b>Total helpers: </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.noOfHelpersRequired}&nbsp;
                          </span>
                        </Typography>
                        <Typography className={classes.titleFont}>
                          <b>Pickup Address: </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.tripStartLocation?.formattedAddress}
                            &nbsp;
                          </span>
                        </Typography>
                        <Typography className={classes.titleFont}>
                          <b>Floor # : </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.tripStartLocation?.floor}&nbsp;
                          </span>
                        </Typography>
                        <Typography className={classes.titleFont}>
                          <b>Destination Address: </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.tripEndLocation?.formattedAddress}
                            &nbsp;
                          </span>
                        </Typography>
                        <Typography className={classes.titleFont}>
                          <b>Floor # : </b> &nbsp;
                          <span color="textPrimary">
                            {orderDetail?.tripEndLocation?.floor}&nbsp;
                          </span>
                        </Typography>
                        <Divider
                          style={{ marginTop: "4%", marginBottom: "3%" }}
                        />
                        <Grid
                          justifyContent="space-between" // Add it here :)
                          container
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Booking Fee: </b> &nbsp;
                              <span color="textPrimary">
                                £{orderDetail?.estimatedPrice?.serviseCharger}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Driver Fee: </b> &nbsp;
                              <span color="textPrimary">
                                £{orderDetail?.estimatedPrice?.driverCharger}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch
                                  ? { fontSize: 16, color: "#c3c3c3 " }
                                  : { fontSize: 12, color: "#c3c3c3 " }
                              }
                            >
                              <b
                                style={
                                  themeType === "dark"
                                    ? { color: "#c3c3c3 " }
                                    : { color: "#000" }
                                }
                              >
                                Note:{" "}
                              </b>{" "}
                              &nbsp;
                              <span
                                style={
                                  themeType === "dark"
                                    ? { color: "#c3c3c3 " }
                                    : { color: "#000" }
                                }
                              >
                                May Vary due to Change in Cogestion and Toll
                                Charges &nbsp;
                              </span>
                            </Typography>

                            <Divider
                              style={{ marginTop: "4%", marginBottom: "3%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          justifyContent="space-between"
                          container
                          alignItems="center"
                          spacing={3}
                        >
                          <Grid item xs={5} sm={6}>
                            <Typography className={classes.titleFont}>
                              <b>Total Price: </b> &nbsp;
                              <span color="textPrimary">
                                £{orderDetail?.estimatedPrice?.maximumPrice}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b
                                style={
                                  themeType === "dark"
                                    ? { color: "#c3c3c3 " }
                                    : { color: "#000" }
                                }
                              >
                                Note:{" "}
                              </b>{" "}
                              &nbsp;
                              <span
                                style={
                                  themeType === "dark"
                                    ? { color: "#c3c3c3 " }
                                    : { color: "#000" }
                                }
                              >
                                May Vary due to Changes in Driver Fees &nbsp;
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={7} sm={6}>
                            <Button
                              color="primary"
                              size="large"
                              variant="contained"
                              onClick={handleClickOpen}
                              className={classes.break}
                            >
                              Price Breakdown
                            </Button>

                            <Dialog
                              maxWidth={lgMatch ? "sm" : "xs"}
                              open={openDialog}
                              classes={{
                                paper: classes.dialog,
                              }}
                              hideBackdrop={true}
                              onClose={handleCloseDialog}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <DialogTitle
                                id="responsive-dialog-title"
                                className={classes.price}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {"Estimated Price Breakdown"}
                                </span>
                                <br />
                                Total Price: £
                                {orderDetail?.estimatedPrice?.minimumPrice}
                                &nbsp;
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  {options.map((option) => (
                                    <Grid container key={option.id}>
                                      <Grid item xs={8} sm={6}>
                                        <Typography
                                          className={classes.titleAcc}
                                          color="textPrimary"
                                        >
                                          {option.title}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        xs={4}
                                        sm={6}
                                        justifyContent="flex-end"
                                      >
                                        <Typography color="textPrimary">
                                          <b>£{option.amount} </b>
                                        </Typography>
                                      </Grid>
                                      {option.id === 15 ? null : (
                                        <Divider className={classes.estDiv} />
                                      )}
                                    </Grid>
                                  ))}
                                </DialogContentText>
                                <Button
                                  variant={
                                    themeType === "dark"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  large
                                  fullWidth
                                  onClick={handleCloseDialog}
                                  color="primary"
                                  autoFocus
                                >
                                  Close
                                </Button>
                              </DialogContent>
                            </Dialog>
                          </Grid>
                        </Grid>
                        {orderDetail?.orderStatus == "payment_required" ? (
                          <Grid
                            justifyContent="space-between" // Add it here :)
                            container
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                              <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                onClick={() =>
                                  dispatch(
                                    actions.updatePendingOrderDetails(
                                      jwt,
                                      orderId
                                    )
                                  )
                                }
                                style={
                                  xlMatch
                                    ? {
                                        fontSize: 14,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                        marginLeft: "10px",
                                      }
                                    : lgMatch
                                    ? {
                                        fontSize: 14,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                        marginLeft: "10px",
                                      }
                                    : mdMatch
                                    ? {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                        marginLeft: "10px",
                                      }
                                    : smMatch
                                    ? {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                        marginLeft: "10px",
                                      }
                                    : {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                        marginLeft: "10px",
                                      }
                                }
                              >
                                Update Order
                              </Button>
                              <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                onClick={() =>
                                  history.push(`/payment/${orderId}`)
                                }
                                style={
                                  xlMatch
                                    ? {
                                        fontSize: 14,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                      }
                                    : lgMatch
                                    ? {
                                        fontSize: 14,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                      }
                                    : mdMatch
                                    ? {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                      }
                                    : smMatch
                                    ? {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                        paddingLeft: "32px",
                                        paddingRight: "32px",
                                      }
                                    : {
                                        fontSize: 12,
                                        textDecoration: "none",
                                        float: "right",
                                      }
                                }
                              >
                                Pay Now £:
                                {orderDetail?.estimatedPrice?.serviseCharger}
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </main>
      <DriverRating
        open={openRating}
        orderDetail={orderDetail}
        Id={orderDetail?._id}
        props={props}
        handleClose={handleCloseRating}
      />
      {orderFeedbackError ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {orderFeedbackError}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="success">
            {orderFeedbackSuccess}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
