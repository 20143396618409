//Libraries
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link, withRouter } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Row, Item } from "@mui-treasury/components/flex";
import PropTypes from "prop-types";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {
  MenuList,
  MenuItem,
  Button,
  SwipeableDrawer,
  useMediaQuery,
  Typography,
  CssBaseline,
  Drawer,
  useScrollTrigger,
  Toolbar,
  makeStyles,
  Switch,
  useTheme,
  Box,
  AppBar,
  Avatar,
  Slide,
  Hidden,
  IconButton,
  Badge,
} from "@material-ui/core";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import { useDynamicAvatarStyles } from "@mui-treasury/styles/avatar/dynamic";
import { useDispatch, useSelector } from "react-redux";

//Components
import actions from "../../Redux/User/User.actions";
import { applyTheme } from "../../Redux/Theme/themeActions";
import history from "../../Utils/history";

const drawerWidth = 240;

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "transparent",
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  paper: {
    background: "#6251FE",
    backgroundColor: "#6251FE",
  },
  list: {
    backgroundColor: "#6251FE",
    [theme.breakpoints.between("sm", "md")]: {
      width: 240,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      height: "100vh",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "secondary",
  },
  Button: {
    marginLeft: "0.5px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  boxMargin: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "16%",
    },
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#6251FE",
    [theme.breakpoints.only("xs")]: {
      width: drawerWidth - 50,
    },
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      width: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(13) + 1,
      backgroundColor: "#6251FE",
    },
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    padding: theme.spacing(0, 1),
  },
  menuText: {
    marginRight: "15px",
    textTransform: "uppercase",
  },
  menuText1: {
    marginRight: "15px",
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "right",
    color: "white",
  },
  list1: {
    color: "white",
  },
  menuitem: {
    marginRight: "6px",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "20px",
    },
  },
  icons: {
    marginRight: 0,
  },
  divmenu: {
    marginRight: "6px",
    color: "white",
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "20px",
    },
  },
  divitem: {
    [theme.breakpoints.only("lg")]: {
      marginTop: "10px",
      marginRight: "6px",
    },
    [theme.breakpoints.only("xl")]: {
      minHeight: "47%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  },
  "& .MuiIconButton-root": {
    padding: "0px",
    color: "#000000",
  },
  "& .MuiSvgIcon-root": {
    padding: "0px",
    color: "#000000",
  },
  purple: {
    backgroundColor: "#6251FE",
  },
  large: {
    paddingBottom: 0,
    height: "100vh",
    [theme.breakpoints.only("xs")]: {
      width: theme.spacing(4.5),
      fontSize: 14,
      height: theme.spacing(4.5),
    },
    [theme.breakpoints.only("sm")]: {
      width: theme.spacing(5.5),
      fontSize: 16,
      height: theme.spacing(5.5),
    },
    [theme.breakpoints.only("md")]: {
      width: theme.spacing(5.5),
      fontSize: 18,
      height: theme.spacing(5.5),
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.spacing(7),
      fontSize: 20,
      height: theme.spacing(7),
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.spacing(7),
      fontSize: 22,
      height: theme.spacing(7),
    },
  },
  box: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "3%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "0.1%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "1%",
    },
  },
  gap: {
    marginLeft: "auto",
    color: "black",
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      marginTop: "3%",
    },
    [theme.breakpoints.only("sm")]: {
      marginRight: "0.1%",
    },
    [theme.breakpoints.only("md")]: {
      marginRight: "1%",
    },
    [theme.breakpoints.only("lg")]: {
      marginRight: "3%",
    },
    [theme.breakpoints.only("xl")]: {
      marginRight: "4%",
    },
  },
  text: {
    [theme.breakpoints.down("md")]: {
      marginTop: "7%",
      fontSize: 20,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "9%",
      fontSize: 25.6,
    },
  },
  logo: {
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    [theme.breakpoints.only("xs")]: {
      marginRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: 50,
    },
  },
}));

//Hide On scroll
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NavigationDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const lgMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xsMatch = useMediaQuery(theme.breakpoints.only("xs"));
  const [open, setOpen] = React.useState(false);
  const avatarStyles = useDynamicAvatarStyles(
    lgMatch ? { size: 56 } : { size: 34 }
  );
  const [opened, setOpened] = useState(false);
  const themesType = useSelector((state) => state.themes.theme);
  const dispatch = useDispatch();
  const themeType = useSelector((state) => state.themes.theme);

  //for taking first word of name

  const username = useSelector((state) => state.user.name);
  const name = username ? username : "user";
  let myStr = name;
  let firstWord = name ? myStr.split(" ")[0] : null;

  let myStr2 = name.toUpperCase();
  let first = name ? myStr2.match(/\b(\w)/g) : null;
  const firstChar = first.slice(0, 3);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeThemeHandler = () => {
    if (themesType === "dark") {
      dispatch(applyTheme("light"));
    } else if (themesType === "light") {
      dispatch(applyTheme("dark"));
    }
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdDown>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Box className={classes.box}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.Button, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <HideOnScroll {...props}>
              <Box className={classes.gap}>
                <Row p={1} gap={3}>
                  <Box mt={2}>
                    {themesType == "dark" ? (
                      <label htmlFor="icon-button-file">
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness4Icon color="secondary" />
                        </IconButton>
                      </label>
                    ) : (
                      <label htmlFor="icon-button-file">
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness7Icon style={{ color: "#ffbf00" }} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                  {/* <Boxs
                    sm={{ mt: '1%' }}
                    md={{ mt: '2%' }}
                    lg={{ mt: '4%' }}
                    xl={{ mt: '4%' }}
                  >
                    <div onClick={() => history.push('/notifications')}>
                      <label htmlFor='icon-button-file'>
                        <IconButton
                          aria-label='upload picture'
                          component='span'
                        >
                          <Badge badgeContent={1} color='secondary'>
                            <NotificationsIcon fontSize='large' />
                          </Badge>
                        </IconButton>
                      </label>
                    </div>
                  </Boxs> */}
                  <Typography className={classes.text} color="textPrimary">
                    <b>{firstWord}</b>
                  </Typography>
                  <div onClick={() => history.push("/profile")}>
                    <Item>
                      <Avatar
                        classes={avatarStyles}
                        className={clsx(classes.large, classes.purple)}
                      >
                        {firstChar}
                      </Avatar>
                      {/* <Avatar
                        classes={avatarStyles}
                        src={'/assets/images/user.png'}
                      /> */}
                    </Item>
                  </div>
                </Row>
              </Box>
            </HideOnScroll>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Typography
              variant="h5"
              className={clsx(classes.menuText1, {
                [classes.hide]: !open,
              })}
            >
              Logo
            </Typography>
            <IconButton
              onClick={handleDrawerClose}
              className={clsx(classes.menuButton, {
                [classes.hide]: !open,
              })}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightRoundedIcon fontSize="large" />
              ) : (
                <img
                  src="/assets/images/menuicons/lefticon.png"
                  alt="picture"
                />
              )}
            </IconButton>
          </div>
          <Box className={classes.boxMargin}>
            <MenuList className={classes.list1}>
              <MenuItem
                component={Link}
                id="click-home"
                to="/home"
                className={classes.menuitem}
              >
                <Box marginRight="20px">
                  <img src="/assets/images/menuicons/home.png" alt="picture" />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Home
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to="/profile"
                id="click-profile"
                className={classes.menuitem}
              >
                <Box marginRight="20px">
                  <img
                    src="/assets/images/menuicons/profile.png"
                    alt="picture"
                  />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  User Profile
                </Typography>
              </MenuItem>
              <MenuItem
                id={"click-past-orders"}
                component={Link}
                to="/orders"
                className={classes.menuitem}
              >
                <img src="/assets/images/menuicons/order.png" alt="picture" />
                <Box marginRight="20px" />
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Orders
                </Typography>
              </MenuItem>
              {/* <MenuItem
                component={Link}
                to='/payment'
                className={classes.menuitem}
              >
                <img src='/assets/images/menuicons/coins.png' alt='picture' />
                <Box marginRight='20px' />
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Coins
                </Typography>
              </MenuItem> */}
              {/*<MenuItem
              component={Link}
              to="/wallet"
              className={classes.menuitem}
            >
              <img src='/assets/images/menuicons/wallet.png' alt='picture' />
              <Box marginRight="20px" />
              <Typography
                className={clsx(classes.menuText, {
                  [classes.hide]: !open,
                })}>Wallet</Typography>
              </MenuItem>*/}
              <MenuItem
                id="click-setting"
                component={Link}
                to="/settings"
                className={classes.menuitem}
              >
                <img src="/assets/images/menuicons/setting.png" alt="picture" />
                <Box marginRight="20px"></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Settings
                </Typography>
              </MenuItem>
              {/*<MenuItem
              component={Link}
              to="/about_us"
              className={classes.menuitem}
            >
              <img src='/assets/images/menuicons/info.png' alt='picture' />
              <Box marginRight="20px"></Box>
              <Typography
                className={clsx(classes.menuText, {
                  [classes.hide]: !open,
                })}>Info</Typography>
              </MenuItem>*/}
              <MenuItem
                component={Link}
                id="click-help"
                to="/help"
                className={classes.menuitem}
              >
                <img src="/assets/images/menuicons/Help.png" alt="picture" />
                <Box marginRight="20px"></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Help
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to="/invite"
                id="click-invite"
                className={classes.menuitem}
              >
                <img src="/assets/images/menuicons/share.png" alt="picture" />
                <Box marginRight="20px"></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Share
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to="/contact_us"
                id="click-contact"
                className={classes.menuitem}
              >
                <Box marginRight="20px">
                  <img
                    src="/assets/images/menuicons/contact.png"
                    alt="picture"
                  />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open,
                  })}
                >
                  Contact Us
                </Typography>
              </MenuItem>
              <div className={classes.divitem}>
                <div onClick={() => history.push("/sign_in")}>
                  <MenuItem
                    component={Button}
                    className={classes.divmenu}
                    onClick={() => dispatch(actions.signOut())}
                  >
                    <Box marginRight="20px">
                      <img
                        src="/assets/images/menuicons/logout.png"
                        alt="picture"
                      />
                    </Box>
                    <Typography
                      className={clsx(classes.menuText, {
                        [classes.hide]: !open,
                      })}
                    >
                      Logout
                    </Typography>
                  </MenuItem>
                </div>
              </div>
            </MenuList>
          </Box>
        </Drawer>
      </Hidden>

      {/* Swipeable Drawer */}
      <Hidden lgUp>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Box className={classes.box}>
              <IconButton
                // color='inherit'
                style={
                  themeType == "dark" ? { color: "white" } : { color: "black" }
                }
                aria-label="open drawer"
                onClick={() => setOpened(true)}
                edge="start"
                className={clsx(classes.Button, {
                  [classes.hide]: open,
                })}
              >
                <HideOnScroll {...props}>
                  <MenuIcon />
                </HideOnScroll>
              </IconButton>
            </Box>

            <HideOnScroll {...props}>
              <Box className={classes.gap}>
                <Row p={1} gap={3}>
                  <Box mt={1}>
                    {themesType == "dark" ? (
                      <label htmlFor="icon-button-file">
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness4Icon color="secondary" />
                        </IconButton>
                      </label>
                    ) : (
                      <label htmlFor="icon-button-file">
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness7Icon style={{ color: "#ffbf00" }} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                  {/* <Boxs
                    sm={{ mt: '1%' }}
                    md={{ mt: '2%' }}
                    lg={{ mt: '4%' }}
                    xl={{ mt: '4%' }}
                  >
                    <div onClick={() => history.push('/notifications')}>
                      <label htmlFor='icon-button-file'>
                        <IconButton
                          aria-label='upload picture'
                          component='span'
                          color='secondary'
                        >
                          <Badge badgeContent={1} color='secondary'>
                            <NotificationsIcon fontSize='large' />
                          </Badge>
                        </IconButton>
                      </label>
                    </div>
                  </Boxs> */}
                  <Typography className={classes.text} color="textPrimary">
                    <b>{firstWord}</b>
                  </Typography>
                  <div
                    onClick={() => history.push("/profile")}
                    color="secondary"
                  >
                    <Item>
                      <Avatar
                        classes={avatarStyles}
                        className={clsx(classes.large, classes.purple)}
                      >
                        {firstChar}
                      </Avatar>
                    </Item>
                  </div>
                </Row>
              </Box>
            </HideOnScroll>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          classes={{ paper: classes.paper }}
          anchor="left"
          open={opened}
          onClose={() => setOpened(false)}
          onOpen={() => {}}
        >
          <div className={classes.list}>
            <div className={classes.toolbar}>
              <Typography variant="h5" className={classes.logo}>
                Logo
              </Typography>
              <IconButton
                className={classes.icons}
                onClick={() => setOpened(false)}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightRoundedIcon fontSize="large" />
                ) : (
                  <img
                    src="/assets/images/menuicons/lefticon.png"
                    alt="picture"
                  />
                )}
              </IconButton>
            </div>
            <Box className={classes.boxMargin}>
              <MenuList className={classes.list1}>
                <MenuItem
                  component={Link}
                  id="click-home"
                  to="/home"
                  className={classes.menuitem}
                >
                  <Box marginRight="20px">
                    <img
                      src="/assets/images/menuicons/home.png"
                      alt="picture"
                    />
                  </Box>
                  <Typography className={classes.menuText}>Home</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/profile"
                  id="click-profile"
                  className={classes.menuitem}
                >
                  <Box marginRight="20px">
                    <img
                      src="/assets/images/menuicons/profile.png"
                      alt="picture"
                    />
                  </Box>
                  <Typography className={classes.menuText}>
                    User Profile
                  </Typography>
                </MenuItem>
                <MenuItem
                  id={"click-past-orders"}
                  component={Link}
                  to="/orders"
                  className={classes.menuitem}
                >
                  <img src="/assets/images/menuicons/order.png" alt="picture" />
                  <Box marginRight="20px" />
                  <Typography className={classes.menuText}>Orders</Typography>
                </MenuItem>
                {/* <MenuItem
                  component={Link}
                  to='/payment'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/coins.png' alt='picture' />
                  <Box marginRight='20px' />
                  <Typography className={classes.menuText}>Coins</Typography>
                </MenuItem> */}
                {/*<MenuItem
              component={Link}
              to="/wallet"
              className={classes.menuitem}
            >
              <img src='/assets/images/menuicons/wallet.png' alt='picture' />
              <Box marginRight="20px" />
              <Typography className={classes.menuText}>Wallet</Typography>
              </MenuItem>*/}
                <MenuItem
                  id="click-setting"
                  component={Link}
                  to="/settings"
                  className={classes.menuitem}
                >
                  <img
                    src="/assets/images/menuicons/setting.png"
                    alt="picture"
                  />
                  <Box marginRight="20px"></Box>
                  <Typography className={classes.menuText}>Settings</Typography>
                </MenuItem>
                {/*<MenuItem
              component={Link}
              to="/info"
              className={classes.menuitem}
            >
              <img src='/assets/images/menuicons/info.png' alt='picture' />
              <Box marginRight="20px"></Box>
              <Typography className={classes.menuText}>Info</Typography>
            </MenuItem>*/}
                <MenuItem
                  component={Link}
                  id="click-help"
                  to="/help"
                  className={classes.menuitem}
                >
                  <img src="/assets/images/menuicons/Help.png" alt="picture" />
                  <Box marginRight="20px"></Box>
                  <Typography className={classes.menuText}>Help</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/invite"
                  id="click-invite"
                  className={classes.menuitem}
                >
                  <img src="/assets/images/menuicons/share.png" alt="picture" />
                  <Box marginRight="20px"></Box>
                  <Typography className={classes.menuText}>Share</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/contact_us"
                  id="click-contact"
                  className={classes.menuitem}
                >
                  <Box marginRight="20px">
                    <img
                      src="/assets/images/menuicons/contact.png"
                      alt="picture"
                    />
                  </Box>
                  <Typography className={classes.menuText}>
                    Contact Us
                  </Typography>
                </MenuItem>

                <div className={classes.divitem}>
                  <div onClick={() => history.push("/sign_in")}>
                    <MenuItem
                      component={Button}
                      to="/signin"
                      className={classes.divmenu}
                      onClick={() => dispatch(actions.signOut())}
                    >
                      <Box marginRight="20px">
                        <img
                          src="/assets/images/menuicons/logout.png"
                          alt="picture"
                        />
                      </Box>
                      <Typography className={classes.menuText}>
                        Logout
                      </Typography>
                    </MenuItem>
                  </div>
                </div>
              </MenuList>
            </Box>
          </div>
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
}
