//Libraries
import React, { useEffect } from 'react'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Hidden,
  Paper,
  TablePagination,
  Card,
  Box,
  Grid,
  Typography,
  Divider
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'

//Component
import actions from '../../Redux/Order/Order.actions'
import Loader from '../../Components/Loader/Loader'
import NoOrders from '../../Components/NoOrder/NoOrders.component'
import history from '../../Utils/history'

//Style
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    border: 'none',
    borderRadius: 8,
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)'
  },
  tableCell: {
    color: '#FF8800',
    fontWeight: 'bold'
  },
  row: {
    height: '80px'
  },
  cell: {
    color: '#FFFFFF'
  },
  cardroot: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    textDecoration: 'none',
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: '3%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
      marginTop: '4%'
    }
  },
  Id: {
    fontSize: 12
  },
  Id1: {
    fontWeight: 'bold',
    fontSize: 12
  },
  div: {
    width: '100%',
    marginTop: '1%',
    marginBottom: '1%'
  },
  heading: {
    color: theme.palette.type === 'light' ? '#6251FE' : 'null',
    fontSize: 14,
    fontWeight: 'bold'
  },
  boxStyle: {
    width: '100%',
    marginTop: '15%',
    display: 'flex',
    justifyContent: 'center'
  },
  tabRow: {
    height: '80px',
    textDecoration: 'none'
  },
  boxs: {
    width: '100%'
  }
}))

export default function PendingOrders () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(4)
  const [pageNo, setPageNo] = React.useState(1)
  const jwt = useSelector(state => state.user.jwt)
  const pendingOrders = useSelector(state => state.order.pendingOrders)

  useEffect(() => {
    dispatch(actions.getPendingOrdersList(jwt))
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangePageNo = (event, newPage) => {
    setPageNo(newPage)
  }

  return (
    <div>
      <Hidden smDown>
        {Array.isArray(pendingOrders) && pendingOrders.length > 0 ? (
          <>
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label='simple table' component='div'>
                <TableHead component='div'>
                  <TableRow
                    component='div'
                    className={classes.row}
                    style={{ backgroundColor: '#6251FE' }}
                  >
                    <TableCell className={classes.cell} component='div'>Ordered ID</TableCell>
                    <TableCell className={classes.cell} component='div'>
                      Pickup Address
                    </TableCell>
                    <TableCell className={classes.cell} component='div'>
                      Destination Address
                    </TableCell>
                    <TableCell className={classes.cell} component='div'>Date</TableCell>
                    <TableCell className={classes.cell} component='div'>Cost</TableCell>
                    <TableCell className={classes.cell} component='div'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody component='div'>
                  {pendingOrders.slice(page * 4, page * 4 + 4).map(row => {
                    return (
                      <TableRow
                        key={row._id}
                        component={Link}
                        to={`/order_details/${row._id}`}
                        className={classes.tabRow}
                      >
                        <TableCell scope='row' component='div'>
                          {row?.ID ? row?.ID : row?._id}
                        </TableCell>
                        <TableCell align='left' component='div'>
                          {row?.tripStartLocation?.formattedAddress}
                        </TableCell>
                        <TableCell align='left' component='div'>
                          {row?.tripEndLocation?.formattedAddress}
                        </TableCell>
                        <TableCell align='left' component='div'>
                          {moment(row?.dateAndTime).format('MMMM DD, YYYY')}
                        </TableCell>
                        <TableCell align='left' component='div'>
                          £{row?.estimatedPrice?.maximumPrice}
                        </TableCell>
                        <TableCell align='left' className={classes.tableCell} component='div'>
                          {row?.orderStatus}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7]}
              component='div'
              count={pendingOrders && pendingOrders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </>
        ) : Array.isArray(pendingOrders) && pendingOrders.length === 0 ? (
          <NoOrders setFieldValue={'pending'} />
        ) : (
          <Loader marginTop={'150px'} />
        )}
      </Hidden>
      <Hidden mdUp>
        {Array.isArray(pendingOrders) && pendingOrders.length > 0 ? (
          <>
            {pendingOrders &&
              pendingOrders.slice((pageNo - 1) * 1, pageNo * 1).map(row => {
                return (
                  <Card
                    className={classes.cardroot}
                    key={row._id}
                    elevation={2}
                  >
                    <div
                      onClick={() => history.push(`/order_details/${row._id}`)}
                    >
                      <Box className={classes.boxs}>
                        <Grid container>
                          <Grid item xs={5}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Ordered ID
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={7} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                {row?.ID ? row?.ID : row?._id}
                              </Typography>
                            </Box>
                          </Grid>
                          <Divider className={classes.div} />
                          <Grid item xs={6}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Pickup Address
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                {row?.tripStartLocation?.formattedAddress}
                              </Typography>
                            </Box>
                          </Grid>
                          <Divider className={classes.div} />
                          <Grid item xs={6}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Destination Address
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                {row?.tripEndLocation?.formattedAddress}
                              </Typography>
                            </Box>
                          </Grid>
                          <Divider className={classes.div} />

                          <Grid item xs={6}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Date
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                {moment(row?.dateAndTime).format(
                                  'MMMM DD, YYYY'
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Divider className={classes.div} />
                          <Grid item xs={6}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Cost
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                £{row?.estimatedPrice?.maximumPrice}
                              </Typography>
                            </Box>
                          </Grid>
                          <Divider className={classes.div} />
                          <Grid item xs={6}>
                            <Box m={2}>
                              <Typography className={classes.heading}>
                                Status
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} align='right'>
                            <Box m={2}>
                              <Typography className={classes.Id}>
                                {row?.orderStatus}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </Card>
                )
              })}
            <Box className={classes.boxStyle}>
              <Pagination
                count={pendingOrders && pendingOrders.length}
                shape='rounded'
                page={pageNo}
                size='small'
                onChange={handleChangePageNo}
              />
            </Box>
          </>
        ) : Array.isArray(pendingOrders) && pendingOrders.length === 0 ? (
          <NoOrders setFieldValue={'pending'} />
        ) : (
          <Loader marginTop={'150px'} />
        )}
      </Hidden>
    </div>
  )
}


