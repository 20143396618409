// const backendPath = 'http://172.20.83.124:3005/api/user/'
// const backendPath = 'http://192.168.0.105:3005/api/user/'
// const backendPath = 'https://api.ineffabledevs.com/api/user/'
// const backendPath = 'https://api.ineffabledevs.com/api/user/'
const backendPath = "https://api.moverbuddy.io/api/user/";
// const backendPath = 'http://localhost:3005/api/user/'
// const backendPath = 'http://ec89-203-124-40-244.ngrok.io/api/user/'
// const backendPath = 'http://localhost:3005/api/user/'

// export const webSocketLiveLocationPath = `ws://localhost:3005/api/user/` //for LocalHost
export const webSocketLiveLocationPath = "wss://api.moverbuddy.io/api/user/"; // for Live Server

// export const webSocketLiveChatPath = `ws://localhost:3005/api/user/` //for LocalHost

export const webSocketLiveChatPath = "wss://api.moverbuddy.io/api/user/"; // for Live Server

export default backendPath;

// userA@gmail.com
// Saad@1245
//1111111111
