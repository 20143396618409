//Libraries
import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  CssBaseline,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { useDynamicAvatarStyles } from "@mui-treasury/styles/avatar/dynamic";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";

//Components
import actions from "../../../Redux/User/User.actions";
import Yup from "../../../Yup/YupAuthValidation.schemas";
import NavigationDrawer from "../../../Components/NavigationDrawer/NavigationDrawer";
import Loader from "../../../Components/Loader/Loader";
function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

//Style
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
  submit: {
    fontWeight: "bold",
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(1, 0, 0),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  image: {
    height: "auto",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: theme.spacing(12),
      width: "50%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: theme.spacing(12),
      width: "60%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(15),
      width: "80%",
    },
  },
  heading: {
    fontWeight: "bold",
    paddingTop: "2%",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 28,
    },
  },
  box: {
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      padding: "1%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "60%",
      padding: "3%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
      padding: "1%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "40%",
      padding: "1%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "30%",
      padding: "3%",
    },
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.isFetching);
  useEffect(() => {
    dispatch(actions.clearError());
  }, []);

  const phone = useSelector((state) => state.user.phone);
  const visible = useSelector((state) => state.user.visible);

  const [open, setOpen] = useState(false);

  const [initialValues, setInitialValues] = useState({
    phone: phone,
    password: "",
    showPassword: false,
  });
  const initialTimer = localStorage.getItem("seconds") ?? 60;
  const timeoutId = React.useRef(null);
  const [seconds, setSeconds] = React.useState(initialTimer);

  const countTimer = React.useCallback(() => {
    if (seconds <= 0) {
      localStorage.clear("currentPasSeconds");
    } else {
      setSeconds(seconds - 1);
      localStorage.setItem("currentPasSeconds", seconds - 1);
    }
  }, [seconds]);

  React.useEffect(() => {
    if (seconds > 0) {
      timeoutId.current = window.setTimeout(countTimer, 1000);
      // cleanup function
      return () => window.clearTimeout(timeoutId.current);
    } else {
      setSeconds(0);
      localStorage.setItem("currentPasSeconds", seconds);
    }
  }, [seconds, countTimer]);
  const resetResendButton = () => {
    setSeconds(60);
  };
  const handleClickShowPassword = () => {
    setInitialValues({
      ...initialValues,
      showPassword: !initialValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const currentPassError = useSelector(
    (state) => state.user.errors.currentPassError
  );

  const currentPassword = useSelector((state) => state.user.currentPassword);

  useEffect(() => {
    if (currentPassError) {
      handleErrorSnack();
    }
  }, [currentPassError]);

  useEffect(() => {
    dispatch(actions.clearError());
    dispatch(actions.resetChangePassView());
  }, [dispatch]);

  const handleErrorSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [Values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showPassword1: false,
  });

  const handleShowPassword = () => {
    setValues({
      ...Values,
      showPassword: !Values.showPassword,
    });
  };

  const handleMDownPassword = (event) => {
    event.preventDefault();
  };

  const handleShowPassword1 = () => {
    setValues({
      ...Values,
      showPassword1: !Values.showPassword1,
    });
  };

  const jwt = useSelector((state) => state.user.jwt);

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.box}>
            <img
              src="/assets/images/createPass.png"
              alt="picture"
              className={classes.image}
            />
            <Typography className={classes.heading}>Reset Password</Typography>
            {!visible ? (
              <Box pt={"2%"}>
                <Typography>Enter your current Password</Typography>
                <Formik
                  key="1"
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    dispatch(
                      actions.currentPass(phone, values.password, props)
                    );
                    resetResendButton();
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <Box textAlign="center">
                        <TextField
                          error={errors.password && touched.password}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : null
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="password"
                          placeholder="Enter your Current Password"
                          id="password"
                          className={classes.fields}
                          type={
                            initialValues.showPassword ? "text" : "password"
                          }
                          autoComplete="password"
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {initialValues.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          type="submit"
                          fullWidth
                          color="primary"
                          size="large"
                          variant="contained"
                          className={classes.submit}
                        >
                          {loading ? <Loader /> : "Submit"}
                          {/* {loading ? <Loader /> : 'Submit'} */}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
                {currentPassError ? (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleCloseSnack}
                  >
                    <Alert onClose={handleCloseSnack} severity="error">
                      {currentPassError}
                    </Alert>
                  </Snackbar>
                ) : null}
              </Box>
            ) : (
              <Box pt={"2%"}>
                <Typography>
                  Your new Password must be different from the previous password
                  you've used
                </Typography>
                <Formik
                  key="2"
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.ConfirmPasswordSchema}
                  onSubmit={(values) => {
                    dispatch(
                      actions.resetPassword(
                        values.password,
                        jwt,
                        currentPassword,
                        props
                      )
                    );
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <Box textAlign="center">
                        <TextField
                          error={errors.password && touched.password}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : null
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="password"
                          placeholder="Enter New Password"
                          id="password"
                          className={classes.fields}
                          type={Values.showPassword ? "text" : "password"}
                          autoComplete="password"
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                  onMouseDown={handleMDownPassword}
                                  edge="end"
                                >
                                  {Values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box textAlign="center">
                        <TextField
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : null
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="confirmPassword"
                          className={classes.fields}
                          placeholder="Confirm Password"
                          id="confirmPassword"
                          type={Values.showPassword1 ? "text" : "password"}
                          autoComplete="confirm-password"
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword1}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {Values.showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          type="submit"
                          fullWidth
                          color="primary"
                          size="large"
                          variant="contained"
                          className={classes.submit}
                        >
                          {loading ? <Loader /> : "Submit"}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
          </Box>
        </div>
      </main>
    </div>
  );
}
