//Libraries
import React from "react";
import {
  makeStyles,
  CssBaseline,
  Typography,
  Box,
  Grid,
  Divider,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

//Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

//Style
const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "4%",
    fontWeight: "bold",
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.125rem",
      marginTop: "5%",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.125rem",
      marginTop: "3%",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5rem",
      marginTop: "3%",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.95rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "2.125rem",
    },
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  submit: {
    margin: theme.spacing(2, 0, 4),
    fontWeight: "bold",
  },
  root: {
    display: "flex",
  },
  heading: {
    color: "primary",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.25rem",
    },
  },
  subheading: {
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.25rem",
    },
  },
  boxes: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "95%",
      padding: "1%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "80%",
      padding: "2%",
    },
  },
  image: {
    marginTop: theme.spacing(2),
  },
  div: {
    marginTop: "1%",
    marginBottom: "1%",
  },
}));

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    textAlign: "left",
  },
}))(MuiAccordionDetails);

export default function FAQs() {
  const classes = useStyles();

  const options = [
    {
      id: "panel1",
      question: "What kind of transport vehicles do you provide ?",
      answer:
        "We provide our customers with 4 transit options which are as following: Medium Wheelbase Transit (MWB) Long Wheelbase Transit (LWB) Ton Luton Body Van with Tail Lift (TLBV) Ton Luton Box Van with Tail Lift (TLBV).",
    },
    {
      id: "panel2",
      question: "Why my profile is not updating ?",
      answer: "When your order is active than profile cannot be updated.",
    },
    {
      id: "panel3",
      question: "What charges should be considered for cost calculation ?",
      answer:
        "The Following will be considered for cost estimation Distance, Number of vehicles, Building Floor, Schedule Day, Inter City or intra city, Number of Helpers.",
    },
    {
      id: "panel4",
      question: "How can I contact the admin ?",
      answer:
        "The Admin can be contacted via following methods via email, chat message and call.",
    },
    {
      id: "panel5",
      question: "How do i complete the order payment",
      answer:
        "You can complete the payment via paypal, mastercard, visa and your account wallet.",
    },
    {
      id: "panel6",
      question: "What are the payment methods available ?",
      answer:
        "You can complete the payment via paypal, mastercard, visa and your account wallet.",
    },
    {
      id: "panel7",
      question: "Can i change my order details after order is submitted ?",
      answer: "In case of any order update you have to contact the admin.",
    },
    {
      id: "panel8",
      question: "How do drivers contact me ?",
      answer: "The driver can be contacted using chat and call.",
    },

    {
      id: "panel9",
      question: "What is the minimum bid amount ?",
      answer:
        "The minimum cost amount  is as follows: Within 5 KM =  25 Within 10 KM = 75 Within 20 KM = 150 Within 30 KM = 250.",
    },
  ];
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container align="center" alignContent="center">
            <Hidden lgUp>
              <Grid item sm={12} xs={12} md={12} lg={false} xl={false}>
                <img src="/assets/images/Faq.png" />
              </Grid>
            </Hidden>
            <Grid item sm={12} xs={12} md={12} lg={8} xl={8} align="center">
              <Box className={classes.boxes}>
                <Typography className={classes.title} align="center">
                  Frequently ask Questions
                </Typography>
                {options.map((option) => (
                  <React.Fragment key={option.id}>
                    <Accordion
                      expanded={expanded === option.id}
                      onChange={handleChange(option.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === option.id ? <RemoveIcon /> : <AddIcon />
                        }
                      >
                        <Typography className={classes.heading}>
                          {option.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.subheading}>
                          {option.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Divider className={classes.div} />{" "}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
            <Hidden mdDown>
              <Grid
                item
                xs={false}
                sm={false}
                md={false}
                lg={4}
                xl={4}
                align="center"
              >
                <img src="/assets/images/FAQs.png" className={classes.image} />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </main>
    </div>
  );
}
