//Libraries
import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import {
  Box,
  Grid,
  Hidden,
  useMediaQuery,
  Dialog,
  Card,
  CardContent,
  Typography,
  TextField,
  Avatar,
  Button
} from '@material-ui/core'
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic'
import { Item } from '@mui-treasury/components/flex'
import Rating from '@material-ui/lab/Rating'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik, ErrorMessage } from 'formik'

//Libraries
import actions from '../../Redux/Order/Order.actions'
import Yup from '../../Yup/YupRating.schemas'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    height: '25%',
    borderRadius: 8
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  content: {
    flexGrow: 1
  },
  heading: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14
    },
    [theme.breakpoints.only('lg', 'xl')]: {
      fontSize: 22.6
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only('xs')]: {
      marginTop: '30%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '20%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '18%'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '9%'
    }
  },
  boxs: {
    marginBottom: '2%',
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '20%'
    }
  },
  submit: {
    margin: theme.spacing(2, 0, 0)
  },
  MuiCardContent: {
    width: '100%',
    display: 'center',
    textAlign: 'center'
  },
  boxRoot: {
    width: '100%',
    padding: '3%'
  },
  grid: {
    paddingTop: '3%'
  },
  title: {
    paddingTop: '2%',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 12
    },
    [theme.breakpoints.only('lg', 'xl')]: {
      fontSize: 18
    }
  },
  text: {
    paddingTop: '2%',
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 12
    },
    [theme.breakpoints.only('lg', 'xl')]: {
      fontSize: 16
    }
  },
  gridText: {
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.only('md', 'xl')]: {
      textAlign: 'right'
    }
  },
  dialog: {
    borderRadius: 8
  }
}))

export default function DriverRating ({ open, handleClose, orderDetail, Id }) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))

  const dispatch = useDispatch()
  const jwt = useSelector(state => state.user.jwt)
  const avatarStyles = useDynamicAvatarStyles(
    lgMatch ? { size: 80 } : { size: 62 }
  )

  const [initialValues, setInitialValues] = useState({
    value: 0,
    comment: ''
  })
  const renderError = message => (
    <p className='help is-danger' style={{ color: '#f44336' }}>
      This Field is required
    </p>
  )

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      aria-labelledby='max-width-dialog-title'
      hideBackdrop={true}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.ratingSchema}
        onSubmit={values => {
          dispatch(actions.orderFeedback(jwt, Id, values))
          handleClose()
        }}
      >
        {({ errors, handleChange, touched }) => (
          <Form>
            <Card className={classes.root} elevation={2}>
              <Box className={classes.boxRoot}>
                <CardContent>
                  <Item>
                    <Avatar
                      classes={avatarStyles}
                      className={clsx(classes.large, classes.purple)}
                    >
                      {(orderDetail?.driver?.name).match(/\b(\w)/g)}
                    </Avatar>
                  </Item>
                  <Grid container className={classes.grid}>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                      <Typography className={classes.heading}>
                        {orderDetail?.driver?.name}
                      </Typography>
                      <Typography color='secondary' className={classes.title}>
                        {orderDetail?.driver?.vehicleDetails?.vehicleType ===
                        'small'
                          ? 'Medium Wheelbase Transit'
                          : orderDetail?.driver?.vehicleDetails?.vehicleType ===
                            'medium'
                          ? 'Long Wheelbase Transit'
                          : orderDetail?.driver?.vehicleDetails?.vehicleType ===
                            'large'
                          ? 'Ton luton body van with tail lift'
                          : orderDetail?.driver?.vehicleDetails?.vehicleType ===
                            'xl'
                          ? 'Ton luton box van with tail lift'
                          : null}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className={classes.gridText}
                    >
                      <Hidden mdDown>
                        <Typography className={classes.heading}>
                          <b>£{orderDetail?.estimatedPrice?.maximumPrice}</b>
                        </Typography>
                      </Hidden>
                      <Typography className={classes.text}>
                        Scheduled on{' '}
                        {moment(orderDetail?.dateAndTime).format(
                          'MMMM DD, YYYY'
                        )}{' '}
                        at {moment(orderDetail?.dateAndTime).format('h:mm a')}
                      </Typography>
                      <Hidden lgUp>
                        <Typography className={classes.heading}>
                          <b>£{orderDetail?.estimatedPrice?.maximumPrice}</b>
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Grid>
                  <Box className={classes.boxs}>
                    <Box
                      textAlign='center'
                      component='fieldset'
                      borderColor='transparent'
                    >
                      <Rating
                        id='value'
                        name='value'
                        type='number'
                        onChange={(event, newValue) =>
                          setInitialValues({
                            ...initialValues,
                            value: newValue
                          })
                        }
                        size={lgMatch ? 'large' : 'small'}
                      />
                      <ErrorMessage name='value' render={renderError} />
                    </Box>
                    <TextField
                      error={errors.comment && touched.comment}
                      helperText={
                        errors.comment && touched.comment
                          ? errors.comment
                          : null
                      }
                      id='comment'
                      name='comment'
                      placeholder='Leave a Comment'
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      onChange={handleChange}
                      multiline={true}
                      rows={3}
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleClose}
                        fullWidth
                        style={{ backgroundColor: '#F5F6FE' }}
                        size='large'
                        variant='contained'
                        className={classes.submit}
                      >
                        No, Thanks
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        type='submit'
                        color='primary'
                        size='large'
                        variant='contained'
                        className={classes.submit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
