import axios from "axios";
import { createNotificationSubscription } from "../../PushNotifications/Notifications.config";
import backendPath from "../../Utils/constants";
import history from "../../Utils/history";
const actions = {
  //#region ACTION DECLARATIONS
  GET_USER: "user/GET_USER",
  GET_USER_ERROR: "user/GET_USER_ERROR",
  SET_USER: "user/SET_USER",
  GET_NOTIFICATION: "user/GET_NOTIFICATION",
  SET_PHONE_OTP_ERROR: "user/SET_PHONE_OTP_ERROR",
  RESENT_PHONE_OTP_SUCCESS: "user/RESENT_PHONE_OTP_SUCCESS",

  FRESH_SIGN_UP: "user/FRESH_SIGN_UP",

  SIGN_UP: "user/SIGN_UP",
  SET_SIGN_UP_SUCCESS: "user/SET_SIGN_UP_SUCCESS",
  SET_SIGN_UP_ERROR: "user/SET_SIGN_UP_ERROR",
  SIGN_UP_COMPLETE: "user/SIGN_UP_COMPLETE",

  SIGN_IN: "user/SIGN_IN",
  SIGN_IN_COMPLETE: "user/SIGN_IN_COMPLETE",
  SET_SIGN_IN_ERROR: "user/SET_SIGN_IN_ERROR",

  //password matching
  CURRENT_PASS: "user/CURRENT_PASS",
  SET_CURRENT_PASS_SUCCESS: "user/SET_CURRENT_PASS_SUCCESS",
  SET_CURRENT_PASS_ERROR: "user/SET_CURRENT_PASS_ERROR",

  SUBSCRIPTION: "user/SUBSCRIPTION",
  ORDER_FEEDBACK: "user/ORDER_FEEDBACK",

  SUBSCRIBE: "user/SUBSCRIBE",
  SUBSCRIPTION_SUCCESS: "user/SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_ERROR: "user/SUBSCRIPTION_ERROR",

  SET_PASSWORD_FETCHING: "SET_PASSWORD_FETCHING",
  SET_PASSWORD: "user/SET_PASSWORD",
  RESET_PASSWORD: "user/RESET_PASSWORD",
  UPDATE_PASSWORD_COMPLETE: "user/UPDATE_PASSWORD_COMPLETE",
  RESET_PASSWORD_VIEW: "user/RESET_PASSWORD_VIEW",
  RESET_PASSWORD_FETCHING: "RESET_PASSWORD_FETCHING",
  SIGN_OUT: "user/SIGN_OUT",

  VERIFY_OTP: "user/VERIFY_OTP",
  REQUEST_OTP: "user/REQUEST_OTP",
  SET_OTP_ERROR: "user/SET_OTP_ERROR",
  RESENT_OTP_SUCCESS: "user/RESENT_OTP_SUCCESS",
  SET_REMEMBER_ME: "user/SET_REMEMBER_ME",
  CLEAR_REMEMBER_ME: "user/CLEAR_REMEMBER_ME",
  UPDATE_PHONE_OTP: "UPDATE_PHONE_OTP",
  UPDATE_USER: "user/UPDATE_USER",
  SET_UPDATE_USER_SUCCESS: "user/SET_UPDATE_USER_SUCCESS",
  SET_UPDATE_USER_ERROR: "user/SET_UPDATE_USER_ERROR",

  UPDATE_PHONE_NEW_USER: "user/UPDATE_PHONE_NEW_USER",
  SET_UPDATE_PHONE_NEW_USER_SUCCESS: "user/SET_UPDATE_PHONE_NEW_USER_SUCCESS",
  SET_UPDATE_PHONE_NEW_USER_ERROR: "user/SET_UPDATE_PHONE_NEW_USER_ERROR",
  UPDATE_PHONE_USER: "user/UPDATE_PHONE_USER",
  SET_UPDATE_PHONE_USER_SUCCESS: "user/SET_UPDATE_PHONE_USER_SUCCESS",
  SET_UPDATE_PHONE_USER_ERROR: "user/SET_UPDATE_PHONE_USER_ERROR",

  SET_FORGOT_PASSWORD_ERROR: "user/SET_FORGOT_PASSWORD_ERROR",
  SET_FORGOT_PASSWORD_SUCCESS: "user/SET_FORGOT_PASSWORD_SUCCESS",
  REQUEST_OTP_LOGIN: "user/REQUEST_OTP_LOGIN",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  SET_JWT: "user/SET_JWT",
  CLEAR_ERROR: "user/clear_error",

  CANCEL_ORDER: "user/CANCEL_ORDER",
  SET_CONTACT_US_SUCCESS: " SET_CONTACT_US_SUCCESS",
  SET_CONTACT_US_ERROR: "SET_CONTACT_US_ERROR",

  //#endregion

  //#region SIGN_IN & GET_USER
  signIn: (userObj) => (dispatch) => {
    console.log("userObj: ", userObj);
    dispatch({
      type: actions.SIGN_IN,
    });
    axios({
      method: "post",
      url: backendPath + "login",
      data: {
        username: userObj.username,
        password: userObj.password,
      },
    })
      .then(function (response) {
        console.log(
          "%c Sign-In Success: ",
          "color: #76FF03; font-weight: bold",
          response
        );
        // handle success
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              "%c Sign-In Success: ",
              "color: #76FF03; font-weight: bold",
              response
            );
            if (userObj.checked === true) {
              console.log("remember me sign in", userObj);
              dispatch(actions.setRememberMe(userObj));
            } else {
              dispatch(actions.clearRememberMe());
            }
            dispatch(actions.getUser(response.data.jwt));
            createNotificationSubscription(response.data.vapidPublicKey).then(
              function (subscription) {
                console.log(
                  "%c Subscription",
                  "color: #76FF03; font-weight: bold",
                  subscription
                );
                dispatch(actions.subscribe(subscription, response.data.jwt));
              }
            );
          } else {
            dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log("%c Sign In Error", "color: red; font-weight: bold", error);
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(
            actions.setSignInError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setSignInError("Network Error"));
        } else {
          dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Set Remember me
  setRememberMe: (signInObj) => {
    return {
      type: actions.SET_REMEMBER_ME,
      payload: { signInObj },
    };
  },

  //CLear Remember Me
  clearRememberMe: () => {
    return {
      type: actions.CLEAR_REMEMBER_ME,
    };
  },

  setPhoneOtpError: (errorMessage) => {
    let otpError = "";
    if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      otpError = "Please wait 60 seconds before you request a new OTP.";
    } else if (errorMessage === "Network Error") {
      otpError = "Internet connection failure, please try again later. ";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      otpError = "Phone Number is already in use, write another one. ";
    } else {
      otpError = "Something went wrong, please try again.";
    }
    console.log(otpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_PHONE_OTP_ERROR,
      payload: { otpError },
    };
  },

  resentPhoneOtpSuccess: (otpSuccess) => {
    return {
      type: actions.RESENT_PHONE_OTP_SUCCESS,
    };
  },

  currentPass: (phone, password) => (dispatch) => {
    console.log("userObj: ", phone, password);
    dispatch({
      type: actions.CURRENT_PASS,
    });
    axios({
      method: "post",
      url: backendPath + "login",
      data: {
        username: phone,
        password: password,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              "%c Current Password matches ",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setCurrentPassSuccess("Password matched.", password)
            );
          } else {
            dispatch(
              actions.setCurrentPassError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(
          "%c Current Password Error",
          "color: red; font-weight: bold",
          error
        );
        if (error.response) {
          dispatch(
            actions.setCurrentPassError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setCurrentPassError("Network Error"));
        } else {
          dispatch(
            actions.setCurrentPassError(
              "Something went wrong, please try again."
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  setCurrentPassSuccess: (currentPassSuccess, pass) => {
    return {
      type: actions.SET_CURRENT_PASS_SUCCESS,
      payload: {
        currentPassSuccess: currentPassSuccess,
        currentPassword: pass,
      },
    };
  },

  resetChangePassView: () => {
    return {
      type: actions.RESET_PASSWORD_VIEW,
    };
  },

  getNotifications: (jwt) => (dispatch) => {
    console.log(jwt);
    axios({
      method: "post",
      url: backendPath + "testNotification",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log(
            "%c Get Notification",
            "color: #76FF03; font-weight: bold",
            response
          );
          dispatch({
            type: actions.GET_NOTIFICATION,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "%c Notifications",
            "color: red; font-weight: bold",
            error.response
          );
        } else if (!error.status) {
          console.log(
            "%c Notifications",
            "color: red; font-weight: bold",
            error.response
          );
        }
      });
  },

  setCurrentPassError: (errorMessage) => {
    let currentPassError = "";
    if (errorMessage === "WRONG_CREDENTIAL") {
      currentPassError = "You entered wrong password, please try again";
    } else if (errorMessage === "Network Error") {
      currentPassError =
        "Internet connection failure, please try again later. ";
    } else {
      currentPassError = "Something went wrong, please try again.";
    }
    return {
      type: actions.SET_CURRENT_PASS_ERROR,
      payload: { currentPassError },
    };
  },

  subscribe: (subscription, jwt) => (dispatch) => {
    dispatch({
      type: actions.SUBSCRIBE,
    });
    axios({
      method: "post",
      url: backendPath + "subscribe",
      data: {
        subscription: subscription,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data.message === "success") {
            console.log(
              "%c Subscribe -> response",
              "color: #76FF03; font-weight: bold",
              response
            );
          } else {
            dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        console.log("%c Sign In Error", "color: red; font-weight: bold", error);
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(
            actions.setSignInError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setSignInError("Network Error"));
        } else {
          dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
        }
      });
  },

  setSignInError: (errorMessage) => {
    console.log("error message", errorMessage);
    let signInError = "";
    if (errorMessage === "WRONG_CREDENTIAL") {
      signInError =
        "You entered wrong username/password, please try again OR Sign-Up";
    } else if (errorMessage === "Network Error") {
      signInError = "Internet connection failure, please try again later. ";
    } else {
      signInError = "Something went wrong please try again";
    }
    console.log(signInError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_SIGN_IN_ERROR,
      payload: { signInError },
    };
  },

  getUser: (jwt) => (dispatch) => {
    dispatch({
      type: actions.GET_USER,
    });
    axios({
      method: "get",
      url: backendPath + "me",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            console.log(
              "%c Get User Response",
              "color: #76FF03; font-weight: bold",
              response
            );
            console.log("response check", response.data);
            dispatch(
              actions.setUser(
                response.data,
                "Welcome " + response.data.name,
                jwt
              )
            );
            history.push("/home");
          } else {
            dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        console.log("%c Sign In Error", "color: red; font-weight: bold", error);
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(
            actions.setSignInError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setSignInError("Network Error"));
        } else {
          dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
        }
      });
  },

  setUser: (userObj, successMessage, jwt) => {
    console.log(userObj);
    return {
      type: actions.SET_USER,
      payload: userObj,
      jwt: jwt,
      successMessage: successMessage,
    };
  },

  //#endregion

  //#region SIGN_UP
  signUp: (userObj) => (dispatch) => {
    dispatch({
      type: actions.SIGN_UP,
    });
    axios({
      method: "post",
      url: backendPath + "register ",
      data: {
        name: userObj.name,
        email: userObj.email,
        phone: userObj.phone,
        city: userObj.city,
      },
    })
      .then(function (response) {
        console.log(
          "%c sign up success",
          "color: #76FF03; font-weight: bold",
          response
        );
        // handle success
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message ===
              "success, A verification token has been sent to the given phone number"
          ) {
            console.log(
              "%c sign up success",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(
              actions.setSignUpSuccess(
                userObj,
                "Sign Up Successful. Please verify your account!"
              )
            );
            history.push("/otp");
          } else {
            dispatch(actions.setSignUpError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error?.response?.data?.message
          );
          dispatch(
            actions.setSignInError(
              error.response.data && error.response.data.message
            )
          );
          dispatch(
            actions.setSignUpError(
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something went wrong while Signing Up"
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setSignUpError("Network Error"));
        } else {
          dispatch(actions.setSignUpError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },
  contactUs: (userObj, jwt) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "contactUs ",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        firstName: userObj.firstName,
        lastName: userObj.lastName,
        email: userObj.email,
        phone: userObj.phone,
        message: userObj.message,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Your message have send to admin"
          ) {
            console.log(
              "%c sign up success",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(
              actions.setContactUsSuccess(
                "Your message have been Send to Admin!"
              )
            );
          } else {
            dispatch(
              actions.setContactUsError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error?.response?.data?.message
          );

          dispatch(
            actions.setContactUsError(
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something went wrong while Send message"
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setContactUsError("Network Error"));
        } else {
          dispatch(
            actions.setContactUsError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },
  setJwt: (jwt, otp) => {
    return {
      type: actions.SET_JWT,
      payload: { jwt, otp },
    };
  },

  setSignUpSuccess: (userObj, signUpSuccess) => {
    return {
      type: actions.SET_SIGN_UP_SUCCESS,
      payload: {
        name: userObj.name,
        email: userObj.email,
        phone: userObj.phone,
        city: userObj.city,
        signUpSuccess: signUpSuccess,
      },
    };
  },
  setContactUsSuccess: (contactUsSuccess) => {
    return {
      type: actions.SET_CONTACT_US_SUCCESS,
      payload: {
        contactUsSuccess: contactUsSuccess,
      },
    };
  },
  setContactUsError: (errorMessage) => {
    console.log(errorMessage);
    let contactUsError = "";
    if (errorMessage === "USER_DOES_NOT_EXIST") {
      contactUsError = "USER_DOES_NOT_EXIST";
    } else if (errorMessage === "Something went wrong, please try again.") {
      contactUsError = "Something went wrong, please try again.";
    } else if (errorMessage === "Network Error") {
      contactUsError = "Internet connection failure, please try again later.";
    } else {
      contactUsError = "Something went wrong while Send message";
    }

    console.log("errorMessage: ", contactUsError);
    return {
      type: actions.SET_CONTACT_US_ERROR,
      payload: { contactUsError: contactUsError },
    };
  },

  setSignUpError: (errorMessage) => {
    console.log(errorMessage);
    let signUpError = "";
    if (errorMessage === "USERNAME_IS_NOT_AVAILABLE") {
      signUpError =
        "USERNAME is already in use, please enter another USERNAME.";
    } else if (errorMessage === "EMAIL_IS_NOT_AVAILABLE") {
      signUpError = "EMAIL is already in use, please enter another EMAIL.";
    } else if (errorMessage === "PHONE_IS_NOT_AVAILABLE") {
      signUpError =
        "PHONE NUMBER is already in use, please enter another PHONE NUMBER.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED") {
      signUpError =
        "This USER is not verified, please verify from OTP sent to your phone number.";
    } else if (errorMessage === "USER_REGISTERED_AND_VERIFIED") {
      signUpError = "This USER is already registered, please try to sign in.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      signUpError =
        "This USER is already registered and verified, please enter another Email Or Phone Number.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_UNVERIFIED") {
      signUpError =
        "This USER is already registered and unverified, please enter another Email Or Phone Number.";
    } else if (errorMessage === "NETWORK_ERROR") {
      signUpError = "Internet connection failure, please try again later.";
    } else {
      signUpError = "Something went wrong, please try again.";
    }
    console.log(signUpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_SIGN_UP_ERROR,
      payload: { signUpError },
    };
  },

  //#endregion

  //#region OTP, SET_PASSWORD & FORGOT PASSWORD
  verifyOTP: (otp, phone, isSignIn, isUpdate) => (dispatch) => {
    dispatch({
      type: actions.VERIFY_OTP,
    });
    axios({
      method: "post",
      url: backendPath + "verifyToken ",
      data: {
        username: phone,
        token: otp,
      },
    })
      .then(function (response) {
        // handle
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              "%c verify otp",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(actions.setJwt(response.data.jwt, otp));
            // dispatch(actions.updatePhoneUser(oldPhone, phone, response.data.jwt, componentProps))

            if (isSignIn && isUpdate) {
              history.push("/updatePhone");
            } else if (isSignIn && !isUpdate) {
              history.push("/reset_password");
            } else if (!isSignIn && !isUpdate) {
              history.push("/set_password");
            }
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        }
      })
      .catch(function (error) {
        // handle error

        if (error.response) {
          console.log(
            "%c Update phone error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Update phone error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(
            actions.setOtpError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  requestOTP: (phone, isForgotPassword) => (dispatch) => {
    dispatch({
      type: actions.FORGET_PASSWORD,
    });
    axios({
      method: "post",
      url: backendPath + "requestToken ",
      data: {
        username: phone,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          // handle
          if (
            response.data &&
            response.data.message ===
              "new token sent to registered phone number"
          ) {
            console.log(
              "%c request otp success",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(
              actions.resentOtpSuccess(
                "A new OTP has been sent to your PHONE NUMBER"
              )
            );
            if (isForgotPassword) {
              dispatch(actions.setForgotPasswordSuccess(phone));
              history.push("/otp");
            }
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          console.log(
            "%c error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        }

        if (isForgotPassword) {
          dispatch(
            actions.setForgotPasswodError(
              error.response &&
                error.response.data &&
                error.response.data.message
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  resentOtpSuccess: (otpSuccess) => {
    return {
      type: actions.RESENT_OTP_SUCCESS,
      payload: { otpSuccess },
    };
  },

  setForgotPasswordSuccess: (phone) => {
    return {
      type: actions.SET_FORGOT_PASSWORD_SUCCESS,
      payload: { phone },
    };
  },

  setForgotPasswodError: (errorMessage) => {
    let forgotPasswordError;
    if (errorMessage === "WRONG_CREDENTIAL") {
      forgotPasswordError = "Please Enter Registered Phone Number";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      forgotPasswordError =
        "Please wait 60 seconds before you request a new OTP.";
    } else {
      forgotPasswordError = "Something went wrong, please try again.";
    }
    return {
      type: actions.SET_FORGOT_PASSWORD_ERROR,
      payload: { forgotPasswordError },
    };
  },

  setOtpError: (errorMessage) => {
    let otpError = "";
    if (errorMessage === "LATE_VERIFICATION") {
      otpError = "Your OTP has expired, please request a new OTP to proceed.";
    } else if (errorMessage === "INVALID_TOKEN") {
      otpError = "You have entered wrong OTP, please try again.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      otpError = "Please wait 60 seconds before you request a new OTP.";
    } else if (errorMessage === "Network Error") {
      otpError = "Internet connection failure, please try again later. ";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      otpError = "Phone is already exist Enter New Phone Number. ";
    } else if (
      errorMessage ===
      "YOU_CAN_NOT_UPDATE_NOW_BEFORE_YOU_NEED_TO_CANCEL_ORDER_OR_COMPLETE_ORDER"
    ) {
      otpError =
        "You can not update now before you need to complete or cancel order";
    } else {
      otpError = "Something went wrong, please try again.";
    }
    console.log(otpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_OTP_ERROR,
      payload: { otpError },
    };
  },

  setPassword:
    (oldPassword, newPassword, otp, jwt, isChangePassword) => (dispatch) => {
      dispatch({
        type: actions.SET_PASSWORD_FETCHING,
      });
      axios({
        method: "post",
        url: backendPath + "changePassword",
        headers: { Authorization: `Bearer ${jwt}` },
        data: {
          newPassword: newPassword,
          oldPassword: oldPassword,
          token: otp,
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            // handle
            if (response.data && response.data.message === "password updated") {
              console.log(
                "%c set password success",
                "color: #76FF03; font-weight: bold",
                response.data
              );
              dispatch(actions.signUpComplete());
              history.push("/sign_in");
            } else {
              dispatch(
                actions.setOtpError("Something went wrong, please try again.")
              );
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            // handle error
            console.log(
              "%c Update phone error",
              "color: red; font-weight: bold",
              error.response
            );
            dispatch(
              actions.setOtpError(
                error.response.data && error.response.data.message
              )
            );
          } else if (!error.status) {
            dispatch(actions.setOtpError("Network Error"));
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        })
        .then(function () {
          // always executed
        });
    },

  resetPassword: (newPassword, jwt, oldPassword) => (dispatch) => {
    dispatch({
      type: actions.RESET_PASSWORD_FETCHING,
    });
    axios({
      method: "post",
      url: backendPath + "changePassword",
      headers: { Authorization: `Bearer ${jwt}` },
      data: {
        newPassword: newPassword,
        oldPassword: oldPassword,
        token: "",
      },
    })
      .then(function (response) {
        // handle
        if (response.status === 200) {
          if (response.data && response.data.message === "password updated") {
            console.log(
              "%c reset password success",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(actions.updatePassword());
            dispatch(actions.clearRememberMe());
            dispatch(actions.signOut());
            history.push("/sign_in");
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          // handle error
          console.log(
            "%c Update phone error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(
            actions.setOtpError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  updatePassword: () => {
    return {
      type: actions.UPDATE_PASSWORD_COMPLETE,
    };
  },

  signUpComplete: () => {
    return {
      type: actions.SIGN_UP_COMPLETE,
    };
  },

  //#endregion

  //#region UPDATE_PHONE_NEW_USER
  updatePhoneNewUser: (oldPhone, newPhone) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "updatePhoneNewUser",
      data: {
        newPhone: newPhone,
        oldPhone: oldPhone,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Phone number updated"
          ) {
            console.log(
              "%c Update phone response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setUpdatePhoneNewUserSuccess(
                "Phone Number Updated Successfully!",
                newPhone
              )
            );
          } else {
            dispatch(
              actions.setUpdatePhoneNewUserError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "%c Update phone error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(
            actions.setUpdatePhoneNewUserError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdatePhoneNewUserError("Network Error"));
        } else {
          dispatch(
            actions.setUpdatePhoneNewUserError(
              "Something went wrong, please try again."
            )
          );
        }
      });
  },

  setUpdatePhoneNewUserSuccess: (updatePhoneNewUserSuccess, phone) => {
    return {
      type: actions.SET_UPDATE_PHONE_NEW_USER_SUCCESS,
      payload: { updatePhoneNewUserSuccess, phone },
    };
  },

  setUpdatePhoneNewUserError: (errorMessage) => {
    let updatePhoneNewUserError;
    if (
      errorMessage === "PHONE_IS_NOT_AVAILABLE" ||
      errorMessage === "WRONG_CREDENTIAL"
    ) {
      updatePhoneNewUserError =
        "Phone Number is already in use, write another one.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      updatePhoneNewUserError =
        "User is already registered and verified, try signing in.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_PHONE_UPDATE") {
      updatePhoneNewUserError =
        "Please wait at least 60 seconds before updating phone number";
    } else if (errorMessage === "Network Error") {
      updatePhoneNewUserError =
        "Internet connection failure, please try again later. ";
    } else {
      updatePhoneNewUserError = "Something went wrong, please try again.";
    }

    return {
      type: actions.SET_UPDATE_PHONE_NEW_USER_ERROR,
      payload: { updatePhoneNewUserError },
    };
  },

  setUpdatePhoneUserSuccess: (updatePhoneUserSuccess) => {
    return {
      type: actions.SET_UPDATE_PHONE_USER_SUCCESS,
      payload: { updatePhoneUserSuccess },
    };
  },

  setUpdatePhoneUserError: (errorMessage) => {
    let updatePhoneUserError;
    if (
      errorMessage === "PHONE_IS_NOT_AVAILABLE" ||
      errorMessage === "WRONG_CREDENTIAL"
    ) {
      updatePhoneUserError =
        "Phone Number is already in use, write another one.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      updatePhoneUserError =
        "User is already registered and verified, try signing in.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_PHONE_UPDATE") {
      updatePhoneUserError =
        "Please wait at least 60 seconds before updating phone number";
    } else if (errorMessage === "Network Error") {
      updatePhoneUserError =
        "Internet connection failure, please try again later. ";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      updatePhoneUserError =
        "This Phone number is already registered, Enter new phone Number.";
    } else if (
      errorMessage ===
      "YOU_CAN_NOT_UPDATE_NOW_BEFORE_YOU_NEED_TO_CANCEL_ORDER_OR_COMPLETE_ORDER"
    ) {
      updatePhoneUserError =
        "You cannot update now before you need to complete order";
    } else {
      updatePhoneUserError = "Something went wrong please try again";
    }
    return {
      type: actions.SET_UPDATE_PHONE_USER_ERROR,
      payload: { updatePhoneUserError },
    };
  },

  updateUser: (data, jwt) => (dispatch) => {
    console.log("userData", data);
    axios({
      method: "post",
      url: backendPath + "updateUser",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      data: data,
    })
      .then(function (response) {
        if (response.status === 200) {
          
          if (
            response.data &&
            response.data.message === "success, Updated Information"
          ) {
            console.log(
              "%c Update User response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setUpdateUserSuccess(
                response.data && response.data.message
                  ? response.data.message
                  : "User Successfully Updated!",
                data.name,
                data.email,
                data.city,
                data.state,
                data.address
              )
            );
          } else {
            dispatch(
              actions.setUpdateUserError(
                "Something went wrong, please try again."
              )
            );
          }
        }
        dispatch(actions.getUser(jwt));
      })
      .catch(function (error) {
        console.log(
          "%c Update User error",
          "color: red; font-weight: bold",
          error.response
        );
        const errorMessage =
          'MongoServerError: E11000 duplicate key error collection: ManWithVan.users index: email_1 dup key: { email: "hamzarahman7@gmail.com" }';
        const pattern = /(E11000 duplicate key error collection)/;

        const match = errorMessage.match(pattern);
        if (match) {
          dispatch(actions.setUpdateUserError("Email is not available!"));
        } else if (error.response) {
          dispatch(
            actions.setUpdateUserError(
              error.response.data && error.response.data.message
                ? error.response.data.message
                : "Error While Updating User!"
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Update User error",
            "color: red; font-weight: bold",
            error.response
          );
          dispatch(actions.setUpdateUserError("Network Error"));
        } else {
          dispatch(
            actions.setUpdateUserError(
              "Something went wrong, please try again."
            )
          );
        }
      });
       dispatch(actions.getUser(jwt));
  },

  setUpdateUserSuccess: (
    updateUserSuccess,
    name,
    email,
    city,
    state,
    address
  ) => {
    return {
      type: actions.SET_UPDATE_USER_SUCCESS,
      payload: { updateUserSuccess, name, email, city, state, address },
    };
  },

  setUpdateUserError: (errorMessage) => {
    let updateUserError;
    if (errorMessage === "Network Error") {
      updateUserError = "Internet connection failure, please try again later. ";
    } else if (
      errorMessage ===
      "YOU_CAN_NOT_UPDATE_NOW_BEFORE_YOU_NEED_TO_CANCEL_ORDER_OR_COMPLETE_ORDER"
    ) {
      updateUserError =
        "You cannot update now before you need to complete order";
    } else {
      updateUserError = errorMessage;
    }

    return {
      type: actions.SET_UPDATE_USER_ERROR,
      payload: { updateUserError },
    };
  },

  //#endregion

  //#region otp request and update phone
  requestOTPLoginUser: (phone, jwt) => (dispatch) => {
    dispatch({
      type: actions.UPDATE_PHONE_OTP,
    });
    axios({
      method: "POST",
      url: backendPath + "requestTokenLoginUser",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        newPhone: phone,
      },
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message ===
              "new token sent to registered phone number"
          ) {
            console.log(
              " %c request otp response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch({
              type: actions.REQUEST_OTP_LOGIN,
              payload: {
                phone: phone,
              },
            });
            history.push("/phone_otp");
          } else {
            dispatch(
              actions.setUpdatePhoneUserError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          console.log(
            "%c error",
            "color: red; font-weight: bold",
            error.response
          );
          // dispatch(actions.setPhoneOtpError(error?.response?.data?.message))
          dispatch(
            actions.setUpdatePhoneUserError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdatePhoneUserError("Network Error"));
          // dispatch(actions.setPhoneOtpError('Network Error'))
        } else {
          dispatch(
            actions.setUpdatePhoneUserError(
              "Something went wrong, please try again."
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  updatePhoneLoginUser: (otp, phone, jwt) => (dispatch) => {
    console.log("newPhoneeeeee", phone);
    axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      url: backendPath + "updatePhoneLoginUser",
      data: {
        newPhone: phone,
        token: otp,
      },
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (response.data && response.data.message === "Phone Updated.") {
            console.log(
              " %c response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(actions.setUpdatePhoneUserSuccess(response.data.message));
            console.log(phone);
            console.log(
              " %c response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(actions.clearRememberMe());
            dispatch(actions.signOut());
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(
            actions.setOtpError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },
  //#endregion

  //get Profile
  getProfile: (access_token) => {
    axios({
      method: "get",
      url: backendPath + "me",
      headers: {
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          // handle success
          console.log(
            "%c Get profile success",
            "color: #76FF03; font-weight: bold",
            response
          );
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            "%c Get Profile error",
            "color: red; font-weight: bold",
            error.response
          );
        } else if (!error.status) {
          console.log(
            "%c Update User error",
            "color: red; font-weight: bold",
            error.response
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  //fresh signup
  freshSignUp: () => {
    return {
      type: actions.FRESH_SIGN_UP,
    };
  },

  //signout
  signOut: () => {
    history.push("/sign_in");
    return {
      type: actions.SIGN_OUT,
    };
  },

  //Clear Error
  clearError: () => {
    return {
      type: actions.CLEAR_ERROR,
    };
  },
};

export default actions;
