//Libraries
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  useTheme,
  CssBaseline,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  MenuItem,
  useMediaQuery,
  Hidden,
  CircularProgress,
  Snackbar,
  Dialog,
  Divider,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Row } from "@mui-treasury/components/flex";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

//Components
import actions from "../Redux/Order/Order.actions";
import NavigationDrawer from "../Components/NavigationDrawer/NavigationDrawer";
import mapStyles from "../Utils/mapStyles";
import PickupDetails from "../Components/PlaceOrderSteps/PickupDetails";
import DropOffDetails from "../Components/PlaceOrderSteps/DropOffDetails";
import Yup from "../Yup/YupOValidation.schemas";
import Location from "../Components/DeliveryMap/Location.Component";
import { KeyboardAlt } from "@mui/icons-material";
import Loader from "../Components/Loader/Loader";
const number = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

//style
const useStyles = makeStyles((theme) => ({
  date: {
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      opacity: 0,
      position: "absolute",
      width: "100%",
      left: "5%",
    },
  },
  timeView: {
    "& input[type='time']::-webkit-calendar-picker-indicator": {
      opacity: 0,
      position: "absolute",
      width: "80%",
      left: "5%",
    },
  },
  root: {
    display: "flex",
  },
  toolbar: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "2%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "1.3%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "1.7%",
    },
  },
  dialog: {
    position: "absolute",
    borderRadius: 8,
  },
  price: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#6251FE",
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.only("md")]: {
      margin: theme.spacing(5),
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingRight: theme.spacing(3),
    },
  },
  subtitle: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.3rem",
    },
  },
  subtitle1: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.3rem",
    },
  },
  progress: {
    width: "50px",
    height: "50px",
  },
  divPro: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
  },
  submitBook: {
    margin: theme.spacing(1, 0, 0),
    "&:disabled": {
      backgroundColor: "#ECECEC",
      color: "black",
    },
  },
  submitAddress: {
    margin: theme.spacing(0, 0, 2),
    justifyContent: "space-between",
    textTransform: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: 18,
    },
  },
  title: {
    fontSize: 15,
    marginTop: "2%",
  },
  titleAcc: {
    fontSize: 15,
  },
  img: {
    [theme.breakpoints.up("lg")]: {
      marginTop: "0.3%",
    },
  },
  image: {
    textAlign: "center",
  },
  divider: {
    marginBottom: "3%",
    width: "100%",
  },
  pick: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "18px",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "6%",
    },
  },
  schText: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "1%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "1.5%",
    },
    [theme.breakpoints.between("md", "xl")]: {
      marginTop: "2%",
    },
  },
  atText: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "8%",
      padding: "0% 1% 0%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "4.5%",
      padding: "0% 2% 0%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "3.5%",
      padding: "0% 5% 0%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "8%",
      padding: "0% 5% 0%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "5%",
      padding: "0% 5% 0%",
    },
  },
  vanType: {
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "1%",
      paddingBottom: "1%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "1.5%",
      paddingBottom: "1.5%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "1%",
      paddingBottom: "2%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "3%",
      paddingBottom: "1%",
    },
  },
  option: {
    width: "100%",
    justifyContent: "space-between",
  },
  closeBtn: {
    backgroundColor: "#F5F6FE",
    marginRight: "2%",
    boxShadow: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

export default function Home(props) {
  const classes = useStyles();
  const currentDate = new Date();
  const oneYearLater = new Date(
    currentDate.setFullYear(currentDate.getFullYear() + 1)
  )
    .toISOString()
    .slice(0, 10);

  const theme = useTheme();
  const [color, setColor] = useState("null");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date._d);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.clearError());
  }, []);

  const [pickupData, setPickupData] = useState();
  const [dropOffData, setDropOffData] = useState();
  const [latValue1, setLatValue1] = useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [latValue2, setLatValue2] = useState(0);
  const [lngValue1, setLngValue1] = useState(0);
  const [lngValue2, setLngValue2] = useState(0);
  const isLoading = useSelector((state) => state.order?.loading);
  const clearNow = useSelector((state) => state.order?.clearNow);
  const jwt = useSelector((state) => state.user.jwt);
  const orderId = useSelector((state) => state.order.orderId);
  const bookNow = useSelector((state) => state.order.bookNow);

  const orderPersist = useSelector((state) => state.order?.order);
  const handleClickOpened = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const vehicleTypeOptions = [
    {
      id: 1,
      img: "/assets/images/mwb.png",
      name: "Mwb",
      value: "small",
      Desc: "Medium Wheelbase Transit",
    },
    {
      id: 2,
      img: "/assets/images/Lwb.png",
      name: "Lwb",
      value: "medium",
      Desc: "Long Wheelbase Transit",
    },
    {
      id: 3,
      img: "/assets/images/Tlbv.png",
      name: "Tlbv",
      value: "large",
      Desc: "Ton luton body van with tail lift",
    },
    {
      id: 4,
      img: "/assets/images/Tlbw.png",
      name: "Tlbw",
      value: "xl",
      Desc: "Ton luton box van with tail lift",
    },
  ];

  const placeOrderError = useSelector(
    (state) => state.order?.errors?.placeOrderError
  );

  const placeOrderSuccess = useSelector(
    (state) => state.order?.success?.placeOrderSuccess
  );

  const priceOrderError = useSelector(
    (state) => state.order?.errors?.priceCalculationError
  );

  const priceOrderSuccess = useSelector(
    (state) => state.order?.success?.priceCalculationSuccess
  );

  const baseFare = useSelector((state) => state.order?.price?.baseFare);
  const bookingFee = useSelector((state) => state.order?.price?.bookingFee);
  const driverFee = useSelector((state) => state.order?.price?.driverFee);
  const finalCongestionCharges = useSelector(
    (state) => state.order?.price?.finalCongestionCharges
  );
  const finalCostFlightOfStair = useSelector(
    (state) => state.order?.price?.finalCostFlightOfStair
  );
  const finalCostOfHelpers = useSelector(
    (state) => state.order?.price?.finalCostOfHelpers
  );
  const finalCostPerMileCharges = useSelector(
    (state) => state.order?.price?.finalCostPerMileCharges
  );
  const finalCostPerMintCharges = useSelector(
    (state) => state.order?.price?.finalCostPerMintCharges
  );
  const finalInsurranceCharges = useSelector(
    (state) => state.order?.price?.finalInsurranceCharges
  );
  const finalTollCharges = useSelector(
    (state) => state.order?.price?.finalTollCharges
  );
  const finalTypeFactoreCharges = useSelector(
    (state) => state.order?.price?.finalTypeFactoreCharges
  );
  const maximumPrice = useSelector((state) => state.order?.price?.maximumPrice);
  const minimumPrice = useSelector((state) => state.order?.price?.minimumPrice);
  const promoCodeCalculation = useSelector(
    (state) => state.order?.price?.promoCodeCalculation
  );
  const rideDistance = useSelector((state) => state.order?.price?.rideDistance);
  const timeInRide = useSelector((state) => state.order?.price?.timeInRide);

  const options = [
    {
      id: 1,
      title: "Base Fare: ",
      amount: baseFare,
    },
    {
      id: 2,
      title: "Booking Fee: ",
      amount: bookingFee,
    },
    {
      id: 3,
      title: "Congestion Charges: ",
      amount: finalCongestionCharges,
    },
    {
      id: 4,
      title: "Cost flight of Stair: ",
      amount: finalCostFlightOfStair,
    },
    {
      id: 5,
      title: "Cost of Helpers: ",
      amount: finalCostOfHelpers,
    },
    {
      id: 6,
      title: "Cost per Mile Charges: ",
      amount: finalCostPerMileCharges,
    },
    {
      id: 7,
      title: "Cost per Mint Charges: ",
      amount: finalCostPerMintCharges,
    },
    {
      id: 8,
      title: "Insurrance Charges: ",
      amount: finalInsurranceCharges,
    },
    {
      id: 9,
      title: "Toll Charges: ",
      amount: finalTollCharges,
    },
    {
      id: 10,
      title: "Factore Charges: ",
      amount: finalTypeFactoreCharges,
    },
    {
      id: 11,
      title: "Maximum Price: ",
      amount: maximumPrice,
    },
    {
      id: 12,
      title: "Minimum Price: ",
      amount: minimumPrice,
    },

    {
      id: 13,
      title: "Ride Distance: ",
      amount: rideDistance,
    },
    {
      id: 14,
      title: "Time In Ride: ",
      amount: timeInRide,
    },
  ];

  const themeType = useSelector((state) => state.themes.theme);

  const [initialValues, setInitialValues] = useState(orderPersist);

  // useEffect(() => {
  //   setInitialValues(orderPersist)
  // }, [
  //   orderPersist.isRideInsured,
  //   orderPersist.isFreightInsured,
  //   orderPersist.description,
  //   orderPersist.rideType,
  //   orderPersist.time,
  //   orderPersist.date,
  //   orderPersist.noOfHelpersRequired
  // ])

  //automatic price calculation checks of not having empty fields
  let checkEmptyOrNull = (obj) => {
    let emptyCheck = false;
    for (const [key, value] of Object.entries(obj)) {
      if (key === "tripStartLocation") {
        for (const [startKey, startValue] of Object.entries(
          obj.tripStartLocation
        )) {
          if (startValue === null || startValue === "") {
            emptyCheck = true;
          }
        }
      }
      if (key === "tripEndLocation") {
        for (const [endKey, endValue] of Object.entries(obj.tripEndLocation)) {
          if (endValue === null || endValue === "") {
            emptyCheck = true;
          }
        }
      }
      if (value == null || value === "") {
        emptyCheck = true;
      } else {
      }
    }

    return emptyCheck;
  };

  useEffect(() => {
    let date = initialValues ? initialValues.date : "";
    let time = initialValues ? initialValues.time : "";

    //Parsed date and time
    let parsedDate = moment(date + " " + time);
    let finalDate = parsedDate.toISOString();
    let dummy = {
      pickUpDateAndTime: finalDate,
    };

    let payload = { ...initialValues, ...dummy };
    let isEmptyOrNullRespnse = checkEmptyOrNull(payload);

    //order data persist
    dispatch(actions.placeOrderData(payload));

    if (!isEmptyOrNullRespnse) {
      if (orderId == null) {
        //Price calculate first time
        dispatch(actions.priceOrderCalculation(jwt, payload));
      } else {
        dispatch(actions.updatePriceOrderCalculation(jwt, orderId, payload));
      }
    }
  }, [initialValues, initialValues?.tripStartLocation]);

  //when price updated
  useEffect(() => {}, [
    baseFare,
    bookingFee,
    driverFee,
    finalCongestionCharges,
    finalCostFlightOfStair,
    finalCostOfHelpers,
    finalCostPerMileCharges,
    finalCostPerMintCharges,
    finalInsurranceCharges,
    finalTollCharges,
    finalTypeFactoreCharges,
    finalTypeFactoreCharges,
    maximumPrice,
    minimumPrice,
    promoCodeCalculation,
    rideDistance,
    timeInRide,
    orderPersist,
    bookNow,
    orderId,
  ]);
  useEffect(() => {
    setLatValue1(parseFloat(orderPersist?.tripStartLocation?.lat));
    setLngValue1(parseFloat(orderPersist?.tripStartLocation?.long));
    setLatValue2(parseFloat(orderPersist?.tripEndLocation?.lat));
    setLngValue2(parseFloat(orderPersist?.tripEndLocation?.long));
  }, [orderPersist]);
  const orderDataHandler = (type, data) => {
    if (type === "pickup") {
      setInitialValues({
        ...initialValues,
        tripStartLocation: data,
      });
      setLatValue1(data && data.lat ? data.lat : 0);
      setLngValue1(data && data.long ? data.long : 0);
    } else if (type === "dropOff") {
      setInitialValues({
        ...initialValues,
        tripEndLocation: data,
      });
      setLatValue2(data && data.lat ? data.lat : 0);
      setLngValue2(data && data.long ? data.long : 0);
    }
  };

  //Place Order Handler
  const placeOrderHandler = () => {
    dispatch(actions.placeOrder(orderId, jwt, props));
  };

  const lgMatch = useMediaQuery(theme.breakpoints.only("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.only("xl"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openD2, setOpenD2] = React.useState(false);

  const handleClickOpenD2 = () => {
    setOpenD2(true);
  };

  const handleCloseD2 = () => {
    setOpenD2(false);
  };

  const center = {
    lat: 43.653225,
    lng: -79.383186,
  };

  const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  //#region Snacklbarrs
  useEffect(() => {
    if (placeOrderError && !placeOrderSuccess) {
      handleErrorSnack();
    }

    if (placeOrderSuccess && !placeOrderError) {
      handleErrorSnack();
    }
  }, [placeOrderError, placeOrderSuccess]);

  useEffect(() => {
    if (priceOrderError && !priceOrderSuccess) {
      handleErrorSnack();
    }

    if (priceOrderSuccess && !priceOrderError) {
      handleErrorSnack();
    }
  }, [priceOrderError, priceOrderSuccess]);

  const handleErrorSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(actions.clearError());
  };
  const places = [
    { latitude: parseFloat(latValue1), longitude: parseFloat(lngValue1) },
    { latitude: parseFloat(latValue2), longitude: parseFloat(lngValue2) },
  ];

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className={classes.divPro}>
          {" "}
          <CircularProgress className={classes.progress} />{" "}
        </div>
      ) : null}
      <NavigationDrawer />
      <CssBaseline />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          mb={2}
          spacing={xlMatch ? 4 : 2}
          style={isLoading ? { pointerEvents: "none", opacity: "0.4" } : null}
        >
          <Hidden lgUp>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Location defaultZoom={7} places={places} />
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              sm={false}
              xs={false}
              md={false}
              lg={1}
              xl={1}
              className={classes.image}
            >
              <Box className={classes.img}>
                <img src="/assets/images/pick.png" />
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.OrderDetailsValidationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                dispatch(actions.placeOrder(orderId, jwt, props));
              }}
            >
              {({ errors, setFieldValue, touched }) => (
                <Form className={classes.form}>
                  <Grid container>
                    <Hidden lgUp>
                      <Grid item xs={1} sm={1} md={1} lg={false} xl={false}>
                        <Box className={classes.pick}>
                          <Hidden mdUp>
                            <img src="/assets/images/pick2.png" />
                          </Hidden>
                          <Hidden smDown>
                            <img src="/assets/images/pick.png" />
                          </Hidden>
                        </Box>
                      </Grid>
                    </Hidden>
                    <Grid item xs={11} sm={11} md={11} lg={12} xl={12}>
                      <PickupDetails
                        setFieldValue={setFieldValue}
                        orderDataHandler={orderDataHandler}
                      />
                      <Box m="10px" />
                      {/*Destination Address Dialog*/}
                      <DropOffDetails
                        setFieldValue={setFieldValue}
                        orderDataHandler={orderDataHandler}
                      />
                    </Grid>
                  </Grid>
                  <Box className={classes.schText}>
                    <Typography className={classes.subtitle}>
                      <b>Schedule Your Orders</b>
                    </Typography>
                  </Box>
                  <Row>
                    <TextField
                      error={errors.date && touched.date}
                      helperText={
                        errors.date && touched.date ? errors.date : null
                      }
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="date"
                      className={classes.date}
                      InputLabelProps={{ shrink: true }}
                      //valid date not previous
                      inputProps={{
                        min: new Date().toISOString().slice(0, 10),
                        max: oneYearLater,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                      id="date"
                      placeholder="dd/mm/yyyy"
                      defaultValue={
                        orderPersist?.date !== "" ? orderPersist?.date : ""
                      }
                      type="date"
                      onChange={(e) =>
                        setInitialValues({
                          ...initialValues,
                          date: e.target.value,
                        })
                      }
                    />
                    <Box className={classes.atText}>
                      <Typography>
                        <b>at</b>
                      </Typography>
                    </Box>
                    <TextField
                      error={errors.time && touched.time}
                      helperText={
                        errors.time && touched.time ? errors.time : null
                      }
                      variant="outlined"
                      margin="normal"
                      name="time"
                      fullWidth
                      defaultValue={
                        orderPersist?.time !== "" ? orderPersist?.time : ""
                      }
                      placeholder="mm/hh/ss"
                      type="time"
                      id="time"
                      className={classes.timeView}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setInitialValues({
                          ...initialValues,
                          time: e.target.value,
                        })
                      }
                    />
                  </Row>
                  <TextField
                    error={
                      errors.noOfHelpersRequired && touched.noOfHelpersRequired
                    }
                    helperText={
                      errors.noOfHelpersRequired && touched.noOfHelpersRequired
                        ? errors.noOfHelpersRequired
                        : null
                    }
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Number of helpers"
                    name="noOfHelpersRequired"
                    id="noOfHelpersRequired"
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        noOfHelpersRequired: e.target.value,
                      })
                    }
                    defaultValue={
                      orderPersist?.noOfHelpersRequired !== ""
                        ? orderPersist?.noOfHelpersRequired
                        : ""
                    }
                    select
                    className={classes.title}
                  >
                    {number.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={errors.isFreightInsured && touched.isFreightInsured}
                    helperText={
                      errors.isFreightInsured && touched.isFreightInsured
                        ? errors.isFreightInsured
                        : null
                    }
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="isFreightInsured"
                    id="isFreightInsured"
                    label="Freight Insurance"
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        isFreightInsured: e.target.value,
                      })
                    }
                    defaultValue={
                      orderPersist?.isFreightInsured !== ""
                        ? orderPersist?.isFreightInsured
                        : ""
                    }
                    select
                    className={classes.title}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="description"
                    id="desription"
                    label="Any specification (optional)"
                    multiline
                    minRows={3}
                    defaultValue={
                      orderPersist?.description !== ""
                        ? orderPersist?.description
                        : ""
                    }
                    className={classes.title}
                    onBlur={(e) =>
                      setInitialValues({
                        ...initialValues,
                        description: e.target.value,
                      })
                    }
                  />
                  <Box className={classes.vanType}>
                    <Typography className={classes.subtitle}>
                      <b>Choose Van Type</b>
                    </Typography>
                  </Box>
                  <Box mt={"1%"} />
                  <TextField
                    error={errors.rideType && touched.rideType}
                    helperText={
                      errors.rideType && touched.rideType
                        ? errors.rideType
                        : null
                    }
                    id="rideType"
                    name="rideType"
                    fullWidth
                    placeholder="vehicle Type"
                    variant="outlined"
                    defaultValue={
                      orderPersist?.rideType !== ""
                        ? orderPersist?.rideType
                        : ""
                    }
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        rideType: e.target.value,
                      })
                    }
                    select
                  >
                    {vehicleTypeOptions.map((option) => {
                      return (
                        <MenuItem
                          value={option.value || ""}
                          style={
                            option.id === 4
                              ? { background: "none" }
                              : {
                                  background: "none",
                                  borderBottom: "1px solid grey",
                                }
                          }
                          key={option.id}
                        >
                          <Box display="flex" className={classes.option}>
                            <Typography variant="subtitle1">
                              {option.Desc}
                            </Typography>
                            <img src={option.img} alt="imshs" width="50" />
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <Box className={classes.vanType}>
                    <Typography className={classes.subtitle1}>
                      <b>Fare Estimation</b>
                    </Typography>
                  </Box>
                  <Box mt="5px"></Box>
                  <Button
                    onClick={handleClickOpened}
                    select
                    variant="outlined"
                    fullWidth
                    className={classes.submitAddress}
                  >
                    Fare Estimation: £{minimumPrice}
                    <ExpandMoreIcon />
                  </Button>
                  <Dialog
                    maxWidth={lgMatch ? "sm" : "xs"}
                    fullWidth
                    open={openDialog}
                    classes={{
                      paper: classes.dialog,
                    }}
                    hideBackdrop={true}
                    onClose={handleCloseDialog}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle
                      id="responsive-dialog-title"
                      className={classes.price}
                    >
                      <span>
                        <b>{"Estimated Price Breakdown"}</b>
                      </span>
                      <br />
                      Total Price: £{minimumPrice}
                      &nbsp;
                    </DialogTitle>
                    <DialogContent>
                      {options.map((option) => (
                        <Grid container key={option.id}>
                          <Grid item xs={8} sm={6}>
                            <Typography
                              className={classes.titleAcc}
                              color="textPrimary"
                            >
                              {option.title}
                            </Typography>
                          </Grid>
                          <Grid container>
                            <Grid item xs={4} sm={6}>
                              <Typography color="textPrimary">
                                <b> £{option.amount}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                          {option.id === 15 ? null : (
                            <Divider className={classes.divider} />
                          )}
                        </Grid>
                      ))}
                      <Grid container spacing={2}>
                        <Grid item xs={6} lg={6}>
                          <Button
                            variant="contained"
                            large
                            fullWidth
                            onClick={handleCloseDialog}
                            className={classes.closeBtn}
                          >
                            Close
                          </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Button
                            variant="contained"
                            large
                            fullWidth
                            onClick={placeOrderHandler}
                            color="primary"
                            disabled={bookNow === false ? false : true}
                            autoFocus
                          >
                            {isLoading ? <Loader /> : "Book Now"}
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                  <Row>
                    <Typography className={classes.title}>
                      Estimated Time:
                    </Typography>
                    <Typography className={classes.title} color="secondary">
                      <b> 2 hrs ago</b>
                    </Typography>
                  </Row>
                  <Row>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      size="large"
                      variant="contained"
                      className={classes.submitBook}
                      disabled={bookNow === false ? false : true}
                    >
                      {isLoading ? <Loader /> : "Book Now"}
                    </Button>
                  </Row>
                  {/* {clearNow ? (
                    <Row>
                      <Button
                        fullWidth
                        type='button'
                        color='error'
                        size='large'
                        variant='contained'
                        className={classes.submitBook}
                        onClick={() => dispatch(actions.clearForms())}
                      >
                        Clear Form
                      </Button>
                    </Row>
                  ) : null} */}
                </Form>
              )}
            </Formik>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Location defaultZoom={7} places={places} />
            </Grid>
          </Hidden>
        </Grid>

        {placeOrderError ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="error">
              {placeOrderError}
            </Alert>
          </Snackbar>
        ) : null}

        {priceOrderError ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="error">
              {priceOrderError}
            </Alert>
          </Snackbar>
        ) : null}
        {priceOrderSuccess ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="success">
              {priceOrderSuccess}
            </Alert>
          </Snackbar>
        ) : null}
      </main>
    </div>
  );
}
