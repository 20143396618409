import React, { useEffect } from 'react'

const GetAddressName = props => {
  // to set lat lng in the info window of marker
  useEffect(() => {
    fetch(
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
        props.lat +
        ',' +
        props.lng +
        '&key=AIzaSyCmjiTW64B01tMxtOGACg7vnW4QJAFmY0A'
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.results[0]) {
          for (
            var i = 0;
            i < responseJson.results[0].address_components.length;
            i++
          ) {
            var types = responseJson.results[0].address_components[i].types

            for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
              if (
                types[typeIdx] == 'route' ||
                types[typeIdx] == 'street_address'
                // ||
                // types[typeIdx] == 'plus_code'
              ) {
                //console.log(results[0].address_components[i].long_name);

                props.setAddress(
                  responseJson.results[0].address_components[i].short_name
                )
              }
            }
          }
        } else {
          console.log('No results found')
        }
        if (responseJson.results[0]) {
          for (
            var i = 0;
            i < responseJson.results[0].address_components.length;
            i++
          ) {
            var types = responseJson.results[0].address_components[i].types

            for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
              if (
                types[typeIdx] == 'postal_code'
                // ||
                // types[typeIdx] == 'plus_code'
              ) {
                //console.log(results[0].address_components[i].long_name);

                props.setZipCode(
                  responseJson.results[0].address_components[i].short_name
                )
              }
            }
          }
        } else {
          console.log('No results found')
        }

        if (responseJson.results[0]) {
          for (
            var i = 0;
            i < responseJson.results[0].address_components.length;
            i++
          ) {
            var types = responseJson.results[0].address_components[i].types

            for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
              if (
                types[typeIdx] == 'postal_town'
                // ||
                // types[typeIdx] == 'administrative_area_level_2'
              ) {
                //console.log(results[0].address_components[i].long_name);

                props.setCity(
                  responseJson.results[0].address_components[i].short_name
                )
              }
            }
          }
        } else {
          console.log('No results found')
        }

        if (responseJson.results[0]) {
          for (
            var i = 0;
            i < responseJson.results[0].address_components.length;
            i++
          ) {
            var types = responseJson.results[0].address_components[i].types

            for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
              if (types[typeIdx] == 'country') {
                //console.log(results[0].address_components[i].long_name);
              }
            }
          }
        } else {
          console.log('No results found')
        }
      })
  }, [props.lat, props.lng])

  return <></>
}

export default GetAddressName
