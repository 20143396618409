//Libraries
import React, { useState, useEffect } from 'react'
import { Button, CssBaseline } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

//Components
import actions from '../Redux/User/User.actions'
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'

export default function CheckNotification () {
  const dispatch = useDispatch()
  const jwt = useSelector(state => state.user.jwt)

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <Button
        fullWidth
        color='primary'
        size='large'
        variant='contained'
        style={{ width: '30%', marginTop: '20%', marginLeft: '20%' }}
        onClick={() => dispatch(actions.getNotifications(jwt))}
      >
        Check notifications
      </Button>
    </div>
  )
}
