import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import {
  Box,
  Divider,
  Grid,
  useMediaQuery,
  CircularProgress,
  Hidden,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { deepPurple } from '@material-ui/core/colors'
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { compose, spacing, palette, breakpoints } from '@material-ui/system'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import backendPath from '../../Utils/constants'
import actions from '../../Redux/Order/Order.actions'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'

const Boxs = styled.div`
  ${breakpoints(compose(spacing, palette))}
`
const useStyles = makeStyles(theme => ({
  root1: {
    width: '100%'
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      paddingRight: '5%',
      paddingLeft: '5%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      paddingRight: '5%',
      paddingLeft: '5%'
    },
    [theme.breakpoints.only('md')]: {
      width: '100%',
      paddingLeft: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '100%',
      paddingLeft: '2%',
      paddingRight: '2%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '100%',
      paddingLeft: '2%',
      paddingRight: '2%'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  cardroot: {
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.only('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '92%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '87%'
    }
  },
  cardroot1: {
    [theme.breakpoints.only('xs')]: {
      width: '96%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '96%'
    },
    [theme.breakpoints.only('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '100%'
    }
  },
  root: {
    display: 'flex'
    // backgroundColor: "#FFFFFF"
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '3%'
    }
  },

  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  number: {
    paddingTop: '2%',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      opacity: 1
    }
  },
  card: {
    width: '43%',
    variant: 'outlined',
    border: '1px solid #E0E0E0'
  },
  submit: {
    width: '15vw',
    [theme.breakpoints.up('lg')]: {
      width: '15vw',
      margin: theme.spacing(1, 0, 2)
    }
  },
  submitAddress: {
    margin: theme.spacing(0, 0, 2),
    fontSize: 18
  },
  title: {
    fontSize: 15,
    marginTop: '2%'
  },
  container: {
    position: 'relative'
  },
  centered: {
    position: 'absolute',
    padding: '0.5%',
    top: '54%',
    left: '28%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)'
  },
  cardCentered: {
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      top: '40%',
      width: '95%',
      left: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      top: '55%',
      width: '50%',
      left: '25%'
    },
    [theme.breakpoints.only('md')]: {
      width: '60%',
      top: '50%',
      left: '15%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%',
      top: '50%',
      left: '15%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '80%',
      top: '60%',
      left: '25%'
    }
  },
  centeredTrip: {
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.only('md')]: {
      width: '60%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '60%'
    }
  },
  title: {
    fontSize: 15,
    marginTop: '2%'
  },
  dialog: {
    position: 'absolute'
  },

  submit: {
    margin: theme.spacing(1, 0, 2)
  },
  logoMas: {
    marginTop: '10px'
  },
  logoVisa: {
    marginTop: '15px'
  },
  image: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
      height: '40%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('md')]: {
      width: '80%',
      height: '80%'
    }
  },
  large: {
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(7),
      fontSize: 24,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(7),
      fontSize: 26,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(9),
      fontSize: 28,
      height: theme.spacing(9)
    },
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(12),
      fontSize: 30,
      height: theme.spacing(12)
    },
    [theme.breakpoints.only('xl')]: {
      width: theme.spacing(14),
      fontSize: 36,
      height: theme.spacing(14)
    }
  },
  price: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#6251FE',
    borderRadius: '15px 15px 2px 2px'
  },
  submitTrip: {
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1, 0, 0),
      fontSize: 12
    },
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(2, 0, 0)
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(2, 0, 0)
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(2, 0, 0)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(5, 0, 0)
    }
  }
}))

export default function CreditCards () {
  const { orderId } = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const [message, setMessage] = useState(null)
  const [err, setError] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const jwt = useSelector(state => state.user.jwt)

  const orderDetail = useSelector(state => state.order.orderDetail)
  const themeType = useSelector(state => state.themes.theme)
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const cardElementOptions = {
    style: {
      base: {
        iconColor: themeType === 'dark' ? '#FFFFFF' : 'primary',
        fontWeight: '500',
        fontSize: '20px',
        padding: '10px',
        color: themeType === 'dark' ? '#FFFFFF' : 'primary',
        letterSpacing: '0.025em',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: themeType === 'dark' ? '#FFFFFF' : 'primary'
        }
      },
      invalid: {
        color: 'red',
        iconColor: 'red'
      }
    },
    hidePostalCode: true
  }

  let loading = false
  const avatarStyles = useDynamicAvatarStyles(
    lgMatch ? { size: 90 } : matches ? { size: 62 } : { size: 62 }
  )
  useEffect(() => {
    if (orderId !== null) {
      dispatch(actions.getOrderDetails(jwt, orderId))
    }
  }, [orderId])

  useEffect(() => {}, [themeType])

  useEffect(() => {
    if (!stripe && !elements) {
      return <CircularProgress color='secondary' />
    }
  }, [stripe])
  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const formik = useFormik({
    initialValues: {
      nameOnCard: '',
      emailOnCard: '',
      phoneOnCard: '',
      addressLine1: '',
      addressLine2: ''
    },
    validationSchema: Yup.object({
      nameOnCard: Yup.string().max(255).required('Name is required'),
      emailOnCard: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      phoneOnCard: Yup.string().max(255).required('Phone is required'),
      addressLine1: Yup.string().max(255).required('Address Line1 is required'),
      addressLine2: Yup.string().max(255).required('Address Line2 is required')
    }),
    onSubmit: async values => {
      const billingDetails = {
        name: values.nameOnCard,
        email: values.emailOnCard,
        phone: values.phoneOnCard,
        address: {
          line1: values.addressLine1,
          line2: values.addressLine1,
          country: 'gb'
        }
      }

      if (
        orderDetail?.estimatedPrice?.serviseCharger !== null &&
        orderId !== null
      ) {
        setIsLoading(true)

        await axios({
          method: 'post',
          url: backendPath + 'stripePayment',
          headers: { Authorization: `Bearer ${jwt}` },
          data: {
            orderId: orderId,
            email: values.emailOnCard,
            bookingFees: orderDetail?.estimatedPrice?.serviseCharger,
            description:
              orderDetail?.description !== null
                ? orderDetail?.description
                : 'new Order'
          }
        }).then(async function (response) {
          if (response.status === 200) {
            const cardElement = elements.getElement(CardElement)
            const paymentMethodReq = await stripe.createPaymentMethod({
              type: 'card',
              card: cardElement,
              billing_details: billingDetails
            })

            if (paymentMethodReq.paymentMethod.id) {
              try {
                const { error, paymentIntent } =
                  await stripe.confirmCardPayment(response.data.clientSecret, {
                    payment_method: paymentMethodReq.paymentMethod.id
                  })
                await stripe
                  .retrievePaymentIntent(response.data.clientSecret)
                  .then(({ paymentIntent }) => {
                    switch (paymentIntent.status) {
                      case 'succeeded':
                        setMessage('Payment succeeded!')
                        dispatch(actions.confirmPayment(orderId, jwt))

                        break
                      case 'processing':
                        setMessage('Your payment is processing.')
                        setIsLoading(true)

                        break
                      case 'requires_payment_method':
                        setMessage(
                          'Your payment was not successful, please try again.'
                        )
                        setIsLoading(false)

                        break
                      default:
                        setMessage('Something went wrong.')
                        setIsLoading(false)

                        break
                    }
                  })
              } catch (error) {
                setMessage(error.message)
              }
              setIsLoading(false)
            }
          }
        })
      } else {
        setMessage('No Current Order Exist')
      }
    }
  })

  const options = [
    {
      id: 1,
      title: 'Booking Fee: ',
      amount: orderDetail?.estimatedPrice?.serviseCharger
    },
    {
      id: 2,
      title: 'Driver Fee: ',
      amount: orderDetail?.estimatedPrice?.driverCharger
    },
    {
      id: 3,
      title: 'Total Price: ',
      amount: orderDetail?.estimatedPrice?.maximumPrice
    },
    {
      id: 4,
      title: 'Base Fare: ',
      amount: orderDetail?.estimatedPrice?.baseFare
    },

    {
      id: 5,
      title: 'Congestion Charges: ',
      amount: orderDetail?.estimatedPrice?.finalCongestionCharges
    },
    {
      id: 6,
      title: 'Cost flight of Stair: ',
      amount: orderDetail?.estimatedPrice?.finalCostFlightOfStair
    },
    {
      id: 7,
      title: 'Cost of Helpers: ',
      amount: orderDetail?.estimatedPrice?.finalCostOfHelpers
    },
    {
      id: 8,
      title: 'Cost per Mile Charges: ',
      amount: orderDetail?.estimatedPrice?.finalCostPerMileCharges
    },
    {
      id: 9,
      title: 'Cost per Mint Charges: ',
      amount: orderDetail?.estimatedPrice?.finalCostPerMintCharges
    },
    {
      id: 10,
      title: 'Insurrance Charges: ',
      amount: orderDetail?.estimatedPrice?.finalInsurranceCharges
    },
    {
      id: 11,
      title: 'Toll Charges: ',
      amount: orderDetail?.estimatedPrice?.finalTollCharges
    },
    {
      id: 12,
      title: 'Factore Charges: ',
      amount: orderDetail?.estimatedPrice?.finalTypeFactoreCharges
    },

    {
      id: 13,
      title: 'Ride Distance: ',
      amount: orderDetail?.estimatedPrice?.rideDistance
    },
    {
      id: 14,
      title: 'Time In Ride: ',
      amount: orderDetail?.estimatedPrice?.timeInRide
    }
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {loading ? (
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress style={{ width: '50px', height: '50px' }} />
          </div>
        ) : (
          <>
            <Grid container style={{ marginTop: '3%' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                style={{ width: '50vw' }}
                className={classes.container}
              >
                <Boxs
                  xs={{ ml: '3%', mr: '3%', mt: '3%' }}
                  sm={{ ml: '8%', mr: '8%', mt: '3%' }}
                  md={{ ml: '8%', mr: '8%', mt: '3%' }}
                  lg={{ ml: '3%', mt: '0%', mr: '0%' }}
                  xl={{ ml: '3%', mt: '0%', mr: '0%' }}
                >
                  <Card
                    className={classes.cardroot}
                    variant='outlined'
                    style={{ borderRadius: 14, marginBottom: '3%' }}
                  >
                    <Box style={{ width: '100%', padding: '1%' }}>
                      <CardContent>
                        <Typography
                          style={
                            lgMatch ? { fontSize: 32.4 } : { fontSize: 18 }
                          }
                        >
                          Order Summary
                        </Typography>

                        <Boxs
                          xs={{ mt: '3%' }}
                          sm={{ mt: '2%' }}
                          md={{ mt: '2%' }}
                          lg={{ mt: '3%' }}
                          xl={{ mt: '3%' }}
                        >
                          <Typography
                            style={
                              lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                            }
                          >
                            <b>Date: </b> &nbsp;
                            <span color='textPrimary'>
                              {moment(orderDetail?.dateAndTime).format(
                                'MMMM DD, YYYY h:mm a'
                              )}
                              &nbsp;
                            </span>
                          </Typography>
                        </Boxs>
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Order ID: </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.ID
                              ? orderDetail?.ID
                              : orderDetail?._id}
                            &nbsp;
                          </span>
                        </Typography>
                        <Grid
                          justify='space-between' // Add it here :)
                          container
                          spacing={24}
                        >
                          <Grid item xs={12}>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Order Status: </b> &nbsp;
                              <span style={{ color: '#1EBC80' }}>
                                {orderDetail?.orderStatus}&nbsp;
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Payment: </b> &nbsp;
                              <span style={{ color: '#1EBC80' }}>
                                {orderDetail?.paymentDetails?.payment
                                  ? orderDetail?.paymentDetails?.payment
                                  : 'Pending'}
                                &nbsp;
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider
                          style={{ marginTop: '5%', marginBottom: '5%' }}
                        />
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Total helpers: </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.noOfHelpersRequired}&nbsp;
                          </span>
                        </Typography>
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Pickup Address: </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.tripStartLocation?.formattedAddress}
                            &nbsp;
                          </span>
                        </Typography>
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Floor # : </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.tripStartLocation?.floor}&nbsp;
                          </span>
                        </Typography>
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Destination Address: </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.tripEndLocation?.formattedAddress}
                            &nbsp;
                          </span>
                        </Typography>
                        <Typography
                          style={lgMatch ? { fontSize: 16 } : { fontSize: 12 }}
                        >
                          <b>Floor # : </b> &nbsp;
                          <span color='textPrimary'>
                            {orderDetail?.tripEndLocation?.floor}&nbsp;
                          </span>
                        </Typography>
                        <Divider
                          style={{ marginTop: '4%', marginBottom: '3%' }}
                        />

                        <Grid
                          justifyContent='space-between' // Add it here :)
                          container
                          alignItems='center'
                          spacing={3}
                        >
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Booking Fee: </b> &nbsp;
                              <span color='textPrimary'>
                                £{orderDetail?.estimatedPrice?.serviseCharger}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Driver Fee: </b> &nbsp;
                              <span color='textPrimary'>
                                £{orderDetail?.estimatedPrice?.driverCharger}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch
                                  ? { fontSize: 16, color: '#c3c3c3 ' }
                                  : { fontSize: 12, color: '#c3c3c3 ' }
                              }
                            >
                              <b
                                style={
                                  themeType === 'dark'
                                    ? { color: '#c3c3c3 ' }
                                    : { color: '#000' }
                                }
                              >
                                Note:{' '}
                              </b>{' '}
                              &nbsp;
                              <span
                                style={
                                  themeType === 'dark'
                                    ? { color: '#c3c3c3 ' }
                                    : { color: '#000' }
                                }
                              >
                                May Vary due to Change in Cogestion and Toll
                                Charges &nbsp;
                              </span>
                            </Typography>

                            <Divider
                              style={{ marginTop: '4%', marginBottom: '3%' }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          justifyContent='space-between' // Add it here :)
                          container
                          alignItems='center'
                          spacing={3}
                        >
                          <Grid item xs={5} sm={6}>
                            <Typography
                              style={
                                lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                              }
                            >
                              <b>Total Price: </b> &nbsp;
                              <span color='textPrimary'>
                                £{orderDetail?.estimatedPrice?.maximumPrice}
                                &nbsp;
                              </span>
                            </Typography>
                            <Typography
                              style={
                                lgMatch
                                  ? { fontSize: 16, color: '#c3c3c3 ' }
                                  : { fontSize: 12, color: '#c3c3c3 ' }
                              }
                            >
                              <b
                                style={
                                  themeType === 'dark'
                                    ? { color: '#c3c3c3 ' }
                                    : { color: '#000' }
                                }
                              >
                                Note:{' '}
                              </b>{' '}
                              &nbsp;
                              <span
                                style={
                                  themeType === 'dark'
                                    ? { color: '#c3c3c3 ' }
                                    : { color: '#000' }
                                }
                              >
                                May Vary due to Changes in Driver Fees &nbsp;
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={7} sm={6}>
                            <Button
                              color='primary'
                              size='large'
                              variant='contained'
                              onClick={handleClickOpen}
                              style={
                                lgMatch
                                  ? {
                                      fontSize: 14,
                                      textDecoration: 'none',
                                      float: 'right'
                                    }
                                  : {
                                      fontSize: 12,
                                      textDecoration: 'none',
                                      float: 'right'
                                    }
                              }
                            >
                              Price Breakdown
                            </Button>
                            <Dialog
                              maxWidth={lgMatch ? 'sm' : 'xs'}
                              fullWidth
                              open={openDialog}
                              classes={{
                                paper: classes.dialog
                              }}
                              onClose={handleCloseDialog}
                              aria-labelledby='responsive-dialog-title'
                            >
                              <Box m={1}>
                                <Paper elevation={3} className={classes.price}>
                                  <DialogTitle id='responsive-dialog-title'>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {'Estimated Price Breakdown'}
                                    </span>
                                    <br />
                                    Total Price: £
                                    {orderDetail?.estimatedPrice?.maximumPrice}
                                    &nbsp;
                                  </DialogTitle>
                                </Paper>
                              </Box>
                              <DialogContent>
                                <DialogContentText>
                                  {options.map(option => (
                                    <Grid container key={option.id}>
                                      <Grid xs={9} sm={6}>
                                        <Typography
                                          className={classes.titleAcc}
                                          color='textPrimary'
                                        >
                                          {option.title}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        xs={3}
                                        sm={6}
                                        justifyContent='flex-end'
                                      >
                                        <Typography color='textPrimary'>
                                          <b> £{option.amount} </b>
                                        </Typography>
                                      </Grid>
                                      {option.id === 15 ? null : (
                                        <Divider
                                          style={{
                                            marginBottom: '3%',
                                            width: '100%'
                                          }}
                                        />
                                      )}
                                    </Grid>
                                  ))}
                                </DialogContentText>
                                <Button
                                  variant='outlined'
                                  large
                                  fullWidth
                                  onClick={handleCloseDialog}
                                  color={
                                    themeType === 'dark' ? 'FFFFFF' : 'primary'
                                  }
                                  autoFocus
                                >
                                  Close
                                </Button>
                              </DialogContent>
                            </Dialog>
                          </Grid>
                        </Grid>
                        <Grid
                          justifyContent='space-between' // Add it here :)
                          container
                          alignItems='center'
                          spacing={3}
                        >
                          <Grid item xs={5} sm={6}></Grid>
                          <Grid item xs={7} sm={6}>
                            <Button
                              color='primary'
                              size='large'
                              variant='contained'
                              disabled={
                                message && message == 'Payment succeeded!'
                              }
                              onClick={() =>
                                dispatch(
                                  actions.updatePendingOrderDetails(
                                    jwt,
                                    orderId
                                  )
                                )
                              }
                              style={
                                lgMatch
                                  ? {
                                      fontSize: 14,
                                      textDecoration: 'none',
                                      float: 'right',

                                      paddingLeft: '40px',
                                      paddingRight: '32px'
                                    }
                                  : {
                                      fontSize: 12,
                                      textDecoration: 'none',
                                      float: 'right',

                                      paddingLeft: '40px',
                                      paddingRight: '32px'
                                    }
                              }
                            >
                              Update Order
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Card>
                </Boxs>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                style={{ width: '50vw' }}
                className={classes.container}
              >
                <Boxs
                  xs={{ ml: '3%', mr: '3%', mt: '3%' }}
                  sm={{ ml: '8%', mr: '8%', mt: '3%' }}
                  md={{ ml: '8%', mr: '8%', mt: '3%' }}
                  lg={{ ml: '3%', mt: '0%', mr: '0%' }}
                  xl={{ ml: '3%', mt: '0%', mr: '0%' }}
                >
                  <Card
                    className={classes.cardroot}
                    variant='outlined'
                    style={{ borderRadius: 14, marginBottom: '3%' }}
                  >
                    <Box className={classes.box} style={{ textAlign: 'left' }}>
                      <Grid container>
                        <Grid item xs={6} lg={6} align='right'>
                          <img
                            src={
                              lgMatch
                                ? '/assets/images/payment/mastercardlogo.png'
                                : '/assets/images/payment/mastersmall.png'
                            }
                            className={classes.logoMas}
                          />
                        </Grid>
                        <Grid item xs={6} lg={6} align='left'>
                          <img
                            src={
                              lgMatch
                                ? '/assets/images/payment/visalogo.png'
                                : '/assets/images/payment/visasmall.png'
                            }
                            className={classes.logoVisa}
                          />
                        </Grid>
                      </Grid>

                      <form id='payment-form' onSubmit={formik.handleSubmit}>
                        <Box textAlign='left' mt={2}>
                          <Typography
                            color='#c3c3c3'
                            style={
                              lgMatch ? { fontSize: 16 } : { fontSize: 12 }
                            }
                          >
                            Card Number
                          </Typography>

                          <Box
                            textAlign='center'
                            style={{
                              border: '1px solid  #d6d6c2',
                              padding: '8px'
                            }}
                          >
                            <CardElement
                              id='payment-element'
                              options={cardElementOptions}
                              onChange={e => {
                                setError(e.error)
                                setIsLoading(false)
                              }}
                            />
                          </Box>
                          {err && err.message ? (
                            <Typography
                              color='textPrimary'
                              style={{ color: 'red' }}
                            >
                              {err.message}
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Box>

                        <Box textAlign='center' mt={2}>
                          <Typography
                            color='textPrimary'
                            style={
                              lgMatch
                                ? { fontSize: 16, float: 'left' }
                                : { fontSize: 12, float: 'left' }
                            }
                          >
                            Card Holder Name
                          </Typography>
                          <TextField
                            error={Boolean(
                              formik.touched.nameOnCard &&
                                formik.errors.nameOnCard
                            )}
                            fullWidth
                            helperText={
                              formik.touched.nameOnCard &&
                              formik.errors.nameOnCard
                            }
                            size='small'
                            name='nameOnCard'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.nameOnCard}
                            variant='outlined'
                            placeholder='Enter Name'
                          />
                        </Box>
                        <Box mt={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} lg={6}>
                              <Typography
                                color='textPrimary'
                                style={
                                  lgMatch
                                    ? { fontSize: 16, float: 'left' }
                                    : { fontSize: 12, float: 'left' }
                                }
                              >
                                Card Holder Phone
                              </Typography>
                              <TextField
                                error={Boolean(
                                  formik.touched.phoneOnCard &&
                                    formik.errors.phoneOnCard
                                )}
                                fullWidth
                                helperText={
                                  formik.touched.phoneOnCard &&
                                  formik.errors.phoneOnCard
                                }
                                size='small'
                                name='phoneOnCard'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phoneOnCard}
                                variant='outlined'
                                placeholder='Enter Phone'
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Typography
                                color='textPrimary'
                                style={
                                  lgMatch
                                    ? { fontSize: 16, float: 'left' }
                                    : { fontSize: 12, float: 'left' }
                                }
                              >
                                Card Holder Email
                              </Typography>
                              <TextField
                                error={Boolean(
                                  formik.touched.emailOnCard &&
                                    formik.errors.emailOnCard
                                )}
                                fullWidth
                                helperText={
                                  formik.touched.emailOnCard &&
                                  formik.errors.emailOnCard
                                }
                                size='small'
                                name='emailOnCard'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.emailOnCard}
                                variant='outlined'
                                placeholder='Enter Email'
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} lg={6}>
                              <Typography
                                color='textPrimary'
                                style={
                                  lgMatch
                                    ? { fontSize: 16, float: 'left' }
                                    : { fontSize: 12, float: 'left' }
                                }
                              >
                                Address line1
                              </Typography>
                              <TextField
                                error={Boolean(
                                  formik.touched.addressLine1 &&
                                    formik.errors.addressLine1
                                )}
                                fullWidth
                                helperText={
                                  formik.touched.addressLine1 &&
                                  formik.errors.addressLine1
                                }
                                size='small'
                                name='addressLine1'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.addressLine1}
                                variant='outlined'
                                placeholder='Enter Address Line1'
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Typography
                                color='textPrimary'
                                style={
                                  lgMatch
                                    ? { fontSize: 16, float: 'left' }
                                    : { fontSize: 12, float: 'left' }
                                }
                              >
                                Address line2
                              </Typography>
                              <TextField
                                error={Boolean(
                                  formik.touched.addressLine2 &&
                                    formik.errors.addressLine2
                                )}
                                fullWidth
                                helperText={
                                  formik.touched.addressLine2 &&
                                  formik.errors.addressLine2
                                }
                                size='small'
                                name='addressLine2'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.addressLine2}
                                variant='outlined'
                                placeholder='Enter Address Line2'
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        {message && message == 'Payment succeeded!' ? (
                          <div
                            id='payment-message'
                            style={{
                              backgroundColor: 'green',
                              color: '#fff',
                              padding: '10px',
                              marginTop: '10px'
                            }}
                          >
                            {message}
                          </div>
                        ) : message ? (
                          <div
                            id='payment-message'
                            style={{
                              backgroundColor: 'red',
                              color: '#fff',
                              padding: '10px',
                              marginTop: '10px'
                            }}
                          >
                            {message}
                          </div>
                        ) : (
                          ''
                        )}

                        <Box mt={2}>
                          <Button
                            type='submit'
                            id='submit'
                            fullWidth
                            color='primary'
                            size='large'
                            variant='contained'
                            className={classes.submit}
                            disabled={
                              isLoading ||
                              !stripe ||
                              !elements ||
                              (message && message == 'Payment succeeded!')
                            }
                          >
                            {isLoading ? (
                              <CircularProgress color='secondary' />
                            ) : (
                              `Pay £:${orderDetail?.estimatedPrice?.serviseCharger}`
                            )}
                          </Button>
                        </Box>
                      </form>
                      <Box textAlign='center' mb={1} pb={4}>
                        <Typography
                          style={
                            lgMatch
                              ? { fontSize: 16, color: '#c3c3c3 ' }
                              : { fontSize: 12, color: '#c3c3c3 ' }
                          }
                        >
                          <b
                            style={
                              themeType === 'dark'
                                ? { color: '#c3c3c3 ' }
                                : { color: '#000' }
                            }
                          >
                            Note:{' '}
                          </b>{' '}
                          &nbsp;
                          <span
                            style={
                              themeType === 'dark'
                                ? { color: '#c3c3c3 ' }
                                : { color: '#000' }
                            }
                          >
                             Only booking charge is payable. Rest of the
                            amout will be paid to the driver by the customer.
                            &nbsp;
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Boxs>
              </Grid>
            </Grid>
          </>
        )}
      </main>
    </div>
  )
}
