import * as yup from 'yup'

const schemata = {
  ratingSchema: yup.object().shape({
    value: yup
      .number()
      .min(1, 'Min value 1.')
      .required('This field is required.')
    // comment: yup.string().required('This field is required')
  })
}

export default schemata
