import React, { useEffect, useState } from "react";

import {
  useTheme,
  useMediaQuery,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { withScriptjs, withGoogleMap } from "react-google-maps";

//Components
import Map from "./Map";
import mapStyles from "../../Utils/mapStyles";

const googleMapsApiKey = "AIzaSyCmjiTW64B01tMxtOGACg7vnW4QJAFmY0A";

//Style
const useStyles = makeStyles((theme) => ({
  progress: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "30px",
      height: "30px",
      marginTop: "20%",
      marginLeft: "50%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "40px",
      height: "40px",
      marginTop: "30%",
      marginLeft: "50%",
    },
  },
}));

const libraries = ["places"];
const center = {
  lat: 51.509865,
  lng: -0.118092,
};
const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

//Map
function Maps() {
  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 43.653225, lng: -79.383186 }}
      options={mapOptions}
    />
  );
}
const WrappedMap = withScriptjs(withGoogleMap(Maps));

const Location = (props) => {
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom,

    places,
    liveLocation,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const lgMatches = useMediaQuery(theme.breakpoints.up("lg"));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCmjiTW64B01tMxtOGACg7vnW4QJAFmY0A",
    libraries,
  });

  return (
    <>
      {isLoaded ? (
        <Map
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=" +
            googleMapsApiKey +
            "&libraries=geometry,drawing,places"
          }
          markers={places}
          liveLocation={liveLocation}
          loadingElement={loadingElement || <div style={{ height: `100%` }} />}
          // mapStyle={mapStyles}
          options={mapOptions}
          containerElement={
            containerElement || (
              <div
                style={
                  smMatches
                    ? { height: "30vh" }
                    : lgMatches
                    ? { height: "100%" }
                    : { height: "40vh" }
                }
              />
            )
          }
          mapElement={
            mapElement || (
              <div
                style={
                  matches
                    ? { height: `100%`, width: "100%" }
                    : { height: `100%`, width: "100%" }
                }
              />
            )
          }
          defaultCenter={
            defaultCenter || {
              lat: 33.738045,
              lng: 73.084488,
            }
          }
          defaultZoom={defaultZoom || 11}
        />
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  );
};

export default Location;
