//Libraries
import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 18
    }
  },
  content: {
    flexGrow: 1
  },
  maintitle: {
    fontWeight: 'bold',
    marginTop: '1%',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 16
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28
    }
  },
  image: {
    alignItems: 'center',
    marginTop: '2%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('md')]: {
      width: '30%',
      height: '30%'
    }
  },
  mainbox: {
    width: '100%',
    textAlign: 'center'
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  box: {
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginTop: '2%'
    }
  }
}))

export default function NoOrders ({ setFieldValue }) {
  const classes = useStyles()

  return (
    <div>
      <main className={classes.content}>
        <div className={classes.toolbar} align='center'>
          <Box className={classes.mainbox}>
            <img src='/assets/images/noOrder.png' className={classes.image} />
            <Typography className={classes.maintitle}>
              No {setFieldValue} order
            </Typography>
          </Box>
        </div>
      </main>
    </div>
  )
}
