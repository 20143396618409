//Libraries
import React, { useState, useEffect } from 'react'
import {
  Box,
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  useTheme,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Alert as MuiAlert } from '@material-ui/lab'
import SwipeableViews from 'react-swipeable-views'
import styled from '@emotion/styled'
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'
//Components
import CreditCards from './CreditCards'
import Paypal from './Paypal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

// const stripePromise =await loadStripe(
//   process.env.STRIPE_PUBLIC_KEY
// )
let stripeLoading = async () => {
  const stripePromise = await loadStripe(
    'pk_test_51LVYGJKLZ8Zi4bZt8fkkg9dsrvzxpFJBLsGp3kt9cLgl3cth9GQ8GbKJwWpaQSlBrN1jatNRIsg1gCXGCSnZpsSq00EAbJEdVB'
  )
  return stripePromise
}

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '3%',
      marginLeft: '5%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    }
  },
  mainRoot: {
    display: 'flex',
    width: '100%'
  },
  customStyleOnTab: {
    fontSize: '15px'
  },
  activeTab: {
    fontSize: '15px',
    fontWeight: '600',
    color: 'textPrimary'
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
      alignItems: 'left'
    },
    '& .MuiTab-root': {
      padding: '0px'
    }
  },
  bar: {
    background: 'transparent',
    boxShadow: 'none',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '20%'
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '10%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '6%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '3%',
      paddingLeft: '2.5%'
    }
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const StyledTabs = styled(({ className, ...other }) => {
  return (
    <Tabs
      {...other}
      classes={{
        root: className,
        flexContainer: 'flexContainer',
        indicator: 'indicator'
      }}
      TabIndicatorProps={{ children: <span /> }}
    />
  )
})({
  '& .indicator': {
    background: 'primary',
    minWidth: '30px',
    maxWidth: '30px'
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    textAlign: 'left'
  }
})
const appearance = {
  theme: 'stripe'
}
const options = {
  appearance
}

export default function Payment () {
  const classes = useStyles()
  const stripeKey = stripeLoading()
  if (!stripeKey)
    return (
      <div className='spinner' id='spinner'>
        <CircularProgress color='secondary' />
      </div>
    )
  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        <div className={classes.root}>
          <Elements options={options} stripe={stripeKey}>
            <CreditCards />
          </Elements>
        </div>
      </main>
    </div>
  )
}
