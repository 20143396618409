//Libraries
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MessageIcon from "@mui/icons-material/Message";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Snackbar,
  CssBaseline,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Formik, Form } from "formik";
import actions from "../Redux/User/User.actions";
import Yup from "../Yup/YupContactUsValidation.schemas";
import { useDispatch, useSelector } from "react-redux";
//Components
import NavigationDrawer from "../Components/NavigationDrawer/NavigationDrawer";

//Style
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1, 2),
      marginTop: "15%",
    },
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "15%",
      padding: theme.spacing(1, 1),
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "9%",
      padding: theme.spacing(1, 1),
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "9%",
      padding: theme.spacing(0, 1),
    },
  },
  submit: {
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "md")]: {
      margin: theme.spacing(2, 0, 2),
    },
    [theme.breakpoints.between("lg", "xl")]: {
      margin: theme.spacing(2, 0, 4),
    },
  },
  root: {
    display: "flex",
  },
  image: {
    objectFit: "cover",
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(4),
      width: "60%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(13),
      width: "40%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(19),
      width: "60%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: theme.spacing(16),
      width: "80%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(15),
    },
  },
  cardroot: {
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
    borderRadius: 8,
    [theme.breakpoints.only("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "80%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "70%",
    },
  },
  heading: {
    fontWeight: "bold",
    float: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 23,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 28,
    },
  },
  box: {
    width: "100%",
    padding: "3%",
  },
  subHead: {
    float: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: 16,
    },
  },
  subBox: {
    float: "right",
    marginRight: "1%",
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mdMatch = useMediaQuery(theme.breakpoints.up("md"));
  const xsMatch = useMediaQuery(theme.breakpoints.only("xs"));
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dissabled, setDissable] = useState(false);
  const jwt = useSelector((state) => state.user.jwt);

  const contactUsError = useSelector(
    (state) => state.user.errors.contactUsError
  );
  console.log('contactUsError',contactUsError)
  const contactUsSuccess = useSelector(
    (state) => state.user.success.contactUsSuccess
  );
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  function Alert(props) {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
  }

  useEffect(() => {
    dispatch(actions.clearError());
  }, []);

  useEffect(() => {
    if (contactUsError) {
      handleErrorSnack();
    }
  }, [contactUsError]);

  const handleErrorSnack = () => {
    setOpenError(true);
  };
  useEffect(() => {
    if (contactUsSuccess) {
      handleSuccessSnack();
    }
  }, [contactUsSuccess]);

  const handleSuccessSnack = () => {
    setOpenSuccess(true);
    setDissable(true);
    setInitialValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };
  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container alignContent="center">
            <Grid item xs={12} sm={12} md={5} lg={6} xl={6} align="center">
              <img
                src="/assets/images/contactus.png"
                className={classes.image}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6} xl={6} align="center">
              <Card className={classes.cardroot} elevation={2}>
                <Box className={classes.box}>
                  <CardContent>
                    <Typography className={classes.heading}>
                      Send us a Message
                    </Typography>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={Yup.YupContactUsValidationSchema}
                      onSubmit={(values) => {
                        dispatch(actions.contactUs(values, jwt));
                      }}
                    >
                      {({ errors, handleChange, touched }) => (
                        <Form className={classes.form}>
                          <Box mt={xsMatch ? 3 : 8}>
                            <Grid container spacing={xsMatch ? 1 : 2}>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography className={classes.subHead}>
                                  First Name
                                </Typography>
                                <TextField
                                  disabled={dissabled}
                                  id="firstName"
                                  name="firstName"
                                  autoComplete="firstName"
                                  placeholder="Enter first name"
                                  margin={xsMatch ? "none" : "normal"}
                                  variant="outlined"
                                  fullWidth
                                  error={errors.firstName && touched.firstName}
                                  helperText={
                                    errors.firstName && touched.firstName
                                      ? errors.firstName
                                      : null
                                  }
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography className={classes.subHead}>
                                  Last Name
                                </Typography>
                                <TextField
                                  disabled={dissabled}
                                  id="lastName"
                                  name="lastName"
                                  autoComplete="lastName"
                                  placeholder="Enter last name"
                                  margin={xsMatch ? "none" : "normal"}
                                  variant="outlined"
                                  fullWidth
                                  error={errors.lastName && touched.lastName}
                                  helperText={
                                    errors.lastName && touched.lastName
                                      ? errors.lastName
                                      : null
                                  }
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box mt={xsMatch ? 1 : 2}>
                            <Typography className={classes.subHead}>
                              Email Address
                            </Typography>
                            <TextField
                              disabled={dissabled}
                              id="email"
                              name="email"
                              autoComplete="email"
                              placeholder="Enter Email Address"
                              fullWidth
                              margin={xsMatch ? "none" : "normal"}
                              variant="outlined"
                              error={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : null
                              }
                              inputProps={{
                                maxLength: 50,
                              }}
                              onChange={handleChange}
                            />
                          </Box>
                          <Box mt={xsMatch ? 1 : 2}>
                            <Typography className={classes.subHead}>
                              Phone Number
                            </Typography>
                            <TextField
                              disabled={dissabled}
                              id="phone"
                              name="phone"
                              autoComplete="phone"
                              placeholder="Enter Phone number"
                              fullWidth
                              margin={xsMatch ? "none" : "normal"}
                              variant="outlined"
                              error={errors.phone && touched.phone}
                              helperText={
                                errors.phone && touched.phone
                                  ? errors.phone
                                  : null
                              }
                              inputProps={{
                                maxLength: 16,
                              }}
                              onChange={handleChange}
                            />
                          </Box>
                          <Box mt={xsMatch ? 1 : 2}>
                            <Typography className={classes.subHead}>
                              Write us your Message
                            </Typography>
                            <TextField
                              disabled={dissabled}
                              id="message"
                              name="message"
                              autoComplete="message"
                              placeholder="Write us your Message"
                              fullWidth
                              margin={xsMatch ? "none" : "normal"}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <MessageIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.message && touched.message}
                              helperText={
                                errors.message && touched.message
                                  ? errors.message
                                  : null
                              }
                              inputProps={{
                                maxLength: 100,
                              }}
                              onChange={handleChange}
                            />
                          </Box>
                          {contactUsError ? (
                            <Snackbar
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              open={openError}
                              autoHideDuration={5000}
                              onClose={handleCloseSnack}
                            >
                              <Alert
                                onClose={handleCloseSnack}
                                severity="error"
                              >
                                {contactUsError}
                              </Alert>
                            </Snackbar>
                          ) : null}
                          {contactUsSuccess ? (
                            <Snackbar
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              open={openSuccess}
                              autoHideDuration={5000}
                              onClose={handleCloseSnack}
                            >
                              <Alert
                                onClose={handleCloseSnack}
                                severity="success"
                              >
                                {contactUsSuccess}
                              </Alert>
                            </Snackbar>
                          ) : null}
                          <Box mt={mdMatch ? 3 : 0} className={classes.subBox}>
                            <Button
                              type="submit"
                              color="primary"
                              size="large"
                              variant="contained"
                              disabled={dissabled}
                              className={classes.submit}
                            >
                              Confirm
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}
