//Components
import userActions from './User.actions'

//User State/Store
const initialState = {
  _id: null,
  name: null,
  email: null,
  phone: null,
  city: null,
  state: null,
  address: null,
  newPhone: null,
  isLoggedIn: false,
  isFetching: false,
  isFetch: false,
  isJWTRecieved: false,
  status: null,
  jwt: null,
  otp: null,
  visible: false,
  currentPassword: null,
  isOTPRecieved: false,
  isOTPResent: false,
  updateP: false,
  notification: null,
  updatePhoneUserSuccess: null,
  success: {
    signInSuccess: null,
    signUpSuccess: null,
    otpResentSuccess: null,
    otpSuccess: null,
    forgotPasswordSucces: null,
    updateUserSuccess: null,
    updatePhoneNewUserSuccess: null,
    currentPassSuccess: null,
    contactUsSuccess: null
  },
  rememberMe: {
    isRememberMe: false,
    password: null,
    emailPhone: null
  },
  errors: {
    signInError: null,
    signUpError: null,
    otpError: null,
    forgotPasswordError: null,
    otpPhoneError: null,
    updateUserError: null,
    updatePhoneNewUserError: null,
    updatePhoneUserError: null,
    currentPassError: null,
    contactUsError: null
  }
}

// User Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //#region Sign up
    case userActions.FRESH_SIGN_UP:
      return initialState

    case userActions.SIGN_UP:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors, signUpError: null },
        success: { ...state.success, signUpSuccess: null }
      }

    case userActions.SET_SIGN_UP_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        city: action.payload.city,
        isFetching: false,
        isOTPRecieved: true,
        errors: { ...state.errors, signUpError: null },
        success: {
          ...state.success,
          signUpSuccess: action.payload.signUpSuccess
        }
      }
    case userActions.SET_CONTACT_US_SUCCESS:
      return {
        ...state,

        errors: { ...state.errors, contactUsError: null },
        success: {
          ...state.success,
          contactUsSuccess: action.payload.contactUsSuccess
        }
      }
    case userActions.SET_CONTACT_US_ERROR:
      console.log('contactUsError',action.payload)
      return {
        ...state,

        errors: {
          ...state.errors,
          contactUsError: action.payload.contactUsError
        },
        success: { ...state.success, contactUsSuccess: null }
      }
    case userActions.SET_SIGN_UP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, signUpError: action.payload.signUpError },
        success: { ...state.success, signUpSuccess: null }
      }
    case userActions.SET_PASSWORD_FETCHING:
      return {
        ...state,

        isFetching: true
      }

    case userActions.SIGN_UP_COMPLETE:
      return initialState

    case userActions.SET_JWT:
      return {
        ...state,
        jwt: action.payload.jwt,
        otp: action.payload.otp,
        isJWTRecieved: true,
        isFetching: false,
        errors: { ...state.errors, otpError: null },
        success: { ...state.success, otpSuccess: action.payload.success }
      }
    //#endregion

    //#region Sign in and set user
    case userActions.SET_USER:
      return {
        ...state,
        _id: action.payload._id,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        city: action.payload.city,
        state: action.payload.state,
        address: action.payload.address,
        isLoggedIn: true,
        isFetching: false,
        isJWTRecieved: true,
        jwt: action.jwt,
        errors: { ...state.errors, signInError: null },
        updatePhoneUserSuccess: null,
        success: {
          ...state.success,
          signInSuccess: action.successMessage
        }
      }

    case userActions.SIGN_IN:
      return {
        ...state,
        isFetching: true,
        updatePhoneUserSuccess: null,
        errors: { ...state.errors, signInError: null },
        success: { ...state.success, signInSuccess: null }
      }

    case userActions.SET_SIGN_IN_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, signInError: action.payload.signInError },
        success: { ...state.success, signInSuccess: null }
      }
      case userActions.GET_USER_ERROR:
        return {
          ...state,
          isFetching: false,
          
        }
        case userActions.SUBSCRIPTION_ERROR:
          return {
            ...state,
            isFetching: false,
            
          }
    //#endregion

    //#region Current password
    case userActions.CURRENT_PASS:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors, currentPassError: null },
        success: { ...state.success, currentPassSuccess: null }
      }

    case userActions.SET_CURRENT_PASS_ERROR:
      return {
        ...state,
        isFetching: false,
        visible: false,
        errors: {
          ...state.errors,
          currentPassError: action.payload.currentPassError
        },
        success: { ...state.success, currentPassSuccess: null }
      }

    case userActions.SET_CURRENT_PASS_SUCCESS:
      return {
        ...state,
        visible: true,
        isFetching: false,
        errors: { ...state.errors, signUpError: null },
        currentPassword: action.payload.currentPassword,
        success: {
          ...state.success,
          currentPassSuccess: action.payload.currentPassSuccess
        }
      }
    //#endregion

    //#region otp request and verify

    case userActions.UPDATE_PHONE_OTP:
      return {
        ...state,

        isFetching: true
      }
    case userActions.REQUEST_OTP_LOGIN:
      return {
        ...state,
        isFetching: false,
        newPhone: action.payload.phone
      }

    case userActions.VERIFY_OTP:
      return {
        ...state,
        isFetching: true
      }
    case userActions.FORGET_PASSWORD:
      return {
        ...state,
        isFetching: true
      }
    case userActions.RESENT_OTP_SUCCESS:
      return {
        ...state,
        isOTPResent: true,
        isFetching: false,
        errors: { ...state.errors, otpError: null },
        success: {
          ...state.success,
          otpResentSuccess: action.payload.otpSuccess
        }
      }

    case userActions.SET_OTP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, otpError: action.payload.otpError },
        success: { ...state.success, otpSuccess: null }
      }

    case userActions.RESENT_PHONE_OTP_SUCCESS:
      return {
        ...state,
        isOTPResent: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case userActions.SET_PHONE_OTP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, otpPhoneError: action.payload.otpError },
        success: { ...state.success, otpSuccess: null }
      }
    //#endregion

    //#region remember me
    case userActions.SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: {
          isRememberMe: action.payload.signInObj.checked,
          emailPhone: action.payload.signInObj.username,
          password: action.payload.signInObj.password
        }
      }

    case userActions.CLEAR_REMEMBER_ME:
      return {
        ...state,
        rememberMe: initialState.rememberMe
      }
    //#endregion

    //#region update User
    case userActions.UPDATE_USER:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case userActions.SET_UPDATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        name: action.payload.name,
        email:action.payload.email,
        city: action.payload.city,
        state: action.payload.state,
        address: action.payload.address,
        errors: { ...state.errors, updateUserError: null },
        success: {
          ...state.success,
          updateUserSuccess: action.payload.updateUserSuccess
        }
      }

    case userActions.SET_UPDATE_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updateUserError: action.payload.updateUserError
        },
        success: { ...state.success, updateUserSuccess: null }
      }

    case userActions.UPDATE_PHONE_NEW_USER:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case userActions.SET_UPDATE_PHONE_NEW_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        phone: action.payload.phone,
        errors: { ...state.errors, updatePhoneNewUserError: null },
        success: {
          ...state.success,
          updatePhoneNewUserSuccess: action.payload.updatePhoneNewUserSuccess
        }
      }

    case userActions.SET_UPDATE_PHONE_NEW_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updatePhoneNewUserError: action.payload.updatePhoneNewUserError
        },
        success: { ...state.success, updatePhoneNewUserSuccess: null }
      }

    case userActions.UPDATE_PHONE_USER:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case userActions.SET_UPDATE_PHONE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, updatePhoneUserError: null },
        updatePhoneUserSuccess: action.payload.updatePhoneUserSuccess,
        success: {
          ...state.success
        }
      }

    case userActions.SET_UPDATE_PHONE_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updatePhoneUserError: action.payload.updatePhoneUserError
        },
        updatePhoneUserSuccess: null,
        success: { ...state.success }
      }
    //#endregion

    //#region Forgot Password
    case userActions.SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          forgotPasswordError: action.payload.forgotPasswordError
        },
        success: { ...state.success }
      }

    case userActions.SET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        phone: action.payload.phone,
        visible: true,
        isOTPRecieved: true,
        isFetching: false,
        errors: { ...state.errors, forgotPasswordError: null },
        success: { ...state.success }
      }

    case userActions.UPDATE_PASSWORD_COMPLETE:
      return {
        ...state,
        visible: false,
        isFetching: false,
        currentPassword: null
      }

    case userActions.RESET_PASSWORD_VIEW:
      return {
        ...state,
        visible: false
      }
    case userActions.RESET_PASSWORD_FETCHING:
      return {
        ...state,
        isFetching: true
      }
    //#endregion

    //signout
    case userActions.SIGN_OUT:
      return {
        ...state,
        _id: null,
        name: null,
        email: null,
        phone: null,
        city: null,
        state: null,
        address: null,
        newPhone: null,
        isLoggedIn: false,
        isFetching: false,
        isFetch: false,
        isJWTRecieved: false,
        status: null,
        jwt: null,
        otp: null,
        visible: false,
        currentPassword: null,
        isOTPRecieved: false,
        isOTPResent: false,
        updateP: false,
        notification: null,
        errors: initialState.errors,
        success: initialState.success
      }

    //clear error
    case userActions.CLEAR_ERROR:
      return {
        ...state,
        currentPassword: null,
        isFetching:false,
        errors: {
          ...state.errors,
          signInError: null,
          signUpError: null,
          otpError: null,
          forgotPasswordError: null,
          otpPhoneError: null,
          updateUserError: null,
          updatePhoneNewUserError: null,
          updatePhoneUserError: null,
          currentPassError: null,
          contactUsError: null
        },
        success: {
          ...state.success,
          signInSuccess: null,
          signUpSuccess: null,
          otpResentSuccess: null,
          otpSuccess: null,
          forgotPasswordSucces: null,
          updateUserSuccess: null,
          updatePhoneNewUserSuccess: null,
          updatePhoneUserSuccess: null,
          currentPassSuccess: null,
          contactUsSuccess: null
        }
      }

    //cancel order
    case userActions.CANCEL_ORDER:
      return {
        ...state,
        errors: { ...state.errors },
        success: {
          ...state.success
        }
      }

    //get notification
    case userActions.GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      }

    //default
    default:
      return state
  }
}

export default userReducer
