//Libraries
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  useMediaQuery,
  Grid,
  DialogContentText,
  DialogTitle,
  DialogContent,
  makeStyles,
  useTheme,
} from "@material-ui/core";

//Load Script
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

//Style
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },

  title: {
    fontSize: 15,
    marginTop: "2%",
  },

  alertError: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SearchLocation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [locationDialog, setLocationDialog] = React.useState(false);
  const [label, setLabel] = React.useState(
    props.address
      ? props.address
      : props.inputLocation
      ? props.inputLocation.formattedAddress
      : "Add a location"
  );

  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCmjiTW64B01tMxtOGACg7vnW4QJAFmY0A&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          request,

          callback
        );
      }, 200),

    []
  );
  React.useEffect(() => {
    setLabel(
      props.address
        ? props.address
        : props.inputLocation
        ? props.inputLocation.formattedAddress
        : "Add a location"
    );
  }, [props.address, props.inputLocation]);
  React.useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "denied") {
          setLocationDialog(true);
        }
      });
    }
  }, []);
  React.useEffect(() => {
    let active = true;

    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);

      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];

          geocodePlaceId(results[0]?.place_id);
        }

        if (results) {
          newOptions = [...newOptions, ...results];
          setOptions(newOptions);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const geocodePlaceId = (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({ placeId: placeId })
      .then(({ results }) => {
        if (results[0]) {
          props.setLat(results[0]?.geometry.location.lat());
          props.setLng(results[0]?.geometry.location.lng());
          props.mapRef.current.setCenter({
            lat: results[0]?.geometry.location.lat(),
            lng: results[0]?.geometry.location.lng(),
          });
          props.setMarkers({
            lat: results[0]?.geometry.location.lat(),
            lng: results[0]?.geometry.location.lng(),
          });
          props.mapRef.current.setZoom(15);
        } else {
          // window.alert('No resslts found')
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };
  const handleCloseLocationDialog = () => {
    setLocationDialog(false);
  };
  return (
    <>
      <Dialog
        open={locationDialog}
        onClose={handleCloseLocationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>Use Google's location service</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Van go requires access to your location Enable location services
              for a more accurate pickup experience.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLocationDialog} color="primary" autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        id="google-map-demo"
        style={{
          width: 250,
          background: theme.type == "light" ? "#fff" : "grey",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            style={{ color: "#000000" }}
            {...params}
            label={label}
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}
