//libraries
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Box,
  Snackbar,
  useMediaQuery,
  InputAdornment,
  IconButton,
  CssBaseline,
  Hidden,
  Paper,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";

//Components
import Loader from "../../../Components/Loader/Loader";
import actions from "../../../Redux/User/User.actions";
import Yup from "../../../Yup/YupAuthValidation.schemas";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    width: "70%",
    height: "auto",
  },
  captions: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "0.83rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.17rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.37rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.37rem",
    },
  },
  paper: {
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(3, 3),
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      margin: theme.spacing(6, 15),
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      margin: theme.spacing(15, 10),
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.only("lg")]: {
      margin: theme.spacing(4, 10),
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(10, 15),
      display: "flex",
      flexDirection: "column",
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(5),
    },
  },
  log: {
    marginBottom: "2%",
    [theme.breakpoints.only("xs")]: {
      marginTop: "12%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      marginTop: "7%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "9%",
    },
  },
  submit: {
    [theme.breakpoints.between("xs", "md")]: {
      margin: theme.spacing(3, 0, 4),
      fontWeight: "bold",
      fontSize: 12,
    },
    [theme.breakpoints.only("lg")]: {
      margin: theme.spacing(3, 0, 2),
      fontWeight: "bold",
      fontSize: 14,
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(7, 0, 2),
      fontWeight: "bold",
      fontSize: 14,
    },
  },
  links: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.155rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.125rem",
    },
  },
  textfields: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.83rem",
      marginTop: "2%",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.83rem",
      marginTop: "2%",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "0.9rem",
      marginTop: "2%",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.1rem",
      marginTop: "2%",
    },
  },
  divLink: {
    [theme.breakpoints.between("xs", "md")]: {
      textAlign: "center",
      marginTop: "2%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      textAlign: "left",
      marginTop: "1%",
    },
  },
}));

export default function SignIn(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.clearError());
  }, []);
  const loading = useSelector((state) => state.user.isFetching);
  const signInError = useSelector((state) => state.user?.errors?.signInError);
  const updatePhoneUserSuccess = useSelector(
    (state) => state.user?.updatePhoneUserSuccess
  );
  const handleErrorSnack = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (signInError) {
      handleErrorSnack();
    }
  }, [signInError]);

  const phone = useSelector((state) => state.user.rememberMe.emailPhone);
  const password = useSelector((state) => state.user.rememberMe.password);
  const isRemember = useSelector((state) => state.user.rememberMe.isRememberMe);

  const [openSnackUpdatePhone, setOpenSnackUpdatePhone] = useState(false);
  const handleErrorSnackUpdatePhone = () => {
    setOpenSnackUpdatePhone(true);
  };
  const handleCloseSnackUpdatePhone = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackUpdatePhone(false);
  };

  useEffect(() => {
    if (updatePhoneUserSuccess) {
      handleErrorSnackUpdatePhone();
    }
  }, [updatePhoneUserSuccess]);

  const isCheck = useSelector((state) => state.themes.isChecked);
  const themeType = useSelector((state) => state.themes.theme);

  const [values, setValues] = useState({
    username: phone === null ? "" : phone,
    password: password === null ? "" : password,
    showPassword: false,
    checked: isRemember === true ? true : false,
  });

  const handleOnChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Typography variant="h1" color="primary">
            <b>LOGO</b>
          </Typography>
          <Box className={classes.log}>
            <Typography variant="h1">
              <b>Log in.</b>
            </Typography>
          </Box>
          <Typography color="secondary" className={classes.captions}>
            Login with your data that you entered during registration
          </Typography>
          <Formik
            initialValues={values}
            validationSchema={Yup.SignInValidationSchema}
            onSubmit={(values) => {
              dispatch(actions.signIn(values, props));
            }}
          >
            {({ errors, handleChange, setFieldValue, touched }) => (
              <Form className={classes.form}>
                <Typography variant="body2" className={classes.textfields}>
                  Email / Phone
                </Typography>
                <TextField
                  error={errors.username && touched.username}
                  helperText={
                    errors.username && touched.username ? errors.username : null
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  defaultValue={phone}
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={handleChange}
                  placeholder="Enter your Email / Phone"
                />
                <Box>
                  <Typography variant="body2" className={classes.textfields}>
                    Password
                  </Typography>
                </Box>
                <TextField
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : null
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  placeholder="Enter your Password"
                  type={values.showPassword ? "text" : "password"}
                  defaultValue={password}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={5} md={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={isRemember === true ? true : false}
                          name="checked"
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      classes={{
                        body1: classes.body1Text,
                        label: classes.links, // here
                      }}
                      label="Remember me"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={7}
                    md={6}
                    lg={6}
                    style={{ textAlign: "right" }}
                  >
                    <Link href="/forgot_password" className={classes.links}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                >
                  {loading ? <Loader /> : "Sign In"}
                </Button>
              </Form>
            )}
          </Formik>
          <div className={classes.divLink}>
            <Typography className={classes.links}>
              Don't have an account Already?
              <Link href="/sign_up">{" Sign up"}</Link>
            </Typography>
          </div>
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={false}
          sm={false}
          md={false}
          lg={6}
          xl={6}
          style={{ backgroundColor: "#6251FE", textAlign: "center" }}
        >
          {themeType == "light" ? (
            <img
              src="/assets/images/signin.png"
              alt="picture"
              className={classes.image}
            />
          ) : (
            <img
              src="/assets/images/signind.png"
              alt="picture"
              className={classes.image}
            />
          )}
        </Grid>
      </Hidden>
      {signInError ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {signInError}
          </Alert>
        </Snackbar>
      ) : null}
      {updatePhoneUserSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackUpdatePhone}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdatePhone}
        >
          <Alert onClose={handleCloseSnackUpdatePhone} severity={"success"}>
            {updatePhoneUserSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  );
}
