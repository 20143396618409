import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px 10px 10px 2px',
    backgroundColor: '#F5F5F5',
    color: '#000',
    opacity: '100%',
    width: '-moz-fit-content',
    width: 'fit-content',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      padding: '1%',
      minWidth: '10%',
      maxWidth: '80%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
      padding: '1%',
      minWidth: '8%',
      maxWidth: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
      minWidth: '6%',
      maxWidth: '40%',
      padding: '0.5%'
    }
  },
  typo: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px'
    }
  },
  typoTime: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px'
    }
  },
  div: {
    marginLeft: '4%',
    marginTop: '2%'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'start'
  },
  box: {
    textAlign: 'end'
  }
}))

export const Receiver = React.memo(function SimpleCard ({
  msgTitle,
  msgMessage,
  msgTime
}) {
  const classes = useStyles()

  return (
    <div className={classes.div}>
      <Typography variant='body1' className={classes.title}>
        {msgTitle}
      </Typography>
      <Paper className={classes.root}>
        {msgMessage}
        <Box className={classes.box}>
          <Typography className={classes.typoTime} variant='body1'>
            {msgTime}
          </Typography>
        </Box>
      </Paper>
    </div>
  )
})
export default Receiver
