//Libraries
import React, { useEffect, useState } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer
} from 'react-google-maps'

//Components
import mapStyles from '../../Utils/mapStyles'

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true
}

function MapDirectionsRenderer (props) {
  const [directions, setDirections] = useState(null)
  const [newDirections, setNewDirections] = useState(null)
  const { places, travelMode } = props
  const [error, setError] = useState('')
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('xs'))
  const smmatch = useMediaQuery(theme.breakpoints.down('sm'))
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    const waypoints = places.map(p => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true
    }))

    const origin = waypoints.shift().location
    const destination = waypoints.pop().location

    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result)
        } else {
          setError(result)
        }
      }
    )
  }, [
    places[0]?.latitude,
    places[0]?.longitude,
    places[1]?.latitude,
    places[1]?.longitude
  ])
  useEffect(() => {
    setNewDirections(directions)
  }, [directions])

  // if (error) {
  //   return <h1>{'error'}</h1>
  // }
  return directions && <DirectionsRenderer directions={newDirections} />
}

const Map = withScriptjs(
  withGoogleMap(props => (
    <div className='map-container' style={{ height: '100%' }}>
      <GoogleMap
        defaultCenter={props.defaultCenter}
        defaultZoom={props.defaultZoom}
        options={mapOptions}
      >
        <Marker
          position={{
            lat: props.liveLocation?.lat,
            lng: props.liveLocation?.lng
          }}
          options={{
            icon: {
              url: '/assets/images/marker.jpg',
              scaledSize: new window.google.maps.Size(60, 60),
              anchor: { x: 10, y: 10 }
            }
          }}
        />

        {props.markers[0].latitude === 0 ? null : (
          <MapDirectionsRenderer
            places={props.markers}
            travelMode={window.google.maps.TravelMode.DRIVING}
          />
        )}
      </GoogleMap>
    </div>
  ))
)

export default Map
