//Libraries
import React, { useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Divider,
  Button,
  Dialog,
  useTheme,
  useMediaQuery,
  withStyles,
  Switch,
  DialogContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";

//Components
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import { useDispatch, useSelector } from "react-redux";
import { applyTheme } from "../../Redux/Theme/themeActions";

//Icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

//Style
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#6251FE",
    width: "auto",
    padding: "1.2%",
    width: "-moz-fit-content",
    width: "fit-content",
    color: "white",
  },
  dialogC: {
    padding: "24px 24px",
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.only("xs")]: {
      marginTop: "30%",
      marginLeft: "7%",
      marginRight: "7%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "18%",
      marginLeft: "4.5%",
      marginRight: "5%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "12%",
      marginLeft: "2.5%",
      marginRight: "5%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingTop: "2%",
      marginTop: "5%",
      marginLeft: "5%",
      marginRight: "5%",
      paddingRight: "1.5%",
    },
  },
  image: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
      height: "50%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "40%",
      height: "40%",
    },
  },
  div: {
    textAlign: "left",
    paddingRight: "2%",
  },
  title: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 23.2,
    },
  },
  maintitle: {
    fontWeight: "bold",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 28,
    },
  },
  mainRoot: {
    display: "flex",
    width: "100%",
  },
  row: {
    textDecoration: "none",
    color: theme.palette.type === "light" ? "black" : "white",
  },
  root: {
    textAlign: "right",
  },
  img: {
    verticalAlign: "middle",
    height: 68,
    width: 68,
  },
  box: {
    width: "100%",
    textAlign: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 32.4,
    },
  },
  subheading: {
    paddingTop: "2%",
  },
  divider: {
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "1.5%",
      marginBottom: "1.5%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "2%",
      marginBottom: "2%",
    },
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 55,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(28px)",
      color: "error",
      "& + $track": {
        backgroundColor: "#6251FE",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#6251FE",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#B9B6B6",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Setting(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const themesType = useSelector((state) => state.themes.theme);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeThemeHandler = () => {
    if (themesType === "dark") {
      dispatch(applyTheme("light"));
    } else if (themesType === "light") {
      dispatch(applyTheme("dark"));
    }
  };

  const changeConsentHandler = () => {
    if (Notification.permission === "default") {
      Notification.requestPermission();
    } else if (Notification.permission === "denied") {
      alert(
        "There is no Notification support on this device or it is disabled. Please check your settings."
      );
    } else {
      alert("Notification permission already granted");
    }
  };
  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        <Typography className={classes.maintitle}>General Settings</Typography>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid
            item
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            className={classes.div}
          >
            <Typography className={classes.title}>
              Enable Notification
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <IOSSwitch
              checked={
                Notification.permission === "granted"
                  ? true
                  : Notification.permission === "denied"
                  ? false
                  : false
              }
              onChange={changeConsentHandler}
              name="checkedA"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid
            item
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            className={classes.div}
          >
            <Typography className={classes.title}>Enable Dark Theme</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <IOSSwitch
              onChange={changeThemeHandler}
              checked={themesType === "dark" ? true : false}
              name="checkedB"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.maintitle}>Privacy Settings</Typography>
        <Divider className={classes.divider} />
        <Link id="RouterNavLink1" className={classes.row} to="/reset_password">
          <Grid container className={classes.row}>
            <Grid
              item
              xs={9}
              sm={10}
              md={11}
              lg={11}
              xl={11}
              className={classes.div}
            >
              <Typography className={classes.title}>Change Password</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className={classes.root}
            >
              <ArrowRightIcon />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.divider} />
        <Link id="RouterNavLink2" className={classes.row} to="/updatePhone">
          <Grid container className={classes.row}>
            <Grid
              item
              xs={9}
              sm={10}
              md={11}
              lg={11}
              xl={11}
              className={classes.div}
            >
              <Typography className={classes.title}>Change Phone</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className={classes.root}
            >
              <ArrowRightIcon />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.divider} />
        <Typography className={classes.maintitle}>Feedback</Typography>

        <Divider className={classes.divider} />
        <Grid container onClick={handleClickOpen}>
          <Grid
            item
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            className={classes.div}
          >
            <Typography className={classes.title}>Rate this Website</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <ArrowRightIcon />
          </Grid>
        </Grid>
        <Box mt={matches ? 1.5 : 2.5}>
          <Divider />
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          hideBackdrop={true}
          maxWidth="sm"
        >
          <DialogContent className={classes.dialogC}>
            <div className={classes.toolbar}>
              <Box className={classes.box}>
                <img
                  src="/assets/images/rating.png"
                  className={classes.image}
                />
                <Typography className={classes.heading}>
                  Your opinion matter to us !
                </Typography>
                <Typography className={classes.subheading}>
                  If you're enjoying using our application, please give us
                  rating.
                </Typography>
                <Box mt={3}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className={classes.submit}
                  >
                    Play store
                  </Button>
                </Box>
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </main>
    </div>
  );
}
