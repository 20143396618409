//libraries
import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import {
  Hidden,
  CssBaseline,
  makeStyles,
  Paper,
  TextField,
  Button,
  Grid,
  Link,
  Snackbar,
  Box,
  Typography
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import Loader from '../../../Components/Loader/Loader'
//components
import Yup from '../../../Yup/YupAuthValidation.schemas'
import actions from '../../../Redux/User/User.actions'
import NavigationDrawer from '../../../Components/NavigationDrawer/NavigationDrawer'

//style
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '2%',
    [theme.breakpoints.between('xs')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '2.03rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '2.1rem'
    }
  },
  subtitle: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem'
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
      alignItems: 'left'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(4),
      alignItems: 'center'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(5),
      alignItems: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(6),
      alignItems: 'center'
    }
  },
  links: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.115rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.125rem'
    }
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'lg')]: {
      margin: theme.spacing(3, 0, 2)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(7, 0, 2)
    }
  },
  image: {
    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '90%',
      height: 'auto'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(14)
    }
  },
  paper: {
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(4, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    },
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(12, 14),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(18, 14),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(8, 15),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(18, 28),
      display: 'flex',
      flexDirection: 'column',
      elevation: '0',
      alignItems: 'center'
    }
  },
  boxMain: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '6%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: 6
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: 4
    }
  },
  textfields: {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  },
  forBox: {
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      textAlign: 'center'
    }
  },
  divBack: {
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
      marginTop: '1%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      textAlign: 'left',
      marginTop: '2%'
    }
  }
}))

export default function ForgotPassword (props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isForgotPassword = true
  const loading = useSelector(state => state.user.isFetching)
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const otpError = useSelector(state => state.user.errors.forgotPasswordError)

  const initialTimer = localStorage.getItem('secondPhone1') ?? 60
  const timeoutId1 = React.useRef(null)
  const [secondPhone, setSecondsPhone] = React.useState(initialTimer)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const countTimer1 = React.useCallback(() => {
    if (secondPhone <= 0) {
      localStorage.clear('secondPhone1')
    } else {
      setSecondsPhone(secondPhone - 1)
      localStorage.setItem('secondPhone1', secondPhone - 1)
    }
  }, [secondPhone])

  React.useEffect(() => {
    if (secondPhone > 0) {
      timeoutId1.current = window.setTimeout(countTimer1, 1000)
      // cleanup function
      return () => window.clearTimeout(timeoutId1.current)
    } else {
      localStorage.setItem('secondPhone1', secondPhone)
      setSecondsPhone(0)
      setIsButtonDisabled(false)
    }
  }, [secondPhone, countTimer1])

  const resetResendButton = () => {
    setSecondsPhone(60)
    setIsButtonDisabled(true)
  }
  useEffect(() => {
    dispatch(actions.clearError())
  }, [])

  const [openSnackOtp, setOpenSnackOtp] = useState(false)

  const handleErrorSnackOtp = () => {
    setOpenSnackOtp(true)
  }

  const [initialValues, setInitialValues] = useState({
    phone: ''
  })

  useEffect(() => {
    if (otpError) {
      handleErrorSnackOtp()
    }
  }, [otpError])

  const handleCloseSnackOtp = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackOtp(false)
  }

  return (
    <div>
      {isLoggedIn ? <NavigationDrawer /> : null}
      <Grid container component='main'>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          component={Paper}
          className={classes.root}
          elevation={0}
          square
        >
          <div className={classes.paper}>
            <Box mb='50px' mt='10px'>
              <Box className={classes.boxMain}>
                <Typography
                  component='h1'
                  variant='h5'
                  className={classes.title}
                >
                  Did you Forget your Password?
                </Typography>
              </Box>
              <Box mt={'2%'} className={classes.forBox}>
                <Typography
                  component='h3'
                  variant='h6'
                  color='secondary'
                  className={classes.subtitle}
                >
                  Enter your Email address or phone number You're using for your
                  account
                </Typography>
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.ForgotPasswordValidationSchema}
                onSubmit={values => {
                  //request otp action
                  dispatch(
                    actions.requestOTP(values.phone, isForgotPassword, props)
                  )
                  resetResendButton()
                }}
              >
                {({ errors, handleChange, touched }) => (
                  <Form className={classes.form}>
                    <Typography
                      variant='body2'
                      className={classes.textfields}
                      align='left'
                    >
                      Phone
                    </Typography>
                    <TextField
                      error={errors.phone && touched.phone}
                      helperText={
                        errors.phone && touched.phone ? errors.phone : null
                      }
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='phone'
                      placeholder='Enter your Phone'
                      name='phone'
                      autoComplete='phone'
                      autoFocus
                      inputProps={{
                        maxLength: 16
                      }}
                      onChange={handleChange}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      disabled={isButtonDisabled}
                    >
                      {loading ? (
                        <Loader />
                      ) : secondPhone === 0 ? (
                        '   Request Reset'
                      ) : (
                        `   Request Reset in ${secondPhone} second`
                      )}
                    </Button>
                    <div className={classes.divBack}>
                      <Typography className={classes.links}>
                        Back to
                        {isLoggedIn ? (
                          <Link
                            className={classes.links}
                            href='/reset_password'
                          >
                            {' Reset Password'}
                          </Link>
                        ) : (
                          <Link className={classes.links} href='/sign_in'>
                            {' Sign in'}
                          </Link>
                        )}
                      </Typography>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </div>
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={false}
            sm={false}
            md={false}
            lg={5}
            style={{ backgroundColor: '#6251FE', textAlign: 'center' }}
          >
            <Box mt={'25%'}>
              <img
                src='/assets/images/pasword.png'
                alt='picture'
                className={classes.image}
              />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      {otpError ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackOtp}
          autoHideDuration={2000}
          onClose={handleCloseSnackOtp}
        >
          <Alert onClose={handleCloseSnackOtp} severity={'error'}>
            {otpError}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
