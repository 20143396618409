//Libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Hidden,
  Zoom,
  Dialog,
  makeStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  DialogTitle,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Snackbar,
  Avatar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'

//Components
import actions from '../../Redux/User/User.actions'
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '85%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '75%'
    },
    [theme.breakpoints.only('md')]: {
      width: '65%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '35%'
    }
  },
  content: {
    flexGrow: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only('xs')]: {
      marginTop: '25%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '15%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '8%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '6%'
    }
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
    fontWeight: 'bold'
  },
  MuiCardContent: {
    width: '100%',
    display: 'center',
    textAlign: 'center'
  },
  input: {
    '&::placeholder': {
      paddingTop: '4.5%'
    }
  },
  image: {
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 98,
      height: 98
    },
    [theme.breakpoints.only('md')]: {
      width: 158,
      height: 158
    }
  },
  fab: {
    float: 'right',
    margin: theme.spacing(2, 0, 0),
    fontWeight: 'bold'
  },
  fabLeft: {
    float: 'right',
    margin: theme.spacing(2, 0, 0),
    fontWeight: 'bold',
    [theme.breakpoints.only('xs')]: {
      left: '65px'
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      left: '100px'
    }
  },
  fabPhoto: {
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      top: '20%',
      right: '37%'
    },
    [theme.breakpoints.only('sm')]: {
      top: '21%',
      right: '44%'
    },
    [theme.breakpoints.only('md')]: {
      top: '28%',
      right: '42%'
    },
    [theme.breakpoints.only('lg')]: {
      top: '30%',
      right: '43%'
    },
    [theme.breakpoints.only('xl')]: {
      top: '26%',
      right: '45%'
    }
  },
  large: {
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(13),
      fontSize: 24,
      height: theme.spacing(13)
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(15),
      fontSize: 26,
      height: theme.spacing(15)
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(16),
      fontSize: 28,
      height: theme.spacing(16)
    },
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(17),
      fontSize: 30,
      height: theme.spacing(17)
    },
    [theme.breakpoints.only('xl')]: {
      width: theme.spacing(17),
      fontSize: 38,
      height: theme.spacing(17)
    }
  },
  div: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  progress: {
    width: '50px',
    height: '50px'
  },
  text: {
    float: 'left'
  }
}))

const MyProfile = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.clearError())
  }, [])

  const [loading, setLoading] = useState(true)
  const [editDisabled, setEditDisabled] = useState(true)
  const [animateFab, setAnimateFab] = useState(0)
  const [animateFabPhoto, setAnimateFabPhoto] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const jwt = useSelector(state => state.user.jwt)
  const updateUserSuccess = useSelector(
    state => state.user.success.updateUserSuccess
  )
  const updateUserError = useSelector(
    state => state.user.errors.updateUserError
  )
  const [profile, setProfile] = useState({
    name: '',
    email:'',
    city: '',
    state: '',
    address: ''
  })

  const userData = useSelector(state => state.user)
  const onClickFab = () => {
    if (animateFab === 0) {
      setAnimateFab(1)
      setEditDisabled(false)
      setAnimateFabPhoto(true)
    } else if (animateFab === 1) {
      setAnimateFab(0)
      setEditDisabled(true)
      setOpenDialog(true)
    }
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleDialogYes = () => {
    let data = {
      name: profile.name,
      city: profile.city,
      email:profile.email,
      state: profile.state,
      address: profile.address
    }
    dispatch(actions.updateUser(data, jwt))
    setOpenDialog(false)
    setAnimateFabPhoto(false)
  }

  const handleDialogNo = () => {
    setOpenDialog(false)
    setAnimateFab(1)
    setEditDisabled(false)
  }

  useEffect(() => {
    if (userData) {
      setProfile(() => {
        return {
          name: userData.name,
          email:userData.email,
          city: userData.city,
          state: userData.state,
          address: userData.address
        }
      })
      setLoading(false)
    }
  }, [userData])

  const inputChangeHandler = event => {
    let input = event.target
    setProfile(employee => {
      return {
        ...profile,
        [input.id]: input.value
      }
    })
  }

  const fabs = [
    {
      id: 'cl-edit',
      color: 'primary',
      className: classes.fab,
      icon: <img src='/assets/images/edit.png' />,
      label: 'Edit'
    },
    {
      id: 'cl-save',
      color: 'primary',
      className: classes.fabLeft,
      icon: <img src='/assets/images/tick.png' />,
      label: 'Save Changes'
    }
  ]

  const [openSnackUpdateUser, setOpenSnackUpdateUser] = useState(false)

  const handleErrorSnackUpdateUser = () => {
    setOpenSnackUpdateUser(true)
  }

  const handleCloseSnackUpdateUser = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdateUser(false)
  }

  useEffect(() => {
    if (updateUserError && !updateUserSuccess) {
      handleErrorSnackUpdateUser()
    } else if (updateUserSuccess && !updateUserError) {
      handleErrorSnackUpdateUser()
    }
  }, [updateUserError, updateUserSuccess])

  const userName = useSelector(state => state.user.name)
  const name = userName ? userName : 'User'

  let myStr = name.toUpperCase()
  let first = myStr.match(/\b(\w)/g)
  const firstChar = first.slice(0, 3)

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          {loading ? (
            <div className={classes.div}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <Box className={classes.root}>
              <Grid container align='center'>
                <Grid item xs={12}>
                  <Avatar className={clsx(classes.large, classes.purple)}>
                    {firstChar}
                  </Avatar>
                </Grid>
              </Grid>

              <form noValidate>
                <Box mt={3} mb={'1%'}>
                  <Typography className={classes.text}>Full Name</Typography>
                </Box>
                <TextField
                  id='name'
                  name='name'
                  fullWidth
                  variant='outlined'
                  disabled={editDisabled}
                  defaultValue={userData.name}
                  onChange={inputChangeHandler}
                />
                <Box mt={3} mb={'1%'}>
                  <Typography className={classes.text}>
                    Email Address
                  </Typography>
                </Box>
                <TextField
                  id='email'
                  fullWidth
                  variant='outlined'
                  name='email'
                  autoComplete='email'
                  disabled={editDisabled}
                  defaultValue={userData.email}
                  onChange={inputChangeHandler}
                />
                <Box mt={3} mb={'1%'}>
                  <Typography className={classes.text}>Phone Number</Typography>
                </Box>
                <TextField
                  id='phone'
                  fullWidth
                  variant='outlined'
                  name='phone'
                  type='tel'
                  disabled={true}
                  value={userData.phone}
                />
                <Box mt={3} mb={'1%'}>
                  <Typography className={classes.text}>City</Typography>
                </Box>
                <TextField
                  id='city'
                  variant='outlined'
                  fullWidth
                  name='city'
                  autoComplete='city'
                  disabled={editDisabled}
                  defaultValue={userData.city ? userData.city : ''}
                  onChange={inputChangeHandler}
                  inputProps={{
                    maxLength: 50
                  }}
                />

                <Box mt={3} mb={'1%'}>
                  <Typography className={classes.text}>Address</Typography>
                </Box>
                <TextField
                  id='address'
                  name='address'
                  fullWidth
                  multiline={true}
                  rows={2}
                  variant='outlined'
                  defaultValue={userData.address ? userData.address : ''}
                  disabled={editDisabled}
                  onChange={inputChangeHandler}
                  inputProps={{
                    maxLength: 60
                  }}
                />
              </form>
              {fabs.map((fab, index) => (
                <Zoom key={fab.id} in={animateFab === index} timeout={400}>
                  <Button
                    aria-label={fab.label}
                    className={fab.className}
                    color={fab.color}
                    onClick={onClickFab}
                    variant='contained'
                  >
                    {fab.icon}
                    <Hidden xsDown>
                      <Box m='10px'>{fab.label}</Box>
                    </Hidden>
                  </Button>
                </Zoom>
              ))}
              <Dialog
                open={openDialog}
                onClose={onCloseDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                hideBackdrop={true}
              >
                <DialogTitle id='alert-dialog-title'>
                  {'Update Profile?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to update your profile?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    id='click-no-btn'
                    onClick={handleDialogNo}
                    variant='contained'
                  >
                    No
                  </Button>
                  <Button
                    id='click-yes-btn'
                    onClick={handleDialogYes}
                    color='primary'
                    autoFocus
                    variant='contained'
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </div>
      </main>
      {updateUserError || updateUserSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackUpdateUser}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdateUser}
        >
          <Alert
            onClose={handleCloseSnackUpdateUser}
            severity={updateUserError ? 'error' : 'success'}
          >
            {updateUserError ? updateUserError : updateUserSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
export default MyProfile
