//Libraries
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
  CssBaseline,
  TextField,
  Button
} from '@material-ui/core'

//Components
import Yup from '../Yup/YupInviteValidation.schemas'
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(4, 0, 0)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  image: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(12),
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(15)
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(18),
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '40%',
      height: '40%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(15)
    }
  },
  heading: {
    fontWeight: 'bold',
    paddingTop: '2%',
    [theme.breakpoints.only('xs')]: {
      fontSize: 18
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28
    }
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      padding: '1%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '60%',
      padding: '3%'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '30%',
      padding: '3%'
    }
  }
}))

export default function Invite () {
  const classes = useStyles()
  const theme = useTheme()
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'))
  const [initialvalues, setInitialValues] = useState({
    email: ''
  })

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} style={{ justifyContent: 'center' }}>
          <Box className={classes.box}>
            <img src='/assets/images/invite.png' className={classes.image} />
            <Typography className={classes.heading}>
              Invite your Friends and Family
            </Typography>
            <Formik
              initialValues={initialvalues}
              validationSchema={Yup.InviteValidationSchema}
              onSubmit={values => {
            
              }}
            >
              {({ errors, handleChange, touched }) => (
                <Form className={classes.form}>
                  <Box textAlign='center' mt={5}>
                    <Typography
                      color='secondary'
                      style={
                        mdMatch
                          ? { fontSize: 16, float: 'left' }
                          : { fontSize: 14, float: 'left' }
                      }
                    >
                      Email Address
                    </Typography>
                    <TextField
                      error={errors.email && touched.email}
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='email'
                      placeholder='Enter Email Address'
                      name='email'
                      autoComplete='email'
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 50
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      size='large'
                      className={classes.submit}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </div>
      </main>
    </div>
  )
}
