//#region checks if Push notification and service workers are supported by your browser

const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

//#endregion

//#region asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
//  Notification.requestPermission()

Notification.requestPermission().then(function (result) {})

if (
  Notification['permission'] !== 'granted' &&
  Notification['permission'] !== 'denied'
) {
  Notification.requestPermission().then(permission => {
    console.log(`Permission given is: ${permission}`)
  })
} else {
  console.log(`Notification permission is: ${Notification.permission}`)
}

// const askUserPermission = async () => {
//   return await Notification.requestPermission()
// }

//#endregion

//#region Register a Service Worker for Notifications

const registerServiceWorker = () => {
  return navigator.serviceWorker.register('/ServiceWorker.js')
}

//#endregion

//#region using the registered service worker creates a push notification subscription and returns it

const createNotificationSubscription = async key => {
  //wait for service worker installation to be ready
  // console.log(navigator.serviceWorker.ready)
  const serviceWorker = await navigator.serviceWorker.ready
  console.log('key =>>>>>>>>>>>>', key)
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: key
  })
}

//#endregion

//#region  returns the subscription if present or nothing

const getUserSubscription = () => {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription()
    })
    .then(function (pushSubscription) {
      return pushSubscription
    })
}

//#endregion

//#region  shows a notification

const showNotification = (notificationTitle, option) => {
  console.log('push notifications')
  const img = option.image
  const text = option.body
  const title = notificationTitle
  const options = {
    body: text,
    icon: option.icon,
    vibrate: [200, 100, 200],
    tag: 'new-product',
    image: img,
    badge: 'https://spyna.it/icons/android-icon-192x192.png',
    actions: [
      {
        action: 'Detail',
        title: 'View',
        icon: 'https://via.placeholder.com/128/ff0000'
      }
    ]
  }
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options)
  })
}

//#endregion

export {
  isPushNotificationSupported,
  // askUserPermission,
  registerServiceWorker,
  showNotification,
  createNotificationSubscription,
  getUserSubscription
}
