//Components
import orderActions from './Order.actions'
import moment from 'moment'
//Order State/Store
const initialState = {
  socket: null,
  location: null,
  loading: false,
  order: {
    tripStartLocation: {
      formattedAddress: null,
      city: null,
      zipcode: null,
      lat: null,
      long: null,
      floor: '',
      isFreightElevatorAvailable: ''
    },
    tripEndLocation: {
      formattedAddress: null,
      city: null,
      zipcode: null,
      lat: null,
      long: null,
      floor: '',
      isFreightElevatorAvailable: ''
    },

    isFreightInsured: '',
    description: '',
    rideType: '',
    date: '',
    time: '',
    pickUpDateAndTime: '',
    noOfHelpersRequired: ''
  },

  tripStartLocationErrors: {
    isFormattedAddressError: false,
    formattedAddressError: null,
    isCityError: false,
    cityError: null,
    isZipcodeError: false,
    zipcodeError: null,
    isLatError: false,
    latError: null,
    islongError: false,
    longError: null,
    isFloorError: false,
    floorError: null,
    isFreightElevatorAvailableError: false,
    freightElevatorAvailableError: null,
    isFormValid: false
  },
  tripEndLocationErrors: {
    isFormattedAddressError: false,
    formattedAddressError: null,
    isCityError: false,
    cityError: null,
    isZipcodeError: false,
    zipcodeError: null,
    isLatError: false,
    latError: null,
    islongError: false,
    longError: null,
    isFloorError: false,
    floorError: null,
    isFreightElevatorAvailableError: false,
    freightElevatorAvailableError: null,
    isFormValid: false
  },
  orderDetailsErrors: {
    isRideTypeError: false,
    rideTypeError: null,
    isNoOfHelpersRequiredError: false,
    noOfHelpersRequiredError: null,
    isFreightInsuredError: false,
    freightInsuredError: null,

    isFormValid: false
  },
  clearNow: false,
  bookNow: true,
  rating: 0,
  comment: '',
  success: {
    placeOrderSuccess: null,
    orderFeedbackSuccess: null,
    priceCalculationSuccess: null
  },
  errors: {
    placeOrderError: null,
    orderFeedbackError: null,
    priceCalculationError: null
  },
  orderId: null,
  orderDetail: null,
  price: {
    baseFare: null,
    finalCongestionCharges: null,
    finalCostFlightOfStair: null,
    finalCostOfHelpers: null,
    finalCostPerMileCharges: null,
    finalCostPerMintCharges: null,
    finalInsurranceCharges: null,
    finalTollCharges: null,
    finalTypeFactoreCharges: null,
    maximumPrice: null,
    minimumPrice: null,
    bookingFee: null,
    driverFee: null,

    rideDistance: null,
    timeInRide: null
  },
  allOrders: null,
  pastOrders: null,
  activeOrders: [],
  pendingOrders: [],
  deliveredOrders: [],
  cancelledOrders: []
}

// Order Reducer
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    //#region update start and end location
    case orderActions.UPDATE_START_LOCATION:
      return {
        ...state,
        order: {
          ...state.order,
          tripStartLocation: {
            ...state.order.tripStartLocation,
            formattedAddress: action.payload.formattedAddress,
            city: action.payload.city,
            zipcode: action.payload.zipcode,
            lat: action.payload.lat,
            long: action.payload.long,
            floor: action.payload.floor,
            isFreightElevatorAvailable:
              action.payload.isFreightElevatorAvailable
          }
        }
      }

    case orderActions.UPDATE_END_LOCATION:
      return {
        ...state,
        order: {
          ...state.order,
          tripEndLocation: {
            formattedAddress: action.payload.formattedAddress,
            city: action.payload.city,
            zipcode: action.payload.zipcode,
            lat: action.payload.lat,
            long: action.payload.long,
            floor: action.payload.floor,
            isFreightElevatorAvailable:
              action.payload.isFreightElevatorAvailable
          }
        }
      }

    case orderActions.UPDATE_ORDER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,

          noOfHelpersRequired: action.payload.noOfHelpersRequired,
          isFreightInsured: action.payload.isFreightInsured,

          description: action.payload.description,
          rideType: action.payload.rideType,

          pickUpDateAndTime: action.payload.pickUpDateAndTime
        }
      }
    //#endregion

    //#region Error Handling
    case orderActions.SET_LOCATION_FORM_ERRORS:
      return {
        ...state,
        tripStartLocationErrors: action.payload
      }

    case orderActions.SET_DROPOFF_FORM_ERRORS:
      return {
        ...state,
        tripEndLocationErrors: action.payload
      }

    case orderActions.SET_ORDER_DETAIL_FORM_ERRORS:
      return {
        ...state,
        orderDetailsErrors: action.payload
      }
    //#endregion

    //#region Place Order
    case orderActions.PLACE_ORDER:
      return {
        ...state,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case orderActions.PLACE_ORDER_DATA:
      return {
        ...state,
        order: action.payload.placeOrderObj
      }
    case orderActions.PAYMENT_REQUEST:

    case orderActions.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        orderId: null,
        bookNow: true,
        clearNow: false,
        order: {
          tripStartLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: '',
            isFreightElevatorAvailable: ''
          },
          tripEndLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: '',
            isFreightElevatorAvailable: ''
          },

          description: ' ',
          isFreightInsured: null,
          rideType: '',
          pickUpDateAndTime: '',
          noOfHelpersRequired: ''
        },
        price: {
          baseFare: null,
          bookingFee: null,
          finalCongestionCharges: null,
          finalCostFlightOfStair: null,
          finalCostOfHelpers: null,
          finalCostPerMileCharges: null,
          finalCostPerMintCharges: null,
          finalInsurranceCharges: null,
          finalTollCharges: null,
          finalTypeFactoreCharges: null,
          maximumPrice: null,
          minimumPrice: null,
          bookingFee: null,
          driverFee: null,

          rideDistance: null,
          timeInRide: null
        },
        errors: {
          ...state.errors,
          placeOrderError: null,
          priceCalculationError: null
        },
        success: {
          ...state.success,
          priceCalculationSuccess: null,
          placeOrderSuccess: action.payload.placeOrderSuccessObj
        }
      }

    case orderActions.SET_PLACE_ORDER_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          placeOrderError: action.payload.placeOrderError
        },
        success: { ...state.success, placeOrderSuccess: null }
      }
    //#endregion

    //#region order Feedback
    case orderActions.ORDER_FEEDBACK_SUCCESS:
      return {
        ...state,
        rating: action.payload.values.value,
        comment: action.payload.values.comment,
        errors: { ...state.errors, orderFeedbackError: null },
        success: {
          ...state.success,
          orderFeedbackSuccess: action.payload.orderFeedbackObj
        }
      }

    case orderActions.ORDER_FEEDBACK_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          orderFeedbackError: action.payload.error
        },
        success: { ...state.success, orderFeedbackSuccess: null }
      }
    //#endregion

    case orderActions.SET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload
      }

    case orderActions.GET_ORDER_DETAILS:
      return {
        ...state,
        orderDetail: action.payload
      }

    case orderActions.UPDATE_PENDING_ORDER_REQUEST:
      return {
        ...state,
        bookNow: true,
        clearNow: true,
        orderId: null,
        order: {
          ...state.order,
          tripStartLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },
          tripEndLocation: {
            formattedAddress: null,
            city: null,
            zipcode: null,
            lat: null,
            long: null,
            floor: null,
            isFreightElevatorAvailable: null
          },

          isFreightInsured: null,

          description: null,
          rideType: null,
          date: null,
          time: null,
          noOfHelpersRequired: null
        },
        price: {
          baseFare: null,
          finalCongestionCharges: null,
          finalCostFlightOfStair: null,
          finalCostOfHelpers: null,
          finalCostPerMileCharges: null,
          finalCostPerMintCharges: null,
          finalInsurranceCharges: null,
          finalTollCharges: null,
          finalTypeFactoreCharges: null,
          maximumPrice: null,
          minimumPrice: null,
          bookingFee: null,
          driverFee: null,

          rideDistance: null,
          timeInRide: null
        }
      }
    case orderActions.UPDATE_PENDING_ORDER_DETAILS:
      let dateAndTimePart = action.payload.pickUpDateAndTime
        .slice(0, -1)
        .split('T')
      return {
        ...state,
        bookNow: false,
        clearNow: true,
        orderId: action.payload._id,
        order: {
          ...state.order,
          tripStartLocation: {
            ...state.order.tripStartLocation,
            formattedAddress: action.payload.tripStartLocation.formattedAddress,
            city: action.payload.tripStartLocation.city,
            zipcode: action.payload.tripStartLocation.zipcode,
            lat: action.payload.tripStartLocation.lat,
            long: action.payload.tripStartLocation.long,
            floor: action.payload.tripStartLocation.floor,
            isFreightElevatorAvailable:
              action.payload.tripStartLocation.isFreightElevatorAvailable
          },
          tripEndLocation: {
            ...state.order.tripEndLocation,
            formattedAddress: action.payload.tripEndLocation.formattedAddress,
            city: action.payload.tripEndLocation.city,
            zipcode: action.payload.tripEndLocation.zipcode,
            lat: action.payload.tripEndLocation.lat,
            long: action.payload.tripEndLocation.long,
            floor: action.payload.tripEndLocation.floor,
            isFreightElevatorAvailable:
              action.payload.tripEndLocation.isFreightElevatorAvailable
          },

          isFreightInsured: action.payload.isFreightInsured,

          description: action.payload.description,
          rideType: action.payload.rideType,
          date: dateAndTimePart[0],
          time: dateAndTimePart[1],
          noOfHelpersRequired: action.payload.noOfHelpersRequired
        },
        price: {
          baseFare: action.payload.estimatedPrice.baseFare,
          finalCongestionCharges:
            action.payload.estimatedPrice.finalCongestionCharges,
          finalCostFlightOfStair:
            action.payload.estimatedPrice.finalCostFlightOfStair,
          finalCostOfHelpers: action.payload.estimatedPrice.finalCostOfHelpers,
          finalCostPerMileCharges:
            action.payload.estimatedPrice.finalCostPerMileCharges,
          finalCostPerMintCharges:
            action.payload.estimatedPrice.finalCostPerMintCharges,
          finalInsurranceCharges:
            action.payload.estimatedPrice.finalInsurranceCharges,
          finalTollCharges: action.payload.estimatedPrice.finalTollCharges,
          finalTypeFactoreCharges:
            action.payload.estimatedPrice.finalTypeFactoreCharges,
          maximumPrice: action.payload.estimatedPrice.maximumPrice,
          minimumPrice: action.payload.estimatedPrice.minimumPrice,
          bookingFee: action.payload.estimatedPrice.serviseCharger,
          driverFee: action.payload.estimatedPrice.driverCharger,

          rideDistance: action.payload.estimatedPrice.rideDistance,
          timeInRide: action.payload.estimatedPrice.timeInRide
        }
      }

    case orderActions.PRICE_ORDER_CALCULATION:
      return {
        ...state,
        orderId: action.payload.orderId,
        loading: false,
        price: {
          baseFare: action.payload.priceEstimater.baseFare,
          finalCongestionCharges:
            action.payload.priceEstimater.finalCongestionCharges,
          finalCostFlightOfStair:
            action.payload.priceEstimater.finalCostFlightOfStair,
          finalCostOfHelpers: action.payload.priceEstimater.finalCostOfHelpers,
          finalCostPerMileCharges:
            action.payload.priceEstimater.finalCostPerMileCharges,
          finalCostPerMintCharges:
            action.payload.priceEstimater.finalCostPerMintCharges,
          finalInsurranceCharges:
            action.payload.priceEstimater.finalInsurranceCharges,
          finalTollCharges: action.payload.priceEstimater.finalTollCharges,
          finalTypeFactoreCharges:
            action.payload.priceEstimater.finalTypeFactoreCharges,
          maximumPrice: action.payload.priceEstimater.maximumPrice,
          minimumPrice: action.payload.priceEstimater.minimumPrice,
          bookingFee: action.payload.priceEstimater.serviseCharger,
          driverFee: action.payload.priceEstimater.driverCharger,

          rideDistance: action.payload.priceEstimater.rideDistance,
          timeInRide: action.payload.priceEstimater.timeInRide
        }
      }

    case orderActions.UPDATE_PRICE_ORDER_CALCULATION:
      return {
        ...state,
        orderId: action.payload.orderId,
        loading: false,
        price: {
          baseFare: action.payload.priceEstimater.baseFare,
          finalCongestionCharges:
            action.payload.priceEstimater.finalCongestionCharges,
          finalCostFlightOfStair:
            action.payload.priceEstimater.finalCostFlightOfStair,
          finalCostOfHelpers: action.payload.priceEstimater.finalCostOfHelpers,
          finalCostPerMileCharges:
            action.payload.priceEstimater.finalCostPerMileCharges,
          finalCostPerMintCharges:
            action.payload.priceEstimater.finalCostPerMintCharges,
          finalInsurranceCharges:
            action.payload.priceEstimater.finalInsurranceCharges,
          finalTollCharges: action.payload.priceEstimater.finalTollCharges,
          finalTypeFactoreCharges:
            action.payload.priceEstimater.finalTypeFactoreCharges,
          maximumPrice: action.payload.priceEstimater.maximumPrice,
          minimumPrice: action.payload.priceEstimater.minimumPrice,
          bookingFee: action.payload.priceEstimater.serviseCharger,
          driverFee: action.payload.priceEstimater.driverCharger,

          rideDistance: action.payload.priceEstimater.rideDistance,
          timeInRide: action.payload.priceEstimater.timeInRide
        }
      }

    case orderActions.PRICE_CALCULATION_SUCCESS:
      return {
        ...state,
        bookNow: false,
        errors: {
          ...state.errors,
          priceCalculationError: null,
          placeOrderError: null
        },
        success: {
          ...state.success,
          priceCalculationSuccess: action.payload.priceOrderSuccessObj
        }
      }

    case orderActions.PRICE_CALCULATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          priceCalculationError: action.payload.priceOrderError
        },
        success: { ...state.success, priceCalculationSuccess: null }
      }
    //#endregion

    //#region get orders lists and details
    case orderActions.GET_ACTIVE_ORDERS:
      return {
        ...state,
        activeOrders: action.payload
      }

    case orderActions.GET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.payload
      }

    case orderActions.GET_DELIVERED_ORDERS:
      return {
        ...state,
        deliveredOrders: action.payload
      }

    case orderActions.GET_CANCELLED_ORDERS:
      return {
        ...state,
        cancelledOrders: action.payload
      }

    case orderActions.GET_PAST_ORDERS:
      return {
        ...state,
        pastOrders: action.payload
      }

    case orderActions.SET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload
      }

    case orderActions.GET_ORDER_DETAILS:
      return {
        ...state,
        orderDetail: action.payload
      }
    //#endregion

    //Socket Connection
    case orderActions.SOCKET_CONNECTION: {
      return {
        ...state,
        socket: action.payload
      }
    }

    //Driver Live Location
    case orderActions.DRIVER_LIVE_LOCATION: {
      return {
        ...state,
        location: action.payload
      }
    }

    //set Loading
    case orderActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    //Clear Error
    case orderActions.CLEAR_ERROR:
      return {
        ...state,
        loading:false,
        errors: {
          ...state.errors,
          placeOrderError: null,
          orderFeedbackError: null,
          priceCalculationError: null
        },
        success: {
          ...state.success,
          placeOrderSuccess: null,
          orderFeedbackSuccess: null,
          priceCalculationSuccess: null
        }
      }

    //Clear Forms
    case orderActions.CLEAR_FORMS:
      return initialState

    default:
      return state
  }
}

export default orderReducer
