//Libraries
import React, { useState, useEffect } from 'react'
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Box,
  Snackbar,
  Link,
  useMediaQuery,
  Paper,
  Hidden,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Alert as MuiAlert } from '@material-ui/lab'
import { Navigate } from 'react-router-dom'

//Components
import actions from '../../../Redux/User/User.actions'
import Yup from '../../../Yup/YupAuthValidation.schemas'
import Loader from '../../../Components/Loader/Loader'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  city: ''
}

function Alert (props) {
  return <MuiAlert elevation={2} variant='filled' {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(3, 3)
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      margin: theme.spacing(6, 15)
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  captions: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.37rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.37rem'
    }
  },
  image: {
    width: '80%',
    height: 'auto'
  },
  links: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.155rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.125rem'
    }
  },
  form: {
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(5)
    }
  },
  textfields: {
    marginTop: '2%',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'md')]: {
      margin: theme.spacing(3, 0, 4)
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(3, 0, 2)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(5, 0, 2)
    }
  },
  signBox: {
    marginBottom: '2%',
    [theme.breakpoints.between('xs', 'lg')]: {
      marginTop: '7%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '9%'
    }
  },
  divBox: {
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
      marginTop: '2%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      textAlign: 'left',
      marginTop: '1%'
    }
  }
}))

export default function SignUp (props) {
  const classes = useStyles()
  const loading = useSelector(state => state.user.isFetching)
  const dispatch = useDispatch()

  useEffect(() => {
 
    dispatch(actions.clearError())
  }, [])

  const [open, setOpen] = useState(false)

  const signUpError = useSelector(state => state.user.errors.signUpError)

  useEffect(() => {
    if (signUpError) {
      handleErrorSnack()
    }
  }, [signUpError])

  const handleErrorSnack = () => {
    setOpen(true)
  }

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const initialTimer = 60
  const [seconds, setSeconds] = React.useState(initialTimer)
  localStorage.setItem('seconds', seconds)

  const isOTPRecieved = useSelector(state => state.user.isOTPRecieved)
  const isJWTRecieved = useSelector(state => state.user.isJWTRecieved)
  const themeType = useSelector(state => state.themes.theme)
 
  const isLoggedIn = useSelector(
    state => state.user.isLoggedIn && state.user.jwt !== null
  )
  if (isLoggedIn) return <Navigate to='/Home' />
  if (isOTPRecieved) return <Navigate to='/otp' />
  if (isOTPRecieved && isJWTRecieved) return <Navigate to='/set_password' />

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <Typography variant='h1' color='primary'>
            <b>LOGO</b>
          </Typography>
          <Box className={classes.signBox}>
            <Typography variant='h1'>
              <b>Sign up.</b>
            </Typography>
          </Box>
          <Typography className={classes.captions} color='secondary'>
            Get Register with us to continue
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.SignUpValidationSchema}
            onSubmit={values => {
            
              dispatch(actions.signUp(values, props))
            }}
          >
            {({ errors, handleChange, touched }) => (
              <Form className={classes.form}>
                <Typography variant='body2' className={classes.textfields}>
                  Name
                </Typography>
                <TextField
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : null}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  id='name'
                  name='name'
                  autoComplete='name'
                  placeholder='Enter your Name'
                  autoFocus
                  inputProps={{
                    maxLength: 20
                  }}
                  onChange={handleChange}
                />
                <Typography variant='body2' className={classes.textfields}>
                  Email
                </Typography>
                <TextField
                  error={errors.email && touched.email}
                  helperText={
                    errors.email && touched.email ? errors.email : null
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  placeholder='Enter your Email'
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50
                  }}
                />

                <Typography variant='body2' className={classes.textfields}>
                  Phone
                </Typography>
                <TextField
                  error={errors.phone && touched.phone}
                  helperText={
                    errors.phone && touched.phone ? errors.phone : null
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  placeholder='Enter your Phone'
                  id='phone'
                  name='phone'
                  autoComplete='phone'
                  type='tel'
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 16
                  }}
                />

                <Typography variant='body2' className={classes.textfields}>
                  City
                </Typography>
                <TextField
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city ? errors.city : null}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  placeholder='Enter your City'
                  id='city'
                  name='city'
                  autoComplete='city'
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50
                  }}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  className={classes.submit}
                >
                  {loading ? <Loader /> : 'Sign Up'}
                </Button>
              </Form>
            )}
          </Formik>
          <div className={classes.divBox}>
            <Typography className={classes.links}>
              Already have an account?
              <Link href='/sign_in'>{' Sign in'}</Link>
            </Typography>
          </div>
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          xs={false}
          sm={false}
          md={false}
          lg={6}
          xl={6}
          style={{ backgroundColor: '#6251FE', textAlign: 'center' }}
        >
          {themeType == 'dark' ? (
            <img
              src='/assets/images/signupd.png'
              alt='picture'
              className={classes.image}
            />
          ) : (
            <img
              src='/assets/images/signup.png'
              alt='picture'
              className={classes.image}
            />
          )}
        </Grid>
      </Hidden>
      {signUpError ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity='error'>
            {signUpError}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  )
}
