//Libraries
import React from 'react'
import {
  Typography,
  Box,
  CssBaseline,
  Button,
  makeStyles,
  useTheme
} from '@material-ui/core'
import OtpInput from 'react-otp-input'

//Components
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'

//Style Sheet
import './OTP.css'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only('xs')]: {
      marginTop: '18%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '15%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '16%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '6%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '6%'
    }
  },
  mainbox: {
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '80%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '40%',
      padding: '3%'
    }
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: theme.spacing(2, 0, 0),
      width: '60%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      margin: theme.spacing(2, 0, 0),
      width: '40%'
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(4, 0, 0),
      width: '60%'
    }
  },
  image: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.between('xs', 'md')]: {
      width: '15%',
      height: '15%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '13%',
      height: '13%'
    }
  },
  text: {
    fontWeight: 'bold',
    paddingTop: '3%',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 18
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 32.4
    }
  }
}))

export default function PaymentVerification () {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.mainbox}>
            <img
              src='/assets/images/otpPayment.png'
              className={classes.image}
            />
            <Typography className={classes.text}>
              Enter Code You Received on Your Phone
            </Typography>
            <Box mt={'1%'} />
            <Typography component='h3' variant='h6' color='secondary'>
              Enter the verification code you have received on your Phone
            </Typography>
            <OtpInput
              autoFocus
              numInputs={6}
              isInputNum={false}
              containerStyle='otpContainer'
              inputStyle='otpInput'
              focusStyles='focusStyle'
            />
            <Box>
              <Button
                fullWidth
                color='primary'
                size='large'
                variant='contained'
                className={classes.submit}
              >
                Confirm
              </Button>
            </Box>
            <Button
              fullWidth
              style={{ backgroundColor: '#F5F6FE' }}
              size='large'
              variant='contained'
              className={classes.submit}
            >
              Resend Code
            </Button>
          </Box>
        </div>
      </main>
    </div>
  )
}
