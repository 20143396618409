import * as yup from 'yup'

const schemata = {
  InviteValidationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email format.')
      .required('This field is required.')
  })
}

export default schemata
