//Libraries
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

//Components
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword.page'
import SignUp from '../Pages/Auth/SignUp/SignUp.page'
import SignIn from '../Pages/Auth/SignIn/SignIn.page'
import OTP from '../Pages/Auth/OTP/OTP.page'
import Home from '../Pages/Home.page'
import Orders from '../Pages/Orders/Orders.page'
import DriverRating from '../Pages/Ratings/DriverRating.page'
import OrderSummary from '../Pages/OrderSummary.page'
import CreatePassword from '../Pages/Settings/CreatePassword.page'
import Setting from '../Pages/Settings/Settings.page'
import Invite from '../Pages/Invite.page'
import ContactUs from '../Pages/ContactUS.page'
import Payment from '../Pages/PaymentMethods/Payment.page'
import MyProfile from '../Pages/MyProfile/MyProfile'
import Help from '../Pages/Help/Help.page'
import Notifications from '../Pages/Notifications.page'
import PaymentVerification from '../Pages/PaymentVerification/paymentVerification.page'
import UpdatePhone from '../Pages/Auth/UpdatePhone/UpdatePhone'
import ResetPassword from '../Pages/Settings/Resetpassword/ResetPassword.page'
import CheckNotification from '../Pages/checkNotification'
import PhoneOTP from '../Pages/Auth/OTP/PhoneOTP.page'
import InternetConnection from '../Components/InternetConnection.component'
import NotFound from '../Pages/NotFound.page'
import Chat from '../Pages/Chat'

const AppRoutes = () => {
  let routes
  const isOTPRecieved = useSelector(state => state.user.isOTPRecieved)
  const isJWTRecieved = useSelector(state => state.user.isJWTRecieved)
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)

  if (isLoggedIn) {
    // After Sign-In Routes
    routes = (
      <div>
        <InternetConnection />
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/order_details/:orderId' element={<OrderSummary />} />
          <Route path='/chat/:orderId' element={<Chat />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/settings' element={<Setting />} />
          <Route path='/help' element={<Help />} />
          <Route path='/profile' element={<MyProfile />} />
          <Route path='/invite' element={<Invite />} />
          <Route path='/about_us' render={() => <div>About Us</div>} />
          <Route path='/contact_us' element={<ContactUs />} />
          <Route path='/reset_password' element={<ResetPassword />} />
          <Route path='/otp' element={<OTP />} />
          <Route path='/check_Notification' element={<CheckNotification />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/driver_rating' element={<DriverRating />} />
          <Route path='/order_summary' element={<OrderSummary />} />
          <Route path='/payment/:orderId' element={<Payment />} />
          <Route exact path='/forgot_password' element={<ForgotPassword />} />
          <Route
            path='/payment_verification'
            element={<PaymentVerification />}
          />
          <Route path='/updatePhone' element={<UpdatePhone />} />
          <Route path='/phone_otp' element={<PhoneOTP />} />
        </Routes>
      </div>
    )
  } else {
    //Auth Routes
    routes = (
      <div>
        <InternetConnection />
        <Routes>
          <Route path='*' element={<SignIn />} />
          <Route exact path='/' element={<SignIn />} />
          <Route exact path='/sign_in' element={<SignIn />} />
          <Route exact path='/forgot_password' element={<ForgotPassword />} />
          <Route exact path='/sign_up' element={<SignUp />} />
          <Route
            path='/otp'
            element={!isOTPRecieved ? <Navigate to='/' /> : <OTP />}
          />
          <Route
            path='/set_password'
            element={!isJWTRecieved ? <Navigate to='/' /> : <CreatePassword />}
          />
        </Routes>
      </div>
    )
  }

  return routes
}
export default AppRoutes

