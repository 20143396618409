//Libraries
import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  NotificationsRounded as NotificationIcon,
  MoreVert as MenuIcon,
} from "@mui/icons-material";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
  },
  pos: {
    marginBottom: 12,
  },
  root1: {
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
    borderRadius: 8,
  },
  text: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: 16,
    },
  },
}));

const NotificationItem = ({ description, time }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdmatches = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isRead, setIsRead] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = () => {
    if (isRead == false) {
      setIsRead(true);
    } else {
      setIsRead(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box mb="20px" mt="20px">
      <Card className={classes.root1} elevation={2}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={mdmatches ? 2 : 1}
          >
            <Grid item xs={2} sm={1} md={1} lg={1} xl={1} align="center">
              <NotificationIcon
                size="large"
                style={isRead ? { color: "black" } : { color: "red" }}
              />
            </Grid>
            <Grid item xs={8} sm={10} md={10} lg={10} xl={10}>
              <Typography
                component="h1"
                variant="body1"
                className={classes.text}
              >
                {description}
              </Typography>
              <Typography
                className={classes.text}
                variant="body1"
                color="textSecondary"
              >
                {time}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} alignItems="flex-end">
              <CardActions>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleChange} className={classes.text}>
                    {isRead ? "Mark as Unread" : "Mark as Read"}
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.text}>
                    Delete
                  </MenuItem>
                </Menu>
              </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotificationItem;
