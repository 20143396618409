//Libraries
import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  makeStyles,
  CssBaseline,
  CardContent,
  Button,
  Typography,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import Loader from '../../../Components/Loader/Loader'
//Components
import actions from '../../../Redux/User/User.actions'
import Yup from '../../../Yup/YupAuthValidation.schemas'
import NavigationDrawer from '../../../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  submit: {
    marginTop: '4%',
    [theme.breakpoints.only('lg')]: {
      fontWeight: 'bold'
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(1, 0, 0),
      fontWeight: 'bold'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  image: {
    height: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(12),
      width: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(15),
      width: '50%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(18),
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '70%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(15),
      width: '80%'
    }
  },
  heading: {
    fontWeight: 'bold',
    paddingTop: '2%',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28
    }
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      padding: '1%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '60%',
      padding: '3%'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '30%',
      padding: '3%'
    }
  }
}))

export default function UpdatePhone (props) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const otpError = useSelector(state => state.user.errors.otpPhoneError)
  const loading = useSelector(state => state.user.isFetching)
  const initialTimer = localStorage.getItem('updatePhone') ?? 60
  const timeoutId1 = React.useRef(null)
  const [secondPhone, setSecondsPhone] = React.useState(initialTimer)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  useEffect(() => {
    dispatch(actions.clearError())
  }, [])

  const isForgotPassword = false
  const countTimer1 = React.useCallback(() => {
    if (secondPhone <= 0) {
      localStorage.clear('updatePhone')
      setIsButtonDisabled(false)
    } else {
      setSecondsPhone(secondPhone - 1)
      localStorage.setItem('updatePhone', secondPhone - 1)
    }
  }, [secondPhone])

  React.useEffect(() => {
    if (secondPhone > 0) {
      timeoutId1.current = window.setTimeout(countTimer1, 1000)
      // cleanup function
      return () => window.clearTimeout(timeoutId1.current)
    } else {
      localStorage.setItem('updatePhone', secondPhone)
      setSecondsPhone(0)
      setIsButtonDisabled(false)
    }
  }, [secondPhone, countTimer1])

  const resetResendButton = () => {
    setSecondsPhone(60)
    setIsButtonDisabled(true)
  }

  const updatePhoneUserSuccess = useSelector(
    state => state.user.success.updatePhoneUserSuccess
  )
  const updatePhoneUserError = useSelector(
    state => state.user.errors.updatePhoneUserError
  )

  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const jwt = useSelector(state => state.user.jwt)
  const [initialValues, setInitialVales] = useState({
    phone: ''
  })
  const [openSnackOtp, setOpenSnackOtp] = useState(false)
  const handleErrorSnackOtp = () => {
    setOpenSnackOtp(true)
  }
  const handleCloseSnackOtp = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackOtp(false)
  }

  const [openSnackUpdatePhone, setOpenSnackUpdatePhone] = useState(false)
  const handleErrorSnackUpdatePhone = () => {
    setOpenSnackUpdatePhone(true)
  }
  const handleCloseSnackUpdatePhone = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdatePhone(false)
  }

  useEffect(() => {
    if (updatePhoneUserError && !updatePhoneUserSuccess) {
      handleErrorSnackUpdatePhone()
    } else if (updatePhoneUserSuccess && !updatePhoneUserError) {
      handleErrorSnackUpdatePhone()
    }
  }, [updatePhoneUserError, updatePhoneUserSuccess])

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.box}>
            <img
              src='/assets/images/changePhone.png'
              className={classes.image}
            />
            <CardContent>
              <Typography
                component='h1'
                variant={matches ? 'subtitle1' : 'h5'}
                align='center'
              >
                Enter Your new Phone Number
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.OTPUpdatePhoneValidationSchema}
                onSubmit={values => {
                  dispatch(
                    actions.requestOTPLoginUser(values.phone, jwt, props)
                  )
                  resetResendButton()
                }}
              >
                {({ errors, handleChange, touched }) => (
                  <Form className={classes.form}>
                    <TextField
                      error={errors.phone && touched.phone}
                      helperText={
                        errors.phone && touched.phone ? errors.phone : null
                      }
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='phone'
                      label='Enter your new Phone number'
                      name='phone'
                      autoComplete='phone'
                      type='tel'
                      onChange={handleChange}
                    />
                    <Button
                      type='submit'
                      fullWidth
                      color='primary'
                      size='large'
                      variant='contained'
                      id='update-phone'
                      className={classes.submit}
                      disabled={isButtonDisabled}
                    >
                      {loading ? (
                        <Loader />
                      ) : secondPhone === 0 ? (
                        'Request OTP'
                      ) : (
                        `Request OTP in ${secondPhone} second`
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Box>
        </div>
      </main>
      {updatePhoneUserError || updatePhoneUserSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackUpdatePhone}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdatePhone}
        >
          <Alert
            onClose={handleCloseSnackUpdatePhone}
            severity={updatePhoneUserError ? 'error' : 'success'}
          >
            {updatePhoneUserError
              ? updatePhoneUserError
              : updatePhoneUserSuccess}
          </Alert>
        </Snackbar>
      ) : null}
      {otpError ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackOtp}
          autoHideDuration={2000}
          onClose={handleCloseSnackOtp}
        >
          <Alert onClose={handleCloseSnackOtp} severity='error'>
            {otpError}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
