import { createTheme, ThemeProvider } from '@material-ui/core'
import { CssBaseline } from '@material-ui/core'
import Routes from './Routes/routes'
import { useSelector } from 'react-redux'

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import history from './Utils/history'

function App () {
  const themeType = useSelector(state => state.themes.theme)

  let theme = createTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#6251FE'
      },
      secondary: {
        main: '#B9B6B6'
      },
      subSecondary: {
        main: '#008000'
      }
    },
    typography: {
      fontFamily: ['Poppins'].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500
    }
  })

  theme.typography.h1 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.25rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.25rem'
    }
  }
  theme.typography.caption = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem'
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='App'>
        <HistoryRouter history={history}>
          <Routes />
        </HistoryRouter>
      </div>
    </ThemeProvider>
  )
}

export default App
