//Libraries
import React from "react";
import {
  makeStyles,
  CssBaseline,
  Typography,
  Box,
  Grid,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

//Icons
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

//Style
const useStyles = makeStyles((theme) => ({
  root1: {
    width: "70%",
    boxShadow: "0 0 6px rgba(34, 35, 58, 0.2)",
  },
  title: {
    marginBottom: "4%",
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.125rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.95rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "2.125rem",
    },
  },
  content: {
    flexGrow: 1,
  },
  textFields: {
    width: "20vw",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  submit: {
    margin: theme.spacing(2, 0, 4),
    fontWeight: "bold",
  },
  MuiCardContent: {
    width: "100%",
    display: "center",
    textAlign: "center",
  },
  root: {
    display: "flex",
  },
  image: {
    // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  heading: {
    color: "primary",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.25rem",
    },
  },
  subheading: {
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.25rem",
    },
  },
  boxes: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "95%",
      padding: "1%",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: "60%",
      padding: "1%",
    },
  },
  div: {
    marginTop: "1%",
    marginBottom: "1%",
  },
}));

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    textAlign: "left",
  },
}))(MuiAccordionDetails);

export default function Terms() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const options = [
    {
      id: "panel1",
      question: "How do i provide rating to driver ?",
      answer: "You can provide the rating for the order using the app",
    },
    {
      id: "panel2",
      question: "How to update my profile ?",
      answer:
        "The User Profile functionality allows you to make changes for all general info other than authentication info.",
    },
    {
      id: "panel3",
      question: "How to delete my account ?",
      answer:
        "Go to Settings  then in the account settings section click the Delete Account Button and after confirmation your account will be deleted.",
    },
    {
      id: "panel4",
      question: "How do i set forward complaint ?",
      answer:
        "In the Help section you can register your complaint using the app or you can contact the company via call, message or chat.",
    },
    {
      id: "panel5",
      question: "What can get my account blocked ?",
      answer:
        "Contact the admin and explain your situation. After you have paid your fines your account will be unblocked.",
    },
    {
      id: "panel6",
      question: "What is the penalty for order cancelation ?",
      answer:
        "The Penalty for order cancelation is account blocking, temporary order block and a fine for the next order.",
    },
    {
      id: "panel7",
      question: "Is this facility available in my city ?",
      answer: "The App is available only in the United Kingdom.",
    },
    {
      id: "panel8",
      question: "Can i get my things moved to another city ?",
      answer: "It is possible as long as the city is within UNited Kingdom.",
    },
    {
      id: "panel9",
      question: "In case of issue what will be done ?",
      answer: "Just Contact your admin via call, chat or message.",
    },
    {
      id: "panel10",
      question: "How do i place an order ?",
      answer: "To place order kindly use our order place order functionality.",
    },
    {
      id: "panel11",
      question: "How can i schedule my order in advance ?",
      answer:
        "You can schedule your order for a later date by selecting in advance. ",
    },
    {
      id: "panel12",
      question: "How can i switch from customer to driver account ?",
      answer:
        "You cannot directly change your account to driver. You have to create a new account and start as a driver.",
    },
    {
      id: "panel13",
      question: "Promo Codes and Discounts ?",
      answer:
        "See The Promos section and during order placing add the promo code.",
    },
    {
      id: "panel14",
      question: "How can a negative rating be removed ?",
      answer:
        "You have to contact the admin and explain your situation so that the negative rating can be removed.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container>
            <Grid item lg={12} align="center">
              <Box className={classes.boxes}>
                <Typography className={classes.title} align="center">
                  Terms and Conditions
                </Typography>
                {options.map((option) => (
                  <React.Fragment key={option.id}>
                    <Accordion
                      expanded={expanded === option.id}
                      onChange={handleChange(option.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === option.id ? <RemoveIcon /> : <AddIcon />
                        }
                      >
                        <Typography className={classes.heading}>
                          {option.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.subheading}>
                          {option.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Divider className={classes.div} />{" "}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}
