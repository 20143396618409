//Libraries
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  Snackbar,
  CssBaseline,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  TextField,
  Box,
  CardContent
} from '@material-ui/core'

//Components
import Yup from '../../../Yup/YupAuthValidation.schemas'
import NavigationDrawer from '../../../Components/NavigationDrawer/NavigationDrawer'
import actions from '../../../Redux/User/User.actions'

//style
import './OTP.css'

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  submit: {
    [theme.breakpoints.only('lg')]: {
      fontWeight: 'bold'
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(4, 0, 0),
      fontWeight: 'bold'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  image: {
    height: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(12),
      width: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(15),
      width: '50%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(18),
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '70%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(15),
      width: '80%'
    }
  },
  heading: {
    fontWeight: 'bold',
    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      paddingTop: '2%'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 18,
      paddingTop: '2%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 20,
      paddingTop: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22,
      paddingTop: '2%'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28,
      paddingTop: '2%'
    }
  },
  box: {
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      padding: '1%',
      textAlign: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      width: '60%',
      padding: '3%',
      textAlign: 'center'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%',
      padding: '1%',
      textAlign: 'center'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%',
      padding: '1%',
      textAlign: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      width: '30%',
      padding: '3%',
      textAlign: 'center'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default function PhoneOTP (props) {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()

  useEffect(() => {
  
    dispatch(actions.clearError())
  }, [])

  const [initialValues1, setInitialValues1] = useState({
    otp: ''
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const newPhone = useSelector(state => state.user.newPhone)

  const otpResentSuccess = useSelector(state => state.user.success.otpSuccess)
  const otpError = useSelector(state => state.user.errors.otpError)

  const updatePhoneNewUserSuccess = useSelector(
    state => state.user.success.updatePhoneNewUserSuccess
  )
  const updatePhoneNewUserError = useSelector(
    state => state.user.errors.updatePhoneNewUserError
  )

  const initialTimer = localStorage.getItem('secondPhone') ?? 60
  const timeoutId1 = React.useRef(null)
  const [secondPhone, setSecondsPhone] = React.useState(initialTimer)

  const countTimer1 = React.useCallback(() => {
    if (secondPhone <= 0) {
      localStorage.removeItem('secondPhone')
    } else {
      setSecondsPhone(secondPhone - 1)
      localStorage.setItem('secondPhone', secondPhone - 1)
    }
  }, [secondPhone])

  React.useEffect(() => {
    if (secondPhone > 0) {
      timeoutId1.current = window.setTimeout(countTimer1, 1000)
      // cleanup function
      return () => window.clearTimeout(timeoutId1.current)
    } else {
      localStorage.setItem('secondPhone', secondPhone)
      setSecondsPhone(0)
      setIsButtonDisabled(false)
    }
  }, [secondPhone, countTimer1])

  const resetResendButton = () => {
    setSecondsPhone(60)
    setIsButtonDisabled(true)
  }

  const [openSnackOtp, setOpenSnackOtp] = useState(false)
  const handleErrorSnackOtp = () => {
    setOpenSnackOtp(true)
  }
  const jwt = useSelector(state => state.user.jwt)
  const handleCloseSnackOtp = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackOtp(false)
  }

  const [openSnackUpdatePhone, setOpenSnackUpdatePhone] = useState(false)
  const handleErrorSnackUpdatePhone = () => {
    setOpenSnackUpdatePhone(true)
  }
  const handleCloseSnackUpdatePhone = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdatePhone(false)
  }

  useEffect(() => {
    if (updatePhoneNewUserError && !updatePhoneNewUserSuccess) {
      handleErrorSnackUpdatePhone()
    } else if (updatePhoneNewUserSuccess && !updatePhoneNewUserError) {
      setExpanded(!expanded)
      handleErrorSnackUpdatePhone()
    } else if (otpError) {
      handleErrorSnackOtp()
    }
  }, [updatePhoneNewUserError, updatePhoneNewUserSuccess, otpError])
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} style={{ justifyContent: 'center' }}>
          <Box className={classes.box}>
            <img src='/assets/images/otp.png' className={classes.image} />
            <div className={classes.paper}>
              <Box mb='50px'>
                <CardContent>
                  <Typography
                    component='h1'
                    variant={matches ? 'subtitle1' : 'h5'}
                    align='center'
                  >
                    Enter Code You Received On Your Phone
                  </Typography>
                  <Formik
                    initialValues={initialValues1}
                    validationSchema={Yup.otpSchema}
                    onSubmit={values => {
                   
                      dispatch(
                        actions.updatePhoneLoginUser(
                          values.otp,
                          newPhone,
                          jwt,
                          props
                        )
                      )
                    }}
                  >
                    {({ errors, handleChange, touched }) => (
                      <Form className={classes.form}>
                        <TextField
                          variant='outlined'
                          margin='normal'
                          className={classes.textField}
                          required
                          fullWidth
                          onChange={handleChange}
                          inputProps={{
                            style: { textTransform: 'uppercase' }
                          }}
                          id='otp'
                          placeholder='Enter OTP'
                          name='otp'
                          autoFocus
                        />
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          Confirm
                        </Button>
                      </Form>
                    )}
                  </Formik>

                  <Button
                    fullWidth
                    color='primary'
                    size='large'
                    variant='outlined'
                    disabled={isButtonDisabled}
                    onClick={() => {
                      dispatch(
                        actions.requestOTPLoginUser(newPhone, jwt, props)
                      )
                      resetResendButton()
                    }}
                  >
                    {secondPhone === 0
                      ? 'Resend OTP'
                      : `Resend OTP in ${secondPhone} second`}
                  </Button>
                </CardContent>
              </Box>
            </div>
          </Box>
        </div>
      </main>
      {otpError || otpResentSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackOtp}
          autoHideDuration={2000}
          onClose={handleCloseSnackOtp}
        >
          <Alert
            onClose={handleCloseSnackOtp}
            severity={otpError ? 'error' : 'success'}
          >
            {otpError ? otpError : otpResentSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
