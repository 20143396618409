//Libraries
import React, { useState, useRef, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Hidden,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  DialogContent,
  withStyles,
  CircularProgress,
  Typography,
  MenuItem,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";

//Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components
import GetAddressName from "./GetAddressName";
import Yup from "../../Yup/YupOValidation.schemas";
import SearchLocation from "./SearchLocation";
import mapStyles from "../../Utils/mapStyles";

//style
const useStyles = makeStyles((theme) => ({
  number: {
    opacity: 1,
    paddingTop: "2%",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      opacity: 1,
    },
  },
  checkIcon: {
    marginLeft: "auto",
    fill: "green",
  },
  map: {
    [theme.breakpoints.only("lg")]: {
      height: "125vh",
    },
    [theme.breakpoints.only("xl")]: {
      height: "83vh",
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
  submitAddress: {
    margin: theme.spacing(0, 0, 2),
    justifyContent: "space-between",
    textTransform: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.between("md", "xl")]: {
      fontSize: 18,
    },
  },
  title: {
    fontSize: 15,
    marginTop: "2%",
  },
  searchBox: {
    position: " fixed",
    marginBottom: "-150px",
    zIndex: "1000",
  },
  drop: {
    width: "48vw",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "5%",
    },
  },
  iconBox: {
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    margin: "15px",
    float: "right",
  },
  boxCan: {
    float: "right",
    marginRight: "1%",
  },
  iconNav: {
    position: "absolute",
    right: -4,
    bottom: 1,
    marginBottom: "100px",
  },
}));

const number = [
  {
    value: 1,
    label: "Ground",
  },
  {
    value: 2,
    label: "1st Floor/ Basement",
  },
  {
    value: 3,
    label: "2nd Floor/ Lower basement",
  },
  {
    value: 4,
    label: "3rd Floor",
  },
  {
    value: 5,
    label: "4th Floor",
  },
  {
    value: 6,
    label: "5th Floor",
  },
  {
    value: 7,
    label: "6th Floor",
  },
  {
    value: 8,
    label: "7th Floor",
  },
  {
    value: 9,
    label: "8th Floor",
  },
  {
    value: 10,
    label: "9th Floor",
  },
  {
    value: 11,
    label: "10th Floor",
  },
  {
    value: 12,
    label: "11th Floor",
  },
  {
    value: 13,
    label: "12th Floor",
  },
  {
    value: 14,
    label: "13th Floor",
  },
  {
    value: 15,
    label: "14th Floor",
  },
  {
    value: 16,
    label: "15th Floor",
  },
];

const BlackTextTypography = withStyles({
  root: {
    color: "#000000",
  },
})(Typography);

const autocompleteService = { current: null };

const libraries = ["places"];

const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

export default function PickupDetails({ setFieldValue, orderDataHandler }) {
  const tripStartLocation = useSelector(
    (state) => state.order?.order?.tripStartLocation
  );

  const classes = useStyles();
  const theme = useTheme();
  const smMatches = useMediaQuery(theme.breakpoints.down("sm"));

  const [locationDialog, setLocationDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [initialValues, setInitialValues] = useState(tripStartLocation);

  const [center, setCenters] = useState({
    lat:
      tripStartLocation?.lat !== null
        ? parseFloat(tripStartLocation?.lat)
        : 51.509865,
    lng:
      tripStartLocation?.long !== null
        ? parseFloat(tripStartLocation?.long)
        : -0.118092,
  });

  const [marker, setMarkers] = useState({
    lat:
      tripStartLocation?.lat !== null
        ? parseFloat(tripStartLocation?.lat)
        : center.lat,
    lng:
      tripStartLocation?.long !== null
        ? parseFloat(tripStartLocation?.long)
        : center.lng,
  }); // to set pins/markers on maplong
  const [lat, setLat] = useState(
    tripStartLocation?.lat !== null ? parseFloat(tripStartLocation?.lat) : ""
  );

  const [lng, setLng] = useState(
    tripStartLocation?.long !== null ? parseFloat(tripStartLocation?.long) : ""
  );

  const [address, setAddress] = useState(
    tripStartLocation?.formattedAddress !== null
      ? tripStartLocation?.formattedAddress
      : ""
  );

  const [city, setCity] = useState(
    tripStartLocation?.city !== null ? tripStartLocation?.city : ""
  );

  const [zipCode, setZipCode] = useState(
    tripStartLocation?.zipcode !== null ? tripStartLocation?.zipcode : ""
  );

  const [selected, setSelected] = useState(null); // to set lat lng in the info window of marker

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCmjiTW64B01tMxtOGACg7vnW4QJAFmY0A",
    libraries,
 
  });

  const mapRef = useRef(null);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  useEffect(() => {
    setCenters({
      lat:
        tripStartLocation?.lat !== null
          ? parseFloat(tripStartLocation?.lat)
          : 51.509865,
      lng:
        tripStartLocation?.long !== null
          ? parseFloat(tripStartLocation?.long)
          : -0.118092,
    });
  }, [tripStartLocation?.lat, tripStartLocation?.long]);
  useEffect(() => {
    setInitialValues(tripStartLocation);
  }, [tripStartLocation?.formattedAddress]);
  function onSuccess(pos) {
    setLat(parseFloat(pos.coords.latitude));
    setLng(parseFloat(pos.coords.longitude));
    setMarkers({
      lat: parseFloat(pos.coords.latitude),
      lng: parseFloat(pos.coords.longitude),
    });
    mapRef.current.setCenter({
      lat: parseFloat(pos.coords.latitude),
      lng: parseFloat(pos.coords.longitude),
    });
    mapRef.current.setZoom(15);
  }

  function onError(err) {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "denied") {
          setLocationDialog(true);
        }
      });
    }
  }

  useEffect(() => {}, [lat, lng]);

  useEffect(() => {}, [address]);

  const onMapClick = useCallback((event) => {
    setLat(event.latLng.lat());
    setLng(event.latLng.lng());
    setMarkers({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    setLat(event.latLng.lat());
    setLng(event.latLng.lng());
    setMarkers({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  }, []);

  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return <CircularProgress />;

  const handleCloseLocationDialog = () => {
    setLocationDialog(false);
  };

  return (
    <>
      {/*Pickup Address Popup*/}
      <Button
        onClick={handleClickOpen}
        select
        variant="outlined"
        fullWidth
        className={classes.submitAddress}
      >
        {tripStartLocation ? (
          tripStartLocation?.formattedAddress == undefined ||
          tripStartLocation?.formattedAddress == null ||
          tripStartLocation?.formattedAddress == "" ? (
            <>Enter Pickup Detail </>
          ) : (
            tripStartLocation?.formattedAddress + ", " + tripStartLocation?.city
          )
        ) : (
          <>Enter Pickup Detail </>
        )}
        {tripStartLocation ? (
          tripStartLocation?.formattedAddress == undefined ||
          tripStartLocation?.formattedAddress == null ||
          tripStartLocation?.formattedAddress == "" ? null : (
            <CheckCircleIcon className={classes.checkIcon} />
          )
        ) : null}
        <ExpandMoreIcon />
      </Button>
      <Dialog
        open={locationDialog}
        onClose={handleCloseLocationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>Use Google's location service</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Van go requires access to your location Enable location services
              for a more accurate pickup experience.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLocationDialog} color="primary" autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        hideBackdrop={true}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <Grid container mb={3}>
            <Hidden mdUp>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                className={classes.drop}
              >
                <div id="searchbox" className={classes.searchBox}>
                  <SearchLocation
                    address={address}
                    inputLocation={tripStartLocation}
                    setLat={setLat}
                    setLng={setLng}
                    setMarkers={setMarkers}
                    mapRef={mapRef}
                  />
                </div>
                <GoogleMap
                  mapContainerStyle={
                    smMatches
                      ? {
                          height: "350px",
                          width: "100%",
                        }
                      : {
                          height: "100vh",
                          width: "100%",
                        }
                  }
                  zoom={12}
                  center={center}
                  options={mapOptions}
                  onClick={onMapClick}
                  onLoad={onMapLoad}
                >
                  {
                    <Marker
                      position={{
                        lat: marker.lat,
                        lng: marker.lng,
                      }}
                      onClick={() => {
                        setSelected(marker);
                      }}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                    />
                  }
                  {selected ? (
                    <InfoWindow
                      position={{ lat: selected.lat, lng: selected.lng }}
                      onCloseClick={() => {
                        setSelected(null);
                      }}
                    >
                      <div>
                        <BlackTextTypography variant="h6">
                          Pick-up Location
                        </BlackTextTypography>
                        <BlackTextTypography variant="subtitle2">
                          Lat: {selected.lat}
                        </BlackTextTypography>
                        <BlackTextTypography variant="subtitle2">
                          Lng: {selected.lng}
                        </BlackTextTypography>
                      </div>
                    </InfoWindow>
                  ) : null}
                  <Box display="flex" className={classes.iconNav}>
                    <IconButton
                      className={classes.iconBox}
                      onClick={() => {
                        if (navigator.geolocation) {
                          var option = {
                            enableHighAccuracy: true,
                            maximumAge: 0,
                          };
                          navigator.geolocation.getCurrentPosition(
                            onSuccess,
                            onError,
                            option
                          );
                        }
                      }}
                    >
                      <MyLocationIcon />
                    </IconButton>
                  </Box>
                </GoogleMap>
                {lat != "" && lng != "" ? (
                  <GetAddressName
                    lat={lat}
                    lng={lng}
                    setAddress={setAddress}
                    setCity={setCity}
                    setZipCode={setZipCode}
                  />
                ) : null}
              </Grid>
            </Hidden>
            <Grid item xs={12} md={5} lg={5}>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.PickUpLocationValidationSchema}
                onSubmit={(values) => {
                  let startData = {
                    lat: lat,
                    long: lng,
                    formattedAddress: address,
                    city: city,
                    zipcode: zipCode,
                  };
                  // let data = {
                  //     ...values,
                  //     lat: lat
                  // }
                  // let payload = {
                  //     ...data,
                  //     long: lng
                  // }
                  let payload = { ...values, ...startData };

                  setFieldValue("tripStartLocation", payload);
                  handleClose();
                  orderDataHandler("pickup", payload);
                }}
              >
                {({ errors, handleChange, touched, values }) => (
                  <Form className={classes.form}>
                    <Typography className={classes.title}>
                      Enter Pickup Address
                    </Typography>
                    <TextField
                      error={
                        errors.formattedAddress && touched.formattedAddress
                      }
                      helperText={
                        errors.formattedAddress && touched.formattedAddress
                          ? errors.formattedAddress
                          : null
                      }
                      id="formattedAddress"
                      name="formattedAddress"
                      variant="outlined"
                      className={classes.title}
                      fullWidth
                      // defaultValue={
                      //   tripStartLocation
                      //     ? tripStartLocation.formattedAddress
                      //     : null
                      // }
                      multiline
                      rows={3}
                      onChange={handleChange}
                      value={address}
                    />
                    <Typography className={classes.title}>
                      Enter City
                    </Typography>
                    <TextField
                      error={errors.city && touched.city}
                      helperText={
                        errors.city && touched.city ? errors.city : null
                      }
                      fullWidth
                      // defaultValue={
                      //   tripStartLocation ? tripStartLocation.city : null
                      // }
                      variant="outlined"
                      margin="normal"
                      name="city"
                      id="city"
                      onChange={handleChange}
                      value={city}
                      // select
                    />
                    <Typography className={classes.title}>
                      Enter Zip Code
                    </Typography>
                    <TextField
                      error={errors.zipcode && touched.zipcode}
                      helperText={
                        errors.zipcode && touched.zipcode
                          ? errors.zipcode
                          : null
                      }
                      id="zipcode"
                      name="zipcode"
                      fullWidth
                      // defaultValue={
                      //   tripStartLocation ? tripStartLocation.zipcode : null
                      // }
                      className={classes.number}
                      variant="outlined"
                      onChange={handleChange}
                      value={zipCode}
                    />
                    <Typography className={classes.title}>
                      Enter Floor
                    </Typography>
                    <TextField
                      error={errors.floor && touched.floor}
                      helperText={
                        errors.floor && touched.floor ? errors.floor : null
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      id="floor"
                      name="floor"
                      onChange={(e) =>
                        setInitialValues({
                          ...initialValues,
                          floor: e.target.value,
                        })
                      }
                      select
                      defaultValue={
                        tripStartLocation ? tripStartLocation?.floor : ""
                      }
                      className={classes.title}
                    >
                      {number.map((option) => (
                        <MenuItem key={option.value} value={option.value || ""}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography className={classes.title}>
                      Elevator Availability
                    </Typography>
                    <TextField
                      error={
                        errors.isFreightElevatorAvailable &&
                        touched.isFreightElevatorAvailable
                      }
                      helperText={
                        errors.isFreightElevatorAvailable &&
                        touched.isFreightElevatorAvailable
                          ? errors.isFreightElevatorAvailable
                          : null
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      name="isFreightElevatorAvailable"
                      id="isFreightElevatorAvailable"
                      defaultValue={
                        tripStartLocation
                          ? tripStartLocation?.isFreightElevatorAvailable
                          : ""
                      }
                      onChange={(e) =>
                        setInitialValues({
                          ...initialValues,
                          isFreightElevatorAvailable: e.target.value,
                        })
                      }
                      select
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </TextField>
                    <Box mt={3} display="flex" className={classes.boxCan}>
                      <Button
                        onClick={handleClose}
                        style={{
                          backgroundColor: "#F5F6FE",
                          marginRight: "3%",
                        }}
                        size="large"
                        variant="contained"
                        className={classes.submit}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        // onClick={handleClose}
                        type="submit"
                        className={classes.submit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Hidden smDown>
              <Grid item md={7} lg={7} xl={7} className={classes.drop}>
                <div id="searchbox" className={classes.searchBox}>
                  <SearchLocation
                    address={address}
                    inputLocation={tripStartLocation}
                    setLat={setLat}
                    setLng={setLng}
                    setMarkers={setMarkers}
                    mapRef={mapRef}
                  />
                </div>
                <GoogleMap
                  mapContainerStyle={{
                    height: "100%",
                    width: "100%",
                  }}
                  zoom={12}
                  center={center}
                  options={mapOptions}
                  onClick={onMapClick}
                  onLoad={onMapLoad}
                >
                  {
                    <Marker
                      position={{
                        lat: marker.lat,
                        lng: marker.lng,
                      }}
                      onClick={() => {
                        setSelected(marker);
                      }}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                    />
                  }
                  {selected ? (
                    <InfoWindow
                      position={{ lat: selected.lat, lng: selected.lng }}
                      onCloseClick={() => {
                        setSelected(null);
                      }}
                    >
                      <div>
                        <BlackTextTypography variant="h6">
                          Pick-up Location
                        </BlackTextTypography>
                        <BlackTextTypography variant="subtitle2">
                          Lat: {selected.lat}
                        </BlackTextTypography>
                        <BlackTextTypography variant="subtitle2">
                          Lng: {selected.lng}
                        </BlackTextTypography>
                      </div>
                    </InfoWindow>
                  ) : null}
                  <Box display="flex" className={classes.iconNav}>
                    <IconButton
                      className={classes.iconBox}
                      onClick={() => {
                        if (navigator.geolocation) {
                          var option = {
                            enableHighAccuracy: true,
                            maximumAge: 0,
                          };
                          navigator.geolocation.getCurrentPosition(
                            onSuccess,
                            onError,
                            option
                          );
                        }
                      }}
                    >
                      <MyLocationIcon />
                    </IconButton>
                  </Box>
                </GoogleMap>
                {lat != "" && lng != "" ? (
                  <GetAddressName
                    lat={lat}
                    lng={lng}
                    setAddress={setAddress}
                    setCity={setCity}
                    setZipCode={setZipCode}
                  />
                ) : null}
              </Grid>
            </Hidden>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
