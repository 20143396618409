//Libraries
import axios from 'axios'

//Components
import backendPath from '../../Utils/constants'
import history from '../../Utils/history'

const actions = {
  //#region ACTION DECLARATIONS
  UPDATE_START_LOCATION: 'order/UPDATE_START_LOCATION',
  UPDATE_END_LOCATION: 'order/UPDATE_END_LOCATION',
  UPDATE_ORDER_DETAILS: 'order/UPDATE_ORDER_DETAILS',
  SOCKET_CONNECTION: 'SOCKET_CONNECTION',
  DRIVER_LIVE_LOCATION: 'DRIVER_LIVE_LOCATION',
  PLACE_ORDER: 'order/PLACE_ORDER',
  PRICE_CALCULATION_SUCCESS: 'order/PRICE_CALCULATION_SUCCESS',
  PRICE_CALCULATION_ERROR: 'order/PRICE_CALCULATION_ERROR',
  PRICE_ORDER_CALCULATION: 'order/PRICE_ORDER_CALCULATION',
  SET_LOADING: 'order/SET_LOADING',
  UPDATE_PRICE_ORDER_CALCULATION: 'order/UPDATE_PRICE_ORDER_CALCULATION',
  PLACE_ORDER_SUCCESS: 'order/PLACE_ORDER_SUCCESS',
  PLACE_ORDER_DATA: 'order/PLACE_ORDER_DATA',
  SET_PLACE_ORDER_ERROR: 'order/SET_PLACE_ORDER_ERROR',

  SET_LOCATION_FORM_ERRORS: 'order/SET_LOCATION_FORM_ERRORS',
  SET_DROPOFF_FORM_ERRORS: 'order/SET_DROPOFF_FORM_ERRORS',
  SET_ORDER_DETAIL_FORM_ERRORS: 'order/SET_ORDER_DETAIL_FORM_ERRORS',
  GET_ALL_ORDERS: 'order/GET_ALL_ORDERS',
  SET_ALL_ORDERS: 'order/SET_ALL_ORDERS',
  CLEAR_FORMS: 'order/CLEAR_FORMS',
  CLEAR_ERROR: 'order/CLEAR_ERROR',
  GET_ORDER_DETAILS: 'order/GET_ORDER_DETAILS',
  GET_ORDER_DETAILS_REQUEST: 'GET_ORDER_DETAILS_REQUEST',
  UPDATE_PENDING_ORDER_DETAILS: 'order/UPDATE_PENDING_ORDER_DETAILS',
  UPDATE_PENDING_ORDER_REQUEST: 'UPDATE_PENDING_ORDER_REQUEST',
  GET_PAST_ORDERS: 'order/GET_PAST_ORDERS',
  GET_ACTIVE_ORDERS: 'order/GET_ACTIVE_ORDERS',
  GET_PENDING_ORDERS: 'order/GET_PENDING_ORDERS',
  GET_DELIVERED_ORDERS: 'order/GET_DELIVERED_ORDERS',
  GET_CANCELLED_ORDERS: 'order/GET_CANCELLED_ORDERS',
  ORDER_FEEDBACK_SUCCESS: 'order/ORDER_FEEDBACK_SUCCESS',
  ORDER_FEEDBACK_ERROR: 'order/ORDER_FEEDBACK_ERROR',
  updateStartLocation: locationObj => {
    console.log('locationObj', locationObj)

    return {
      type: actions.UPDATE_START_LOCATION,
      payload: locationObj
    }
  },

  updateEndLocation: locationObj => {
    return {
      type: actions.UPDATE_END_LOCATION,
      payload: locationObj
    }
  },
  //#endregion

  //#region error handling
  setLocationFormErrors: errorObject => {
    return {
      type: actions.SET_LOCATION_FORM_ERRORS,
      payload: errorObject
    }
  },

  setDropOffFormErrors: errorObject => {
    return {
      type: actions.SET_DROPOFF_FORM_ERRORS,
      payload: errorObject
    }
  },

  setOrderDetailFormErrors: errorObject => {
    return {
      type: actions.SET_ORDER_DETAIL_FORM_ERRORS,
      payload: errorObject
    }
  },
  confirmPayment: (orderId, jwt) => dispatch => {
    console.log('confirmPayment', orderId)
    axios({
      method: 'post',
      url: backendPath + 'confirmPayment',
      headers: { Authorization: `Bearer ${jwt}` },
      data: {
        orderId: orderId
      }
    }).then(function (response) {
      console.log('response', response)
      if (response.status === 200) {
        if (response.data && response.data.message === 'Payment Successfully') {
          console.log('response.data.message', response.data.message)
          history.push(`/order_details/${orderId}`)
        }
      }
    })
  },
  placeOrder: (orderId, jwt) => dispatch => {
    console.log('orderID', orderId)

    axios({
      method: 'post',
      url: backendPath + 'newPlaceOrder',
      headers: { Authorization: `Bearer ${jwt}` },
      data: {
        orderId: orderId
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data.message === 'new Order Place') {
            console.log(
              '%c Order Placed',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch(
              actions.placeOrderSuccess(
                'Order Placed Successfully',
                response.data
              )
            )
            history.push(`/payment/${response.data.orderId}`)
          }
          else{
            dispatch(actions.setPaceOrderError('Something went wrong, please try again.'))
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Place Order error',
            'color: red; font-weight: bold',
            error.response
          )

          console.log(
            '%c Place Order error',
            'color: red; font-weight: bold',
            error.response.config.data
          )
          dispatch(actions.setPaceOrderError(error.response.data.message))
        } else if (!error.status) {
          dispatch(actions.setPaceOrderError('NETWORK ERROR'))
        }
        else{
          dispatch(actions.setPaceOrderError('Something went wrong, please try again.'))
        }
      })
  },

  placeOrderSuccess: (placeOrderSuccessObj, placeOrderObj) => {
    return {
      type: actions.PLACE_ORDER_SUCCESS,
      payload: { placeOrderSuccessObj, placeOrderObj }
    }
  },
  clearNow: () => {
    return {
      type: actions.PLACE_ORDER_SUCCESS,
      payload: 'Success Clear Field Data'
    }
  },
  setPaceOrderError: errorMessage => {
    console.log(errorMessage.message)
    let placeOrderError = errorMessage
    if (errorMessage === 'Network Error') {
      placeOrderError = 'Internet connection failure, please try again later. '
    } else {
      placeOrderError = 'Something went wrong, please try again.'
    }

    return {
      type: actions.SET_PLACE_ORDER_ERROR,
      payload: { placeOrderError }
    }
  },

  placeOrderData: placeOrderObj => {
    return {
      type: actions.PLACE_ORDER_DATA,
      payload: { placeOrderObj }
    }
  },
  //#endregion

  //#region order Feedback
  orderFeedback: (jwt, orderId, values) => dispatch => {
    console.log('orderID', values)
    axios({
      method: 'post',
      url: backendPath + 'orderFeedback',
      headers: { Authorization: `Bearer ${jwt}` },
      data: {
        orderId: orderId,
        rating: values.value,
        comment: values.comment
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data.message === 'Feedback Saved') {
            console.log(
              '%c Order Feedback',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch(
              actions.orderFeedbackSuccess(
                'Order feedback done Successfully',
                values
              )
            )
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c OrderFeedback error',
            'color: red; font-weight: bold',
            error.response
          )
          dispatch(actions.orderFeedbackError(error.response.data.message))
        } else if (!error.status) {
          dispatch(actions.orderFeedbackError('Network Error'))
        }
      })
  },

  orderFeedbackSuccess: (orderFeedbackObj, values) => {
    console.log('values', values)
    return {
      type: actions.ORDER_FEEDBACK_SUCCESS,
      payload: { orderFeedbackObj, values }
    }
  },

  orderFeedbackError: errorMessage => {
    console.log(errorMessage.message)
    let error = errorMessage
    return {
      type: actions.ORDER_FEEDBACK_ERROR,
      payload: { error }
    }
  },
  //#endregion

  placeOrderData: placeOrderObj => {
    return {
      type: actions.PLACE_ORDER_DATA,
      payload: { placeOrderObj }
    }
  },

  setPaceOrderError: errorMessage => {
    console.log(errorMessage.message)
    let placeOrderError = errorMessage
    if (errorMessage === 'Network Error') {
      placeOrderError = 'Internet connection failure, please try again later. '
    } else {
      placeOrderError = 'Something went wrong, please try again.'
    }

    return {
      type: actions.SET_PLACE_ORDER_ERROR,
      payload: { placeOrderError }
    }
  },

  getAllOrdersList: jwt => dispatch => {
    dispatch({
      type: actions.GET_ALL_ORDERS
    })
    axios({
      method: 'get',
      url: backendPath + 'getAcceptedOrdersList',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log(
            '%c Get All Orders Response',
            'color: #76FF03; font-weight: bold',
            response
          )
          console.log('All Orders response check', response.data)
          dispatch(actions.setAllOrdersList(response.data))
          // componentProps.history.push("/home");
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Get All Orders Error',
            'color: red; font-weight: bold',
            error
          )
        } else if (!error.status) {
          console.log(
            '%c Get All Orders Error',
            'color: red; font-weight: bold',
            error
          )
        }
      })
  },

  setAllOrdersList: orderObj => {
    return {
      type: actions.SET_ALL_ORDERS,
      payload: orderObj
    }
  },

  updatePendingOrderDetails: (jwt, orderId) => dispatch => {
    console.log('updateOrderDetails', orderId)
    dispatch({
      type: actions.UPDATE_PENDING_ORDER_REQUEST
    })
    axios({
      method: 'post',
      url: backendPath + 'orderDetails',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: { orderId }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            console.log(
              '%c Get Order Details',
              'color: #76FF03; font-weight: bold',
              response
            )
            console.log('response check', response.data)
            dispatch({
              type: actions.UPDATE_PENDING_ORDER_DETAILS,
              payload: response.data
            })
          }
          history.push('/home')
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Get order detail Error',
            'color: red; font-weight: bold',
            error
          )
        } else if (!error.status) {
          console.log(
            '%c Get All order detail Error',
            'color: red; font-weight: bold',
            error
          )
        }
      })
  },

  getOrderDetails: (jwt, orderId) => dispatch => {
    console.log('getOrderDetails', orderId)

    axios({
      method: 'post',
      url: backendPath + 'orderDetails',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: { orderId }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            console.log(
              '%c Get Order Details',
              'color: #76FF03; font-weight: bold',
              response
            )
            console.log('response check', response.data)
            dispatch({
              type: actions.GET_ORDER_DETAILS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Get order detail Error',
            'color: red; font-weight: bold',
            error
          )
        } else if (!error.status) {
          console.log(
            '%c Get All order detail Error',
            'color: red; font-weight: bold',
            error
          )
        }
      })
  },

  priceOrderCalculation: (jwt, order) => dispatch => {
    console.log('price calculation once', order)
    dispatch({
      type: actions.SET_LOADING,
      payload: true
    })
    axios({
      method: 'post',
      url: backendPath + 'orderPriceCalculation',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'content-type': 'application/json'
      },
      data: {
        order_Id: null,
        tripStartLocation: {
          formattedAddress: order.tripStartLocation.formattedAddress,
          city: order.tripStartLocation.city,
          zipcode: order.tripStartLocation.zipcode,
          lat: order.tripStartLocation.lat,
          long: order.tripStartLocation.long,
          floor: order.tripStartLocation.floor,
          isFreightElevatorAvailable:
            order.tripStartLocation.isFreightElevatorAvailable
        },
        tripEndLocation: {
          formattedAddress: order.tripEndLocation.formattedAddress,
          city: order.tripEndLocation.city,
          zipcode: order.tripEndLocation.zipcode,
          lat: order.tripEndLocation.lat,
          long: order.tripEndLocation.long,
          floor: order.tripEndLocation.floor,
          isFreightElevatorAvailable:
            order.tripEndLocation.isFreightElevatorAvailable
        },

        isFreightInsured: order.isFreightInsured,
        noOfHelpersRequired: order.noOfHelpersRequired,

        description: order.description,
        rideType: order.rideType,

        pickUpDateAndTime: order.pickUpDateAndTime
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data.message === 'order placed') {
            console.log(
              '%c Get User Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            console.log('response check', response.data)
            dispatch({
              type: actions.PRICE_ORDER_CALCULATION,
              payload: response.data
            })
            dispatch(
              actions.priceCalculationSuccess(
                'Price estimated Successfully',
                response.data
              )
            )
          }
          else{
            dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c automatic price calculation error',
            'color: red; font-weight: bold',
            error.response.config.data
          )
          dispatch(
            actions.setPriceCalculationError(error.response.data.message)
          )
        } else if (!error.status) {
          console.log(
            '%c automatic price calculation error:',
            'color: red; font-weight: bold',
            error
          )
          dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
        }
        else{
          dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
        }
      })
  },

  updatePriceOrderCalculation: (jwt, orderId, order) => dispatch => {
    console.log('updateeee order-->', order)
    dispatch({
      type: actions.SET_LOADING,
      payload: true
    })
    axios({
      method: 'post',
      url: backendPath + 'orderPriceCalculation',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'content-type': 'application/json'
      },
      data: {
        order_Id: orderId,
        tripStartLocation: {
          formattedAddress: order.tripStartLocation.formattedAddress,
          city: order.tripStartLocation.city,
          zipcode: order.tripStartLocation.zipcode,
          lat: order.tripStartLocation.lat,
          long: order.tripStartLocation.long,
          floor: order.tripStartLocation.floor,
          isFreightElevatorAvailable:
            order.tripStartLocation.isFreightElevatorAvailable
        },
        tripEndLocation: {
          formattedAddress: order.tripEndLocation.formattedAddress,
          city: order.tripEndLocation.city,
          zipcode: order.tripEndLocation.zipcode,
          lat: order.tripEndLocation.lat,
          long: order.tripEndLocation.long,
          floor: order.tripEndLocation.floor,
          isFreightElevatorAvailable:
            order.tripEndLocation.isFreightElevatorAvailable
        },

        isFreightInsured: order.isFreightInsured,
        noOfHelpersRequired: order.noOfHelpersRequired,

        description: order.description,
        rideType: order.rideType,

        pickUpDateAndTime: order.pickUpDateAndTime
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data.message === 'update order') {
            console.log(
              '%c Get User Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.UPDATE_PRICE_ORDER_CALCULATION,
              payload: response.data
            })
            dispatch(
              actions.priceCalculationSuccess(
                'Price estimated Successfully',
                response.data
              )
            )
          }
          else{
            dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Update price calculation error',
            'color: red; font-weight: bold',
            error.response
          )

          console.log(
            '%c Update price calculation error',
            'color: red; font-weight: bold',
            error.response.config.data
          )
          dispatch(
            actions.setPriceCalculationError(error.response.data.message)
          )
        } else if (!error.status) {
          console.log(
            '%c Update price calculation error',
            'color: red; font-weight: bold',
            error.response
          )
          dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
        }
        else{
          dispatch(actions.setPriceCalculationError('NETWORK ERROR'))
        }
      })
  },

  priceCalculationSuccess: (priceOrderSuccessObj, priceOrderObj) => {
    return {
      type: actions.PRICE_CALCULATION_SUCCESS,
      payload: { priceOrderSuccessObj, priceOrderObj }
    }
  },

  setPriceCalculationError: errorMessage => {
    console.log(errorMessage.message)
    let priceOrderError
    priceOrderError = 'Something went wrong, please try again.'

    return {
      type: actions.PRICE_CALCULATION_ERROR,
      payload: { priceOrderError }
    }
  },
  //#endregion

  //#region Get Orders List and details
  setAllOrdersList: orderObj => {
    return {
      type: actions.SET_ALL_ORDERS,
      payload: orderObj
    }
  },

  getAllOrdersList: jwt => dispatch => {
    dispatch({
      type: actions.GET_ALL_ORDERS
    })
    axios({
      method: 'get',
      url: backendPath + 'getAcceptedOrdersList',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log(
            '%c Get All Orders Response',
            'color: #76FF03; font-weight: bold',
            response
          )
          console.log('All Orders response check', response.data)
          dispatch(actions.setAllOrdersList(response.data))
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Get All Orders Error',
            'color: red; font-weight: bold',
            error
          )
        } else if (!error.status) {
          console.log(
            '%c Get All Orders Error',
            'color: red; font-weight: bold',
            error
          )
        }
      })
  },

  getPastOrdersList: jwt => dispatch => {
    console.log(jwt)
    axios({
      method: 'get',
      url: backendPath + 'getPastOrdersList',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          console.log(
            '%c Get Past Orders List',
            'color: #76FF03; font-weight: bold',
            response
          )
          dispatch({
            type: actions.GET_PAST_ORDERS,
            payload: response.data
          })
        }
      })
      .catch(function (error) {
        console.log(
          '%c Get Past Orders List',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  getActiveOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allActiveOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && Array.isArray(response.data)) {
            console.log(
              '%c GET_ACTIVE_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_ACTIVE_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_ACTIVE_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  getPendingOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allPendingOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && Array.isArray(response.data)) {
            console.log(
              '%c GET_PENDING_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_PENDING_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_PENDING_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  getDeliveredOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allCompleteOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && Array.isArray(response.data)) {
            console.log(
              '%c GET_DELIVERED_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_DELIVERED_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_DELIVERED_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  getCancelledOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allCancelOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && Array.isArray(response.data)) {
            console.log(
              '%c GET_CANCELLED_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_CANCELLED_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_CANCELLED_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  getOrderDetails: (jwt, orderId) => dispatch => {
    console.log('getOrderDetails', orderId)
    axios({
      method: 'post',
      url: backendPath + 'orderDetails',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: { orderId }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            console.log(
              '%c Get Order Details',
              'color: #76FF03; font-weight: bold',
              response
            )
            console.log('response check', response.data)
            dispatch({
              type: actions.GET_ORDER_DETAILS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(
            '%c Get order detail Error',
            'color: red; font-weight: bold',
            error
          )
        } else if (!error.status) {
          console.log(
            '%c Get All order detail Error',
            'color: red; font-weight: bold',
            error
          )
        }
      })
  },

  updateOrderDetails: orderDetailsObj => {
    let orderDetails = orderDetailsObj
    if (orderDetailsObj.isFreightInsured == 'Yes') {
      orderDetails.isFreightInsured = true
    } else if (orderDetailsObj.isFreightInsured == 'No') {
      orderDetails.isFreightInsured = false
    }

    console.log('orderDetailsObj obj after yes / no', orderDetails)
    return {
      type: actions.UPDATE_ORDER_DETAILS,
      payload: orderDetails
    }
  },
  //#endregion

  //Socket Connection
  socketConnection: socket => dispatch => {
    dispatch({
      type: actions.SOCKET_CONNECTION,
      payload: socket
    })
  },

  //Driver Live Location
  driverLiveLocation: msg => dispatch => {
    dispatch({
      type: actions.DRIVER_LIVE_LOCATION,
      payload: msg
    })
  },

  //Clear Errors
  clearError: () => {
    return {
      type: actions.CLEAR_ERROR
    }
  },

  //Clear Forms
  clearForms: () => {
    return {
      type: actions.CLEAR_FORMS
    }
  }
}

export default actions
