import * as yup from 'yup'

const schemata = {
  PickUpLocationValidationSchema: yup.object().shape({
    floor: yup.number().required('This field is required.').nullable(),
    isFreightElevatorAvailable: yup
      .bool()
      .required('This field is required.')
      .nullable()
  }),
  DropOffLocationValidationSchema: yup.object().shape({
    floor: yup.number().required('This field is required.').nullable(),
    isFreightElevatorAvailable: yup
      .bool()
      .required('This field is required.')
      .nullable()
  }),
  OrderDetailsValidationSchema: yup.object().shape({
    tripStartLocation: yup.object().required('This field is required.'),
    tripEndLocation: yup
      .object()
      .required('This field is required.')
      .nullable(),
    date: yup.string().required('Required').nullable(),
    time: yup.string().required('Required').nullable(),
    noOfHelpersRequired: yup
      .number()
      .required('This field is required.')
      .nullable(),
    isFreightInsured: yup
      .string()
      .required('This field is required.')
      .nullable(),
    rideType: yup.string().required('This field is required.').nullable()
  })
}

export default schemata
