import * as Actions from './themeActions'

const initialState = {
  theme: 'light',
  isChecked: false
}

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.APPLY_THEME:
      return {
        ...state,
        theme: action.payload
      }

    case Actions.REMEMBER_ME:
      return {
        ...state,
        isChecked: action.payload
      }

    default:
      return state
  }
}
