//Libraries
import { combineReducers } from 'redux'

//Components
import userReducer from './User/User.reducer'
import orderReducer from './Order/Order.reducer'
import { themeReducer } from './Theme/themeReducer'

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  themes: themeReducer
})

export default rootReducer
