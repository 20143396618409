export const APPLY_THEME = 'APPLY_THEME'
export const REMEMBER_ME = 'REMEMBER_ME'

export const applyTheme = theme => {
  return {
    type: APPLY_THEME,
    payload: theme
  }
}

export const rememberMe = isChecked => {
  return {
    type: REMEMBER_ME,
    payload: isChecked
  }
}
